import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Column, Row as ReactTableRow } from "react-table";
import {
  deleteAccountRegistrationRequest,
  fetchAccountRegistrations,
  getDebtorInformation,
  resetDebtorInformation,
  sendAccountRegistrationRequest,
  upsertAccountRegistrationRequest,
} from "store/actions/accountActions";
import { RootState } from "store/reducers/rootReducer";
import DateUtils from "utils/dateUtils";
import IAccountRegistrationRequest from "../../interfaces/IAccountRegistrationRequest";
import IAccountRegistrationManagementProps, {
  IAccountRegistrationManagementDispatchProps,
  IAccountRegistrationManagementStateProps,
} from "./interfaces/IAccountRegistrationManagementProps";
import IAccountRegistrationManagementState from "./interfaces/IAccountRegistrationManagementState";

class AccountRegistrationManagement extends Component<
  IAccountRegistrationManagementProps,
  IAccountRegistrationManagementState
> {
  private readonly tableDataExampleObject: IAccountRegistrationRequest = {
    name: "",
    email: "",
    houseNumber: "",
    houseNumberAddition: "",
    zipCode: "",
    debtorId: "",
    externalDebtorId: "",
    createdOn: new Date(),
    accountRegistrationCompletedOn: new Date(),
    accountRegistrationOwner: "",
    accountRegistrationSendOn: new Date(),
    accountRegistrationStatus: "",
    accountRegistrationRequestId: "",
  };

  private readonly statusCreated: string = "Aangemaakt";
  private readonly statusApproved: string = "Afgerond";
  private readonly statusSent: string = "Verzonden";

  public constructor(props: IAccountRegistrationManagementProps) {
    super(props);

    const state: IAccountRegistrationManagementState = {
      isLoading: true,
      showAddModal: false,
      fetchingDebtorInformation: false,
      fetchedDebtorInformation: undefined,
      upsertingDebtorInformation: false,
      showDeleteConfirmationPrompt: false,
    };

    this.state = state;

    this.setShowAddModal = this.setShowAddModal.bind(this);
    this.toggleShowAddModal = this.toggleShowAddModal.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.newUserDebtorId = this.newUserDebtorId.bind(this);
    this.renderRowSubComponent = this.renderRowSubComponent.bind(this);
    this.checkExternalDebtorId = this.checkExternalDebtorId.bind(this);
    this.upsertAccountRegistrationRequest = this.upsertAccountRegistrationRequest.bind(this);
    this.onUpsertUserSuccess = this.onUpsertUserSuccess.bind(this);
    this.showDeleteAccountRegistrationRequest = this.showDeleteAccountRegistrationRequest.bind(this);
    this.onDeleteRequest = this.onDeleteRequest.bind(this);
  }

  public componentDidMount(): void {
    this.props.onFetchAccountRegistrationRequests();
  }

  private get getColumns(): Column[] {
    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_name),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.name),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_request_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.createdOn),
        Cell: ({ value }): string => DateUtils.getFriendlyDateTimeString(DateUtils.utcToLocal(value)),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_external_debtor_id),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.externalDebtorId),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_status),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.accountRegistrationStatus
        ),
        Cell: ({ value }): JSX.Element => {
          return (
            <div>
              <span className={value === this.statusApproved ? "g-text-green" : ""}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.accountRegistrationRequestId
        ),
        Cell: ({ value, row }): JSX.Element => {
          return (
            <div className="account-registration-request-actions">
              <Container>
                <Row>
                  <Col className="align-items-center justify-content-center d-flex col-6">
                    <img
                      src={DeleteIcon}
                      alt="delete registration"
                      onClick={(): void =>
                        this.showDeleteAccountRegistrationRequest(value, true, row.values.accountRegistrationStatus)
                      }
                      className={row.values.accountRegistrationStatus === this.statusCreated ? "" : "disabled"}
                    />
                  </Col>
                  <Col className="align-items-center justify-content-center d-flex col-6">
                    <span {...row.getToggleRowExpandedProps()}>
                      <div
                        className={
                          row.isExpanded
                            ? "account-registration-request-management-table-row-expander-open"
                            : "account-registration-request-management-table-row-expander-closed"
                        }
                      />
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        },
        disableSortBy: true,
      },
    ];

    return columns;
  }

  private showDeleteAccountRegistrationRequest(
    requestId: string | undefined,
    showDelete: boolean,
    status: string | undefined
  ): void {
    if (status === this.statusCreated) {
      this.setState({
        showDeleteConfirmationPrompt: showDelete,
        requestIdToDelete: requestId,
      });
    } else {
      this.setState({
        showDeleteConfirmationPrompt: false,
        requestIdToDelete: undefined,
      });
    }
  }

  private onDeleteRequest(requestId?: string): void {
    if (!requestId) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.general));
      return;
    }

    this.props.onDeleteAccountRegistration(requestId);
    this.showDeleteAccountRegistrationRequest(undefined, false, undefined);
  }

  private setShowAddModal(show: boolean): void {
    this.props.onResetDebtorInformation();
    this.setState({
      showAddModal: show,
    });
  }

  private toggleShowAddModal(): void {
    this.setState({
      showAddModal: !this.state.showAddModal,
    });
  }

  private sendInvitation(accountRegistrationRequestId: string): void {
    this.props.onSendAccountRegistration(accountRegistrationRequestId, this.props.onFetchAccountRegistrationRequests);
  }

  private newUserDebtorId(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value !== "") {
      this.setState({ externalDebtorId: event.target.value });
    } else {
      this.setState({ externalDebtorId: undefined });
    }
  }

  private checkExternalDebtorId(): void {
    if (this.state.externalDebtorId != null) {
      const duplicateRegistration = this.props.accountRegistrationRequests?.some(
        (a) => a.externalDebtorId === this.state.externalDebtorId
      );

      if (duplicateRegistration) {
        NotificationManager.error(
          `${LanguageProvider.t(
            TranslationMapper.pages.offer_request_management.error_duplicate_account_registration
          )} ${this.state.externalDebtorId}`
        );
      } else {
        this.props.onFetchDebtorInformation(this.state.externalDebtorId);
      }
    }
  }

  private upsertAccountRegistrationRequest(): void {
    const newUser: IAccountRegistrationRequest = {
      name: this.props.fetchedDebtorInformation?.name?.trim() ?? "",
      email: this.props.fetchedDebtorInformation?.email?.trim() ?? "",
      houseNumber: this.props.fetchedDebtorInformation?.houseNumber ?? "",
      houseNumberAddition: this.props.fetchedDebtorInformation?.houseNumberAddition?.trim() ?? "",
      zipCode: this.props.fetchedDebtorInformation?.zipCode?.trim() ?? "",
      externalDebtorId: this.props.fetchedDebtorInformation?.externalDebtorId?.trim() ?? "",
      debtorId: this.props.fetchedDebtorInformation?.debtorId ?? "",
      createdOn: new Date(),
      accountRegistrationCompletedOn: undefined,
      accountRegistrationOwner: undefined,
      accountRegistrationSendOn: undefined,
      accountRegistrationStatus: LanguageProvider.t(TranslationMapper.pages.offer_request_management.request_status_5),
      accountRegistrationRequestId: undefined,
    };

    this.props.onUpsertAccountRegistration(newUser, this.onUpsertUserSuccess);
  }

  private onUpsertUserSuccess(): void {
    this.setShowAddModal(false);
  }

  private renderRowSubComponent(rowData: ReactTableRow): JSX.Element {
    const accountRegistrationDetails = rowData.original as IAccountRegistrationRequest;
    return (
      <div className="account-registration-request-management-table-row-sub">
        <Container fluid>
          <Row>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_email)}</b>
              </Col>
              <Col md>{accountRegistrationDetails.email}</Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.column_zipcode)}</b>
              </Col>
              <Col md>{accountRegistrationDetails.zipCode}</Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.column_housenumber)}</b>
              </Col>
              <Col md>{accountRegistrationDetails.houseNumber}</Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.column_housenumber_addition)}</b>
              </Col>
              <Col md>
                {accountRegistrationDetails.houseNumberAddition ? accountRegistrationDetails.houseNumberAddition : "-"}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_invitation_owner)}</b>
              </Col>
              <Col md>
                {accountRegistrationDetails.accountRegistrationOwner
                  ? accountRegistrationDetails.accountRegistrationOwner
                  : "-"}
              </Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_invitation_send_date)}</b>
              </Col>
              <Col md>
                {accountRegistrationDetails.accountRegistrationSendOn
                  ? DateUtils.getFriendlyDateTimeString(
                      DateUtils.utcToLocal(accountRegistrationDetails.accountRegistrationSendOn)
                    )
                  : "-"}
              </Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_invitation_completed_on)}</b>
              </Col>
              <Col md>
                {accountRegistrationDetails.accountRegistrationCompletedOn
                  ? DateUtils.getFriendlyDateTimeString(
                      DateUtils.utcToLocal(accountRegistrationDetails.accountRegistrationCompletedOn)
                    )
                  : "-"}
              </Col>
            </Col>
            <Col md className="account-registration-table-col">
              <Button
                style={ButtonStyle.blueOutline}
                label={LanguageProvider.t(TranslationMapper.components.labels.send_invitation)}
                isDisabled={
                  accountRegistrationDetails.accountRegistrationStatus !== this.statusCreated &&
                  accountRegistrationDetails.accountRegistrationStatus !== this.statusSent
                }
                onClick={(): void => this.sendInvitation(accountRegistrationDetails.accountRegistrationRequestId ?? "")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="account-registration-request-management-page">
        {this.props.isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!this.props.isLoading && (
          <>
            <div className="account-registration-request-management-table-top">
              <div className="p-2">
                <Button
                  style={ButtonStyle.blue}
                  label={LanguageProvider.t(TranslationMapper.components.labels.add_registration_request)}
                  onClick={(): void => this.setShowAddModal(true)}
                />
              </div>
            </div>
            <ReactTable
              columns={this.getColumns}
              data={this.props.accountRegistrationRequests ?? []}
              renderRowSubComponent={this.renderRowSubComponent}
              className="account-registration-request-management-table"
              usePaginationForTable={false}
              defaultColumnToSortBy={{
                id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.createdOn),
                desc: true,
              }}
            />

            <ConfirmationPrompt
              showPrompt={this.state.showDeleteConfirmationPrompt}
              onCancel={(): void => this.showDeleteAccountRegistrationRequest(undefined, false, undefined)}
              onAgree={(): void => this.onDeleteRequest(this.state.requestIdToDelete)}
            />

            <BasicPopup
              showPopup={this.state.showAddModal}
              onClose={this.toggleShowAddModal}
              headerText={LanguageProvider.t(TranslationMapper.components.labels.add_registration_request)}
            >
              <Container>
                <Row className="account-registration-row">
                  <Col lg={6}>
                    <span>{LanguageProvider.t(TranslationMapper.components.labels.debtor_number_from_afas)}</span>
                  </Col>
                  <Col lg={6}>
                    <input
                      className="account-registration-debtor-input"
                      value={this.props.externalDebtorNumber}
                      onChange={this.newUserDebtorId}
                      type="number"
                    />
                  </Col>
                </Row>
                <div className="account-registration-check-buttons">
                  <Button
                    style={ButtonStyle.blueOutline}
                    onClick={this.checkExternalDebtorId}
                    label={
                      this.props.fetchingDebtorInformation
                        ? LanguageProvider.t(TranslationMapper.components.labels.check_debtor_number_checking)
                        : LanguageProvider.t(TranslationMapper.components.labels.check_debtor_number)
                    }
                    isDisabled={this.state.externalDebtorId === undefined}
                  />
                </div>
              </Container>
              {this.props.fetchedDebtorInformation && (
                <Container className="pt-4">
                  <Row className="account-registration-fetched-info-row">
                    <Col lg={6}>
                      <span>{LanguageProvider.t(TranslationMapper.components.labels.column_name)}</span>
                    </Col>
                    <Col lg={6} className="account-registration-fetched-info-right-col">
                      {this.props.fetchedDebtorInformation.name}
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row">
                    <Col lg={6}>
                      <span>{LanguageProvider.t(TranslationMapper.components.labels.label_email)}</span>
                    </Col>
                    <Col lg={6} className="account-registration-fetched-info-right-col">
                      {this.props.fetchedDebtorInformation.email}
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row">
                    <Col lg={6}>
                      <span>{LanguageProvider.t(TranslationMapper.components.labels.column_zipcode)}</span>
                    </Col>
                    <Col lg={6} className="account-registration-fetched-info-right-col">
                      {this.props.fetchedDebtorInformation.zipCode}
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row">
                    <Col lg={6}>
                      <span>{LanguageProvider.t(TranslationMapper.components.labels.column_housenumber)}</span>
                    </Col>
                    <Col lg={6} className="account-registration-fetched-info-right-col">
                      {this.props.fetchedDebtorInformation.houseNumber}
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row">
                    <Col lg={6}>
                      <span>{LanguageProvider.t(TranslationMapper.components.labels.column_housenumber_addition)}</span>
                    </Col>
                    <Col lg={6} className="account-registration-fetched-info-right-col">
                      {this.props.fetchedDebtorInformation.houseNumberAddition ?? ""}
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row">
                    <Col className="font-italic text-muted">
                      <span>
                        {LanguageProvider.t(TranslationMapper.components.labels.check_debtor_number_error_message)}
                      </span>
                    </Col>
                  </Row>
                  <Row className="account-registration-fetched-info-row mt-5">
                    <Col className="justify-content-center">
                      <Button
                        className="account-registration-add-user-button px-3"
                        style={ButtonStyle.pinkGradient}
                        label={
                          !this.props.upsertingAccountRegistration
                            ? LanguageProvider.t(TranslationMapper.components.labels.add_registration_request)
                            : LanguageProvider.t(TranslationMapper.components.labels.check_debtor_number_inserting)
                        }
                        onClick={this.upsertAccountRegistrationRequest}
                      />
                    </Col>
                  </Row>
                </Container>
              )}
            </BasicPopup>
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IAccountRegistrationManagementStateProps => ({
  accountRegistrationRequests: state.accountState.accountRegistrationRequests,
  isLoading: state.accountState.isLoading,
  fetchingDebtorInformation: state.accountState.fetchingDebtorInformation,
  fetchedDebtorInformation: state.accountState.fetchedDebtorInformation,
  upsertingAccountRegistration: state.accountState.upsertingAccountRegistration,
});

const mapDispatchToProps: IAccountRegistrationManagementDispatchProps = {
  onFetchAccountRegistrationRequests: fetchAccountRegistrations,
  onUpsertAccountRegistration: upsertAccountRegistrationRequest,
  onSendAccountRegistration: sendAccountRegistrationRequest,
  onFetchDebtorInformation: getDebtorInformation,
  onResetDebtorInformation: resetDebtorInformation,
  onDeleteAccountRegistration: deleteAccountRegistrationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountRegistrationManagement);
