import IChatMessage from "interfaces/IChatMessage";
import IChatDeletionCheckDetails from "pages/avgGdprManagement/interfaces/IChatDeletionCheckDetails";
import IChatInfo from "../../interfaces/IChatInfo";

export interface IChatStoreState {
  chatInfo: IChatInfo[];
  isLoading: boolean;
  isChatsLoading: boolean;
  isChatsDeletionLoading: boolean;
  chatConversation: IChatMessage[];
  currentTopicId?: string;
  chatsToDeleteDetails?: IChatDeletionCheckDetails;
}

export const initialChatStoreState: IChatStoreState = {
  chatInfo: [
    {
      id: "",
      fullName: "",
    },
  ],
  isLoading: false,
  isChatsDeletionLoading: false,
  isChatsLoading: false,
  chatConversation: [
    {
      chatId: "",
      senderId: "",
      topicId: "",
      senderName: "",
      chatType: 0,
      createdOn: "",
    },
  ],
};
