import BasicPopup from "components/basicPopup/basicPopup";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { deleteAllChatConversationsForUser, fetchChatDeletionCheckDetails } from "store/actions/chatActions";
import { RootState } from "store/reducers/rootReducer";

import { RegexCollection } from "enums/regexCollection";
import IAvgGdprManagementProps, {
  IAvgGdprManagementDispatchProps,
  IAvgGdprManagementStateProps,
} from "./interfaces/IAvgGdprManagementProps";
import IAvgGdprManagementState from "./interfaces/IAvgGdprManagementState";

class AvgGdprManagement extends Component<IAvgGdprManagementProps, IAvgGdprManagementState> {
  public constructor(props: IAvgGdprManagementProps) {
    super(props);

    const state: IAvgGdprManagementState = {
      showChatDeletionPrompt: false,
      invalidGuid: false,
    };

    this.state = state;

    this.onChangeChatEndUserObjectId = this.onChangeChatEndUserObjectId.bind(this);
    this.onCancelDeleteChat = this.onCancelDeleteChat.bind(this);
    this.onDeleteChatClick = this.onDeleteChatClick.bind(this);
    this.onDeleteChatConfirmation = this.onDeleteChatConfirmation.bind(this);
    this.resetChatDeletion = this.resetChatDeletion.bind(this);
  }

  private onChangeChatEndUserObjectId(event: ChangeEvent<HTMLInputElement>): void {
    const input = event.target.value;

    this.setState({
      invalidGuid: false,
      endUserObjectIdForChat: input == null || input === "" ? undefined : input,
    });
  }

  private onCancelDeleteChat(): void {
    this.setState({
      showChatDeletionPrompt: false,
    });
  }

  private onDeleteChatClick(): void {
    const endUserObjectId = this.state.endUserObjectIdForChat;
    const guidRegex = RegexCollection.Guid;

    if (endUserObjectId == null || endUserObjectId === "" || this.state.invalidGuid) {
      return;
    }

    if (!endUserObjectId.match(guidRegex)) {
      this.setState({
        invalidGuid: true,
      });
      return;
    }

    this.props.onFetchChatDeletionDetails(endUserObjectId, this.onCancelDeleteChat);
    this.setState({
      showChatDeletionPrompt: true,
    });
  }

  private onDeleteChatConfirmation(): void {
    if (this.props.chatDeletionDetails == null || this.state.endUserObjectIdForChat == null) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.error_delete_chats));
      return;
    }

    this.props.onDeleteChatsForEndUser(this.state.endUserObjectIdForChat, this.resetChatDeletion);
  }

  private get allowedToProceed(): boolean {
    if (this.props.isLoading || !this.state.endUserObjectIdForChat || !this.props.chatDeletionDetails) {
      return false;
    }

    const cleanedObjectId = (value: string): string => value.trim().toLowerCase();

    return (
      cleanedObjectId(this.state.endUserObjectIdForChat) ===
      cleanedObjectId(this.props.chatDeletionDetails.endUserObjectId)
    );
  }

  private resetChatDeletion(): void {
    this.setState({
      showChatDeletionPrompt: false,
      endUserObjectIdForChat: undefined,
    });
  }

  private get deleteChatConfirmationFooter(): JSX.Element {
    return (
      <div className="g-confirmation-prompt-buttons">
        <Button
          style={ButtonStyle.blueOutline}
          onClick={this.onCancelDeleteChat}
          label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
        />

        <Button
          style={ButtonStyle.blue}
          onClick={this.onDeleteChatConfirmation}
          label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
          isDisabled={
            this.props.isLoading ||
            !this.allowedToProceed ||
            this.props.chatDeletionDetails?.endUserName == null ||
            this.props.chatDeletionDetails.numberOfChatsToBeDeleted === 0
          }
        />
      </div>
    );
  }

  private get deleteChatConfirmationBody(): JSX.Element {
    const warning = LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.delete_chat_warning);

    return (
      <>
        <div>{warning}</div>
        <div className="delete-chats-confirmation-details-container">
          {this.props.isLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}

          {this.props.chatDeletionDetails && !this.props.isLoading && (
            <>
              <div className="delete-chats-confirmation-details-row">
                <h3>{LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.enduser_name)}:</h3>
                <div>
                  {this.props.chatDeletionDetails.endUserName != null
                    ? this.props.chatDeletionDetails.endUserName
                    : LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.unknown_user)}
                </div>
              </div>
              <div className="delete-chats-confirmation-details-row">
                <h3>{LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.number_of_chats)}:</h3>
                <div>{this.props.chatDeletionDetails.numberOfChatsToBeDeleted}</div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="avg-gdpr-management">
        <h1>{LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.page_title)}</h1>

        <div className="avg-gdpr-section-container">
          <h2>{LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.chat_title)}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.delete_chat_description),
            }}
          />
          <Form.Control
            type="text"
            value={this.state.endUserObjectIdForChat ?? ""}
            placeholder={LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.enduser_input_placeholder)}
            onChange={this.onChangeChatEndUserObjectId}
          />
          <Button
            label={LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.delete_chat_button)}
            onClick={this.onDeleteChatClick}
          />
        </div>
        {this.state.invalidGuid && (
          <div className="invalid-guid-warning">
            <p>{LanguageProvider.t(TranslationMapper.pages.avg_gdpr_management.invalid_guid)}</p>
          </div>
        )}

        <BasicPopup
          onClose={this.onCancelDeleteChat}
          showPopup={this.state.showChatDeletionPrompt}
          headerText={LanguageProvider.t(TranslationMapper.global.messages.confirmation_message)}
          className={"g-confirmation-prompt delete-chats-prompt"}
          footerContent={this.deleteChatConfirmationFooter}
        >
          {this.deleteChatConfirmationBody}
        </BasicPopup>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IAvgGdprManagementStateProps => ({
  isLoading: state.chatState.isChatsDeletionLoading,
  chatDeletionDetails: state.chatState.chatsToDeleteDetails,
});

const mapDispatchToProps: IAvgGdprManagementDispatchProps = {
  onDeleteChatsForEndUser: deleteAllChatConversationsForUser,
  onFetchChatDeletionDetails: fetchChatDeletionCheckDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AvgGdprManagement);
