const dateMiddleware = (store) => (next) => (action) => {
  // Check if the action payload contains a date object
  if (action.payload) {
    for (const property in action.payload) {
      if (action?.payload[property] instanceof Date) {
        action.payload[property] = action.payload[property].toISOString();
      }
    }
  }

  // // Continue with the next middleware or the reducer
  return next(action);
};

export default dateMiddleware;
