import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import {
  IPostalCodeRequestStoreState,
  initialPostalCodeRequestStoreState,
} from "store/state/PostalCodeRequestStoreState";

function postalCodeRequestReducer(
  state: IPostalCodeRequestStoreState = initialPostalCodeRequestStoreState,
  action: ActionTypesCollection
): IPostalCodeRequestStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_POSTAL_CODE_REQUEST:
      return { ...state, isLoading: action.payload };
    case ActionTypes.UPDATE_LOADING_SEND_EMAIL:
      return { ...state, sendEmailIsLoading: action.payload };
    case ActionTypes.UPDATE_PATCH_POSTAL_CODE_REQUEST_STATUS:
      return { ...state, patchPostalCodeRequestsLoading: action.payload };
    case ActionTypes.FETCHED_POSTAL_CODE_REQUESTS:
      return { ...state, fetchedPostalCodeRequests: [...action.payload] };
    default:
      return state;
  }
}

export default postalCodeRequestReducer;
