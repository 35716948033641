import BasicPopup from "components/basicPopup/basicPopup";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import EditIcon from "images/edit.svg";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { Column } from "react-table";
import { deleteNotification, getListAllNotifications, upsertNotification } from "store/actions/notificationActions";
import { RootState } from "store/reducers/rootReducer";
import INotificationProps, {
  INotificationDispatchProps as INnotificationDispatchProps,
  INotificationStateProps,
} from "./interfaces/INotificationProps";
import { INotificationItemsState } from "./interfaces/INotificationState";

import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import { ReactTable } from "components/material/table/reactTable";
import INotification from "interfaces/INotification";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";

class Notification extends Component<INotificationProps, INotificationItemsState> {
  private readonly tableDataNotificationObject: INotification = {
    endDate: "",
    id: "",
    message: "",
    resetViewedBy: false,
    startDate: "",
    title: "",
    url: "",
  };

  public constructor(props: INotificationProps) {
    super(props);

    const state: INotificationItemsState = {
      isCustomErrorNotificationShown: false,
      isDeleteModalShown: false,
      isUpsertConfirmationModalShown: false,
      isUpsertModalShown: false,
      notificationUpsert: {
        endDate: "",
        id: "",
        message: "",
        resetViewedBy: false,
        startDate: "",
        title: "",
        url: "",
      },
    };

    this.state = state;

    this.showUpsertModal = this.showUpsertModal.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onChangeDateStart = this.onChangeDateStart.bind(this);
    this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
    this.validateUpsertModal = this.validateUpsertModal.bind(this);
    this.completeUpsertModal = this.completeUpsertModal.bind(this);
  }

  public componentDidMount(): void {
    this.getListAllNotifications();
  }

  private get isEditting(): boolean {
    return this.state.notificationUpsert?.id !== undefined;
  }

  private get upsertConfirmationHeaderText(): string {
    let headerText = this.isEditting
      ? LanguageProvider.t(TranslationMapper.pages.notification.modal.upsert_confirm.title.edit)
      : LanguageProvider.t(TranslationMapper.pages.notification.modal.upsert_confirm.title.create);

    headerText = headerText.replace("{0}", this.state.notificationUpsert.title ?? "");

    return headerText;
  }

  private getListAllNotifications(): void {
    this.props.onGetListAllNotifications();
  }

  private completeValidateUpsertModal(): void {
    const validateUpsertModal = this.validateUpsertModal();
    if (validateUpsertModal === true) {
      this.setState({ isUpsertModalShown: false, isUpsertConfirmationModalShown: true });
    } else if (validateUpsertModal === false) {
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.pages.notification.messages.error.invalid_values),
        "",
        9999
      );
    }
  }

  private completeUpsertModal(): void {
    // new record then add UUID
    const notificationSelected = { ...this.state.notificationUpsert };
    if (!this.isEditting) {
      notificationSelected.id = crypto.randomUUID();
    }

    this.props.onUpsertNotificationAsync(notificationSelected, () => {
      this.getListAllNotifications();
      this.setState({ isUpsertConfirmationModalShown: false });
      NotificationManager.success(
        LanguageProvider.t(TranslationMapper.pages.notification.messages.success.upsert_notification)
      );
    });
  }

  // return null = custom check
  private validateUpsertModal(): boolean | null {
    if (!this.state.notificationUpsert.title) {
      return false;
    }

    if (!this.state.notificationUpsert.message) {
      return false;
    }

    if (!this.state.notificationUpsert.startDate) {
      return false;
    }

    if (
      this.state.notificationUpsert.url &&
      this.state.notificationUpsert.url.length > 0 &&
      !["http://", "https://"].some((v) => this.state.notificationUpsert?.url.includes(v))
    ) {
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.pages.notification.messages.error.invalid_url),
        "",
        9999
      );
      return null;
    }

    if (!this.state.notificationUpsert.endDate) {
      return false;
    } else {
      // Check end date is in the future of start date
      const startDate = new Date(this.state.notificationUpsert.startDate);
      const endDate = new Date(this.state.notificationUpsert.endDate);
      if (startDate >= endDate) {
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.notification.messages.error.invalid_end_date),
          "",
          9999
        );
        return null;
      }
    }

    return true;
  }

  private showUpsertModal(doShow: boolean, requestId: string | undefined): void {
    const notification = this.props.notifications?.find((x) => x.id === requestId);

    this.setState({
      isUpsertModalShown: doShow,
      notificationUpsert: notification ?? ({} as INotification),
    });
  }

  private toggleUpsertStepModals(): void {
    this.setState({
      isUpsertModalShown: !this.state.isUpsertModalShown,
      isUpsertConfirmationModalShown: !this.state.isUpsertConfirmationModalShown,
    });
  }

  private showDeleteModal(doShow: boolean, requestId: string): void {
    const notification = this.props.notifications?.find((x) => x.id === requestId);
    this.setState({
      isDeleteModalShown: doShow,
      notificationUpsert: notification ?? ({} as INotification),
    });
  }

  private onTitleChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, title: value },
    });
  }

  private onMessageChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, message: value },
    });
  }

  private onUrlChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, url: value },
    });
  }

  private onChangeDateStart(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, startDate: value },
    });
  }

  private onChangeDateEnd(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, endDate: value },
    });
  }

  private onChangeResetViewedBy(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.checked;
    this.setState({
      notificationUpsert: { ...this.state.notificationUpsert, resetViewedBy: value },
    });
  }

  private onDelete(): void {
    if (this.state.notificationUpsert?.id !== "") {
      this.props.onDeleteNotification(this.state.notificationUpsert.id, () => {
        this.getListAllNotifications();
        this.showDeleteModal(false, "");

        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.notification.messages.success.delete_notification)
        );
      });
    }
  }

  private get getColumns(): Column[] {
    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.title),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.title),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.message),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.message),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.start_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.startDate),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.end_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.endDate),
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.id),
        Cell: ({ row }): JSX.Element => {
          return (
            <div className="g-react-table__actions">
              <div>
                <img
                  src={EditIcon}
                  alt="Edit notification"
                  onClick={(): void => this.showUpsertModal(true, row.values.id)}
                  className="g-react-table__action"
                />

                <img
                  src={DeleteIcon}
                  alt="Delete notification"
                  onClick={(): void => this.showDeleteModal(true, row.values.id)}
                  className="g-react-table__action"
                />
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
    ];
    return columns;
  }

  public renderUpsertModal(): JSX.Element {
    return (
      <>
        <BasicPopup
          className="g-confirmation-prompt"
          headerText={LanguageProvider.t(
            this.isEditting
              ? TranslationMapper.pages.notification.modal.upsert.title.edit
              : TranslationMapper.pages.notification.modal.upsert.title.create
          )}
          onClose={(): void => this.showUpsertModal(false, undefined)}
          showPopup={this.state.isUpsertModalShown}
          footerContent={
            <div className="g-confirmation-prompt-buttons">
              <Button
                style={ButtonStyle.blueOutline}
                onClick={(): void => this.showUpsertModal(false, undefined)}
                label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
              />
              <Button
                style={ButtonStyle.blue}
                onClick={(): void => this.completeValidateUpsertModal()}
                label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
              />
            </div>
          }
        >
          {this.props.isLoadingGetAllNotifications ? (
            <div className="w-100 d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="mx-4 mb-5">
              <div className="grouped-filter mb-3">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.title)}*
                </div>
                <Form.Control
                  type="text"
                  value={this.state.notificationUpsert.title ?? ""}
                  onChange={this.onTitleChange}
                  maxLength={50}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.title)}
                ></Form.Control>
                <div className="form-text">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.form_text.title)}
                </div>
              </div>

              <div className="grouped-filter mb-3">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.message)}*
                </div>
                <Form.Control
                  type="text"
                  value={this.state.notificationUpsert.message ?? ""}
                  onChange={this.onMessageChange}
                  maxLength={100}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.message)}
                ></Form.Control>
                <div className="form-text">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.form_text.message)}
                </div>
              </div>

              <div className="grouped-filter mb-3">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.url)}
                </div>
                <Form.Control
                  type="text"
                  value={this.state.notificationUpsert.url ?? ""}
                  onChange={this.onUrlChange}
                  maxLength={50}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.url)}
                ></Form.Control>
                <div className="form-text">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.form_text.url)}
                </div>
              </div>

              <div className="grouped-filter mb-3">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.start_date)}*
                </div>
                <Form.Control
                  type="text"
                  value={this.state.notificationUpsert.startDate ?? ""}
                  onChange={this.onChangeDateStart}
                  onFocus={(e): string => (e.target.type = "datetime-local")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.start_date)}
                ></Form.Control>
              </div>

              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.end_date)}*
                </div>
                <Form.Control
                  type="text"
                  value={this.state.notificationUpsert.endDate ?? ""}
                  onChange={this.onChangeDateEnd}
                  onFocus={(e): string => (e.target.type = "datetime-local")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.end_date)}
                ></Form.Control>
                <div className="form-text">
                  {LanguageProvider.t(TranslationMapper.pages.notification.upsert.form_text.end_date)}
                </div>
              </div>

              {/* Reset all "viewed by" records for current notification */}
              {this.isEditting && (
                <div className="grouped-filter mt-3">
                  <hr />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="true"
                      id="resetViewedBy"
                      onChange={(e): void => this.onChangeResetViewedBy(e)}
                    />
                    <label className="form-check-label" htmlFor="resetViewedBy">
                      {LanguageProvider.t(TranslationMapper.pages.notification.upsert.labels.reset_viewed_by)}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </BasicPopup>

        <ConfirmationPrompt
          onAgree={this.completeUpsertModal}
          onCancel={(): void => this.toggleUpsertStepModals()}
          showPrompt={this.state.isUpsertConfirmationModalShown}
          headerText={this.upsertConfirmationHeaderText}
          isAgreeLoading={this.props.isLoadingUpsertNotification}
          buttonLabelLeft={LanguageProvider.t(TranslationMapper.global.buttons.return)}
          id="upsert-notification-confirmation"
        >
          {LanguageProvider.t(TranslationMapper.pages.notification.modal.upsert_confirm.body.message).replace(
            "{0}",
            this.state.notificationUpsert.message
          )}
          <br />
          {LanguageProvider.t(TranslationMapper.pages.notification.modal.upsert_confirm.body.start_date).replace(
            "{0}",
            this.state.notificationUpsert.startDate
          )}
          <br />
          {LanguageProvider.t(TranslationMapper.pages.notification.modal.upsert_confirm.body.end_date).replace(
            "{0}",
            this.state.notificationUpsert.endDate
          )}
        </ConfirmationPrompt>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="notification-item-page">
        <div className="react-table__top">
          <Button
            style={ButtonStyle.blue}
            onClick={(): void => this.showUpsertModal(true, undefined)}
            label={LanguageProvider.t(TranslationMapper.pages.notification.upsert.create_new)}
          />
        </div>
        <ReactTable
          columns={this.getColumns}
          data={this.props.notifications}
          usePaginationForTable={true}
          defaultColumnToSortBy={{
            id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataNotificationObject, (x) => x.startDate),
            desc: false,
          }}
          isLoading={this.props.isLoadingGetAllNotifications}
        />

        {/* Modal Upsert */}
        {this.renderUpsertModal()}

        {/* Modal Delete */}
        <ConfirmationPrompt
          showPrompt={this.state.isDeleteModalShown}
          onCancel={(): void => this.showDeleteModal(false, "")}
          onAgree={(): void => this.onDelete()}
          isAgreeLoading={this.props.isLoadingDeleteNotification}
          subheaderText={LanguageProvider.t(TranslationMapper.pages.notification.delete.header).replace(
            "{0}",
            this.state.notificationUpsert.title ?? ""
          )}
        ></ConfirmationPrompt>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): INotificationStateProps => ({
  isLoadingDeleteNotification: state.notificationState.isLoadingDeleteNotification,
  isLoadingGetAllNotifications: state.notificationState.isLoadingGetAllNotifications,
  isLoadingUpsertNotification: state.notificationState.isLoadingUpsertNotification,
  notifications: state.notificationState.notifications,
});

const mapDispatchToProps: INnotificationDispatchProps = {
  onDeleteNotification: deleteNotification,
  onGetListAllNotifications: getListAllNotifications,
  onUpsertNotificationAsync: upsertNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
