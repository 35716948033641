import IActiveRegion from "../../interfaces/IActiveRegion";

export interface IRegionStoreState {
  activeRegions?: IActiveRegion[];
  isLoading: boolean;
}

export const initialRegionStoreState: IRegionStoreState = {
  isLoading: false,
};
