import { IActivationStoreState, initialActivationStoreState } from "store/state/activationStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function activationReducer(
  state: IActivationStoreState = initialActivationStoreState,
  action: ActionTypesCollection
): IActivationStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_ACTIVATION:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default activationReducer;
