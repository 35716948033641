/* eslint-disable @typescript-eslint/explicit-function-return-type */
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import ManagementService from "services/managementService";
import TelemetryService from "services/telemetryService";
import IActiveRegion from "../../interfaces/IActiveRegion";
import { actionCreator, AppAction } from "../appAction";

import IUpsertActiveReqionRequest from "interfaces/IUpsertActiveReqionRequest";
import { ActionTypes } from "../actionTypes";

export const setRegionsLoading = actionCreator<ActionTypes.UPDATE_LOADING_REGIONS, boolean>(
  ActionTypes.UPDATE_LOADING_REGIONS
);
export const fetchedActiveRegions = actionCreator<ActionTypes.FETCHED_ACTIVE_REGIONS, IActiveRegion[]>(
  ActionTypes.FETCHED_ACTIVE_REGIONS
);
export const updateActiveRegions = actionCreator<ActionTypes.UPDATE_ACTIVE_REGIONS, IActiveRegion[]>(
  ActionTypes.UPDATE_ACTIVE_REGIONS
);

export function getActiveRegions(): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleRegionLoadingStatus(true));

    managementService
      .getActiveRegionsAsync()
      .then((servicesCategories) => dispatch(fetchedActiveRegions(servicesCategories)))
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "regionActions.getActiveRegions",
            message: `Error retrieving active regions ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_fetching_active_regions));
        console.warn(error);
      })
      .finally(() => dispatch(toggleRegionLoadingStatus(false)));
  };
}

export function deleteActiveRegion(region: number): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleRegionLoadingStatus(true));

    managementService
      .deleteActiveRegionAsync(region)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.global.success.succces_deleting_active_region)
        );
        dispatch(getActiveRegions());
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "regionActions.deleteActiveRegion",
            message: `Error on deleting active region ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_deleting_active_region));
      })
      .finally(() => dispatch(toggleRegionLoadingStatus(false)));
  };
}

export function upsertActiveRegions(request: IUpsertActiveReqionRequest, onSuccess: () => void): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleRegionLoadingStatus(true));

    managementService
      .upsertActiveRegionAsync(request)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.global.success.succces_adding_new_active_region)
        );
        dispatch(getActiveRegions());
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "regionActions.upsertActiveRegions",
            message: `Error on updating active regions ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_updating_active_regions));
      })
      .finally(() => dispatch(toggleRegionLoadingStatus(false)));
  };
}

export function toggleRegionLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch) => {
    dispatch(setRegionsLoading(isLoading));
  };
}
