import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import TranslationMapper from "i18n/mapper";
import IActiveRegion from "interfaces/IActiveRegion";
import IUpsertActiveReqionRequest from "interfaces/IUpsertActiveReqionRequest";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { replaceStringPlaceholders } from "utils/stringUtils";

import { AuthByRole } from "enums/userRole";
import AuthProvider from "providers/authProvider";
import DeleteIcon from "../../images/delete.svg";
import AddIcon from "../../images/plus.svg";
import { deleteActiveRegion, getActiveRegions, upsertActiveRegions } from "../../store/actions/regionActions";
import { RootState } from "../../store/reducers/rootReducer";
import IActiveRegionsProps, {
  IActiveRegionsDispatchProps,
  IActiveRegionsStateProps,
} from "./interfaces/IActiveRegionsProps";
import IActiveRegionsState from "./interfaces/IActiveRegionsState";

class ActiveRegions extends Component<IActiveRegionsProps, IActiveRegionsState> {
  public constructor(props: IActiveRegionsProps) {
    super(props);

    const state: IActiveRegionsState = {
      showDeleteConfirmationPrompt: false,
      showAddRegionConfirmationPrompt: false,
      showAddRegionsPopup: false,
    };

    this.state = state;

    this.onDeleteActiveRegion = this.onDeleteActiveRegion.bind(this);
    this.showDeleteConfirmationPrompt = this.showDeleteConfirmationPrompt.bind(this);
    this.toggleDeleteConfirmationPrompt = this.toggleDeleteConfirmationPrompt.bind(this);
    this.toggleShowAddRegionsPopup = this.toggleShowAddRegionsPopup.bind(this);
    this.onSubmitActiveRegionRequest = this.onSubmitActiveRegionRequest.bind(this);
    this.newRegionBeginChanged = this.newRegionBeginChanged.bind(this);
    this.newRegionEndChanged = this.newRegionEndChanged.bind(this);
    this.newRegionCostCenterChanged = this.newRegionCostCenterChanged.bind(this);
    this.clearNewRegionEntry = this.clearNewRegionEntry.bind(this);
    this.toggleAddRegionConfirmationPrompt = this.toggleAddRegionConfirmationPrompt.bind(this);
    this.validateNewRegionEntry = this.validateNewRegionEntry.bind(this);
    this.onSubmitActiveRegionRequest = this.onSubmitActiveRegionRequest.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.activeRegions.length === 0) {
      this.props.onFetchActiveRegions();
    }
  }

  private toggleShowAddRegionsPopup(): void {
    this.setState({
      showAddRegionsPopup: !this.state.showAddRegionsPopup,
    });
  }

  private clearNewRegionEntry(): void {
    this.setState({
      newRegionCostCenter: undefined,
      newRegionStart: undefined,
      newRegionEnd: undefined,
      showAddRegionsPopup: false,
      showAddRegionConfirmationPrompt: false,
    });
  }

  private onDeleteActiveRegion(): void {
    this.toggleDeleteConfirmationPrompt();
    if (this.state.selectedRegion) {
      this.props.onDeleteActiveRegion(this.state.selectedRegion.region);
    }
  }

  private showDeleteConfirmationPrompt(region: IActiveRegion): void {
    this.setState({
      selectedRegion: region,
      showDeleteConfirmationPrompt: !this.state.showDeleteConfirmationPrompt,
    });
  }

  private toggleDeleteConfirmationPrompt(): void {
    this.setState({
      selectedRegion: undefined,
      showDeleteConfirmationPrompt: !this.state.showDeleteConfirmationPrompt,
    });
  }

  private get confirmationDeleteRegionMessage(): string {
    if (this.state.selectedRegion) {
      return replaceStringPlaceholders(
        LanguageProvider.t(TranslationMapper.components.active_regions.active_regions_confirm_delete),
        this.state.selectedRegion.region.toString()
      );
    }

    return LanguageProvider.t(TranslationMapper.global.messages.confirmation_message);
  }

  private get addRegionMessage(): string {
    if (this.state.newRegionStart && this.state.newRegionEnd) {
      return replaceStringPlaceholders(
        LanguageProvider.t(TranslationMapper.components.active_regions.active_regions_confirm_add),
        this.state.newRegionStart.toString(),
        this.state.newRegionEnd.toString()
      );
    }

    return LanguageProvider.t(TranslationMapper.global.messages.confirmation_message);
  }

  private newRegionBeginChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.valueAsNumber;
    this.setState({
      newRegionStart: !isNaN(value) ? value : undefined,
    });
  }

  private newRegionEndChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.valueAsNumber;
    this.setState({
      newRegionEnd: !isNaN(value) ? value : undefined,
    });
  }

  private newRegionCostCenterChanged(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newRegionCostCenter: event.target.value });
  }

  private onSubmitActiveRegionRequest(): void {
    if (this.isNewRegionInputValid) {
      const newRegion: IUpsertActiveReqionRequest = {
        costCenter: this.state.newRegionCostCenter!.trim(),
        regionBegin: this.state.newRegionStart!,
        regionEnd: this.state.newRegionEnd ?? this.state.newRegionStart!,
      };

      this.props.onUpdateActiveRegions(newRegion, this.clearNewRegionEntry);
    } else {
      const popupTimeOut = 99999;
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.components.active_regions.error_invalid_new_region),
        "",
        popupTimeOut
      );
    }
  }

  private validateNewRegionEntry(): void {
    if (this.isNewRegionInputValid) {
      this.toggleShowAddRegionsPopup();
      this.toggleAddRegionConfirmationPrompt();
    } else {
      const popupTimeOut = 99999;
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.components.active_regions.error_invalid_new_region),
        "",
        popupTimeOut
      );
    }
  }

  private toggleAddRegionConfirmationPrompt(): void {
    this.setState({ showAddRegionConfirmationPrompt: !this.state.showAddRegionConfirmationPrompt });
  }

  private get isNewRegionInputValid(): boolean {
    const regionRangeMinimum = 1000;
    const regionRangeMax = 9999;
    const costCenterMinLength = 1;
    const costCenterMaxLength = 16;

    const regionRangeStart = this.state.newRegionStart;
    const regionRangeEnd = this.state.newRegionEnd;
    const regionCostCenter = this.state.newRegionCostCenter;

    const isStartValid =
      regionRangeStart != null && regionRangeStart >= regionRangeMinimum && regionRangeStart <= regionRangeMax;
    const isEndDateFilledCorrectly =
      isStartValid &&
      regionRangeEnd != null &&
      regionRangeEnd >= regionRangeMinimum &&
      regionRangeEnd >= regionRangeStart &&
      regionRangeEnd <= regionRangeMax;
    // When end remains empty, we set it equal to start on submit
    const isEndValid = (isStartValid && regionRangeEnd == null) || isEndDateFilledCorrectly;

    const isCostCenterValid =
      regionCostCenter != null &&
      regionCostCenter.length > costCenterMinLength &&
      regionCostCenter.length <= costCenterMaxLength;

    return isStartValid && isEndValid && isCostCenterValid;
  }

  public render(): JSX.Element {
    return (
      <Page className="active-regions-overview">
        <h1>
          {LanguageProvider.t(TranslationMapper.components.active_regions.active_regions_header)}
          {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ActiveRegionsReadWrite) && (
            <img
              src={AddIcon}
              className="clickable active-regions-add-button"
              alt="Add"
              onClick={this.toggleShowAddRegionsPopup}
            ></img>
          )}
        </h1>
        {this.props.isLoading && (
          <div className="active-regions-overview-loading">
            {LanguageProvider.t(TranslationMapper.global.messages.loading_active_regions)}
          </div>
        )}
        <Container className="active-regions-overview-container" fluid={true}>
          {this.props.activeRegions?.length > 0 && (
            <table className="active-regions-overview-table">
              <thead>
                <tr>
                  <th className="active-regions-align-right">
                    {LanguageProvider.t(TranslationMapper.components.active_regions.active_regions_region)}
                  </th>
                  <th className="active-regions-align-right">
                    {LanguageProvider.t(TranslationMapper.components.active_regions.active_regions_cost_center)}
                  </th>
                  {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ActiveRegionsReadWrite) && <th>&nbsp;</th>}
                </tr>
              </thead>
              <tbody>
                {this.props.activeRegions.map((activeRegion, index) => {
                  const showDeleteConfirmationPrompt = (): void => this.showDeleteConfirmationPrompt(activeRegion);
                  return (
                    <tr key={index}>
                      <td className="active-regions-align-right">{activeRegion.region}</td>
                      <td className="active-regions-align-right">{activeRegion.costCenter}</td>
                      {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ActiveRegionsReadWrite) && (
                        <td className="active-regions-align-center">
                          <img
                            src={DeleteIcon}
                            className="clickable delete-button"
                            alt="delete"
                            onClick={showDeleteConfirmationPrompt}
                          ></img>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Container>

        <ConfirmationPrompt
          onAgree={this.onDeleteActiveRegion}
          onCancel={this.toggleDeleteConfirmationPrompt}
          showPrompt={this.state.showDeleteConfirmationPrompt}
          headerText={this.confirmationDeleteRegionMessage}
          id="delete-region-confirmation"
        />

        <ConfirmationPrompt
          onAgree={this.onSubmitActiveRegionRequest}
          onCancel={this.toggleAddRegionConfirmationPrompt}
          showPrompt={this.state.showAddRegionConfirmationPrompt}
          headerText={this.addRegionMessage}
          id="add-region-confirmation"
        />

        <BasicPopup
          showPopup={this.state.showAddRegionsPopup}
          onClose={this.toggleShowAddRegionsPopup}
          headerText={LanguageProvider.t(TranslationMapper.components.active_regions.new_region_header)}
        >
          <Row className="active-region-new-region-row">
            <Col lg={8}>
              <p className="active-region-new-region-label">
                {LanguageProvider.t(TranslationMapper.components.active_regions.new_region_range_begin)}
              </p>
            </Col>
            <Col lg={4}>
              <input
                type="number"
                className="active-region-new-region-input"
                value={this.state.newRegionStart ?? ""}
                onChange={this.newRegionBeginChanged}
              />
            </Col>
          </Row>

          <Row className="active-region-new-region-row">
            <Col lg={8}>
              <p className="active-region-new-region-label">
                {LanguageProvider.t(TranslationMapper.components.active_regions.new_region_range_end)}
              </p>
            </Col>
            <Col lg={4}>
              <input
                type="number"
                className="active-region-new-region-input"
                value={this.state.newRegionEnd ?? ""}
                onChange={this.newRegionEndChanged}
              />
            </Col>
          </Row>

          <Row className="active-region-new-region-row">
            <Col lg={8}>
              <p className="active-region-new-region-label">
                {LanguageProvider.t(TranslationMapper.components.active_regions.new_region_cost_center)}
              </p>
            </Col>
            <Col lg={4}>
              <input
                className="active-region-new-region-input"
                value={this.state.newRegionCostCenter ?? ""}
                onChange={this.newRegionCostCenterChanged}
              />
            </Col>
          </Row>

          <div className="btn active-region-confirmation-prompt-buttons">
            <Button
              className="active-region-confirmation-cancel-button"
              onClick={this.toggleShowAddRegionsPopup}
              label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
            />

            <Button
              className="active-region-confirmation-agree-button"
              onClick={this.validateNewRegionEntry}
              style={ButtonStyle.blue}
              label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
            />
          </div>
        </BasicPopup>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IActiveRegionsStateProps => ({
  activeRegions: state.regionState.activeRegions ?? [],
  isLoading: state.regionState.isLoading,
});

const mapDispatchToProps: IActiveRegionsDispatchProps = {
  onFetchActiveRegions: getActiveRegions,
  onDeleteActiveRegion: deleteActiveRegion,
  onUpdateActiveRegions: upsertActiveRegions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveRegions);
