import AuthClient from "clients/authClient";
import IAuthUser, { IAuthRole, IAuthUpsertUser } from "interfaces/IAuth";

export default class AuthService {
  private readonly authClient: AuthClient = new AuthClient();

  public async deleteUserAsync(id: string): Promise<Response> {
    return this.authClient.deleteUserAsync(id);
  }

  public async getBearerToken(): Promise<string> {
    const response = await this.authClient.getAuthTokenAsync();
    if (!response) {
      throw new Error("Bearer token is null");
    }
    return response;
  }

  public async getListAllUsers(): Promise<IAuthUser[]> {
    return this.authClient.getListAllUsers();
  }

  public async getListAllRoles(): Promise<IAuthRole[]> {
    return this.authClient.getListAllRoles();
  }

  public async putUpdateUserRoles(data: IAuthUpsertUser): Promise<Response> {
    return this.authClient.putUpdateUserRoles(data);
  }
}
