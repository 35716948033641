import { Component } from "react";
import { fetchEndUserChatInfo } from "../../store/actions/chatActions";
import { RootState } from "../../store/reducers/rootReducer";
import IEndUserChatProps, { IEndUserChatDispatchProps, IEndUserChatStateProps } from "./interfaces/IEndUserChatProps";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChatOverview from "../../components/chatOverview/chatOverview";
import { ChatType } from "enums/chatType";
import { RoutingPath } from "providers/routingProvider";

class EndUserChat extends Component<IEndUserChatProps> {

    public constructor(props: IEndUserChatProps) {
        super(props);

        this.refreshChats = this.refreshChats.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.props.onFetchInfo();
    }

    private refreshChats(): void {
        this.props.onFetchInfo();
    }

    public render(): JSX.Element {
        return (
            <ChatOverview
                isLoading={this.props.isLoading}
                chatInfo={this.props.chatInfo}
                onFetchChats={this.refreshChats}
                chatType={ChatType.EndUserServiceDeskChat}
                cleanPathName={RoutingPath.chatEndUser}
            />
        );
    }
}

const mapStateToProps = (state: RootState): IEndUserChatStateProps => ({
    chatInfo: state.chatState.chatInfo,
    isLoading: state.chatState.isLoading
});

const mapDispatchToProps: IEndUserChatDispatchProps = {
    onFetchInfo: fetchEndUserChatInfo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EndUserChat));
