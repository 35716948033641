import AuthClient from "clients/authClient";
import { UserRole } from "enums/userRole";
import IAccountRegistration from "interfaces/IAccountRegistrationRequest";
import IDebtorInformation from "interfaces/IDebtorInformation";
import IPostalCodeActivatedEmail from "interfaces/IPostalCodeActivatedEmail";
import IServiceCategory from "interfaces/IServiceCategory";
import ISupplementedServiceOfferRequest from "interfaces/ISupplementedServiceOfferRequest";
import { jwtDecode } from "jwt-decode";
import CreateActivationLinkRequest from "models/createActivationLinkRequest";
import AuthProvider from "providers/authProvider";

import AccountClient from "../clients/accountClient";

export default class AccountService {
  private readonly accountClient: AccountClient = new AccountClient();
  private readonly authClient: AuthClient = new AuthClient();

  private async getBearerToken(): Promise<string> {
    const response = await this.authClient.getAuthTokenAsync();
    if (!response) {
      throw new Error("Bearer token is null");
    }
    return response;
  }

  public async updateAuthProviderAllowedPaths(): Promise<void> {
    const userRoles = await this.getUserRolesAsync();
    AuthProvider.updateIsAllowed(userRoles);
  }

  public async getUserRolesAsync(): Promise<UserRole[]> {
    const bearerToken = await this.getBearerToken();
    const decoded: any = jwtDecode(bearerToken);

    // One role is a string, multiple roles is an array
    let roles: UserRole[] | UserRole = decoded.role;

    // if none roles defined, return empty array
    if (!roles) {
      return [];
    }

    // if roles is not an array, make it an array
    if (!(roles instanceof Array)) {
      roles = [roles];
    }

    // set roles to AuthProvider
    AuthProvider.roles = roles;

    return roles;
  }

  public async sendActivationEmailAsync(createActivationLinkRequest: CreateActivationLinkRequest): Promise<Response> {
    return this.accountClient.sendActivationEmailAsync(createActivationLinkRequest);
  }

  public async getAllAvailableServicesAsync(languageCode: string): Promise<IServiceCategory[]> {
    return this.accountClient.getAllAvailableServicesAsync(languageCode);
  }

  public async getServiceOfferRequestsAsync(): Promise<ISupplementedServiceOfferRequest[]> {
    return this.accountClient.getServiceOfferRequestsAsync();
  }

  public async updateServiceOfferRequestAsync(serviceOfferRequestCode: string, price: number): Promise<Response> {
    return this.accountClient.updateServiceOfferRequestAsync(serviceOfferRequestCode, price);
  }

  public async deleteServiceOfferRequestAsync(serviceOfferRequestCode: string): Promise<Response> {
    return this.accountClient.deleteServiceOfferRequestAsync(serviceOfferRequestCode);
  }

  public async getDebtorInformationAsync(externalDebtorId: string): Promise<IDebtorInformation> {
    return this.accountClient.getDebtorInformationAsync(externalDebtorId);
  }

  public async upsertAccountRegistrationRequestAsync(request: IAccountRegistration): Promise<Response> {
    return this.accountClient.upsertAccountRegistrationRequestAsync(request);
  }

  public async getAccountRegistrationRequestsAsync(): Promise<IAccountRegistration[]> {
    return this.accountClient.getAccountRegistrationRequestsAsync();
  }

  public async sendAccountRegistrationRequestAsync(accountRegistrationRequestId: string): Promise<Response> {
    return this.accountClient.sendAccountRegistrationRequestAsync(accountRegistrationRequestId);
  }

  public async deleteAccountRegistrationRequestAsync(requestId: string): Promise<Response> {
    return this.accountClient.deleteAccountRegistrationRequestAsync(requestId);
  }

  public async sendPostalcodeActivatedEmailAsync(emailAddressess: IPostalCodeActivatedEmail): Promise<Response> {
    return this.accountClient.sendPostalcodeActivatedEmailAsync(emailAddressess);
  }
}
