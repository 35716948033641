import IAuthUser, { IAuthRole } from "interfaces/IAuth";

export interface IAuthorizationUserStoreState {
  isLoadingDelete: boolean;
  isLoadingGetAllUsers: boolean;
  isLoadingPostUser: boolean;
  users: IAuthUser[];
}

export interface IAuthRoleStoreState {
  isLoadingGetAllRoles: boolean;
  roles: IAuthRole[];
}

export interface IAuthStoreState {
  user: IAuthorizationUserStoreState;
  role: IAuthRoleStoreState;
}

export const initialAuthorizationStoreState: IAuthStoreState = {
  user: {
    isLoadingDelete: false,
    isLoadingGetAllUsers: false,
    isLoadingPostUser: false,
    users: [],
  },
  role: {
    isLoadingGetAllRoles: false,
    roles: [],
  },
};
