import { IAuthenticator } from "authenticators/interfaces/IAuthenticator";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import AuthProvider from "providers/authProvider";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export default abstract class BaseClient {
  public constructor(private readonly resource: string, private readonly authenticator?: IAuthenticator) {}

  public async getAuthToken<T>(query: string): Promise<string> {
    if (AuthProvider.token.auth.token) {
      return AuthProvider.token.auth.token;
    }

    return this.execute(query, "GET", false, true).then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }

      return response.text().then((result) => {
        AuthProvider.token.auth.token = result;

        const decoded: any = jwtDecode(result);
        AuthProvider.token.auth.time.exp = decoded.exp;
        AuthProvider.token.auth.time.iat = decoded.iat;
        AuthProvider.token.auth.time.user = moment().unix();
        if (AuthProvider.token.auth.time.iat) {
          AuthProvider.token.auth.time.diff = AuthProvider.token.auth.time.exp
            ? AuthProvider.token.auth.time.exp - AuthProvider.token.auth.time.iat
            : null;
        }

        return result;
      });
    });
  }

  public async get<T>(query: string): Promise<T> {
    return this.execute(query, "GET", false).then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }

      return response.json().then((result) => {
        return result;
      });
    });
  }

  public async post(query: string, data: any): Promise<Response> {
    return this.execute(query, "POST", data);
  }

  public async postWithResponse<T>(query: string, data: any): Promise<T> {
    return this.execute(query, "POST", data).then((r) => r.json());
  }

  public async put(query: string, data?: any): Promise<Response> {
    return this.execute(query, "PUT", data);
  }

  public async patch(query: string, data: any): Promise<Response> {
    return this.execute(query, "PATCH", data);
  }

  protected async delete(query: string): Promise<Response> {
    return this.execute(query, "DELETE", false);
  }

  private async execute(
    query: string,
    method: CRUDOperation,
    data?: any,
    hasAuthenticator?: boolean
  ): Promise<Response> {
    const url = `${this.resource}/${query}`;

    let headers = {
      "Content-type": "application/json; charset=UTF-8",
    };

    let request: RequestInit = {
      headers,
      method,
      body: data ? JSON.stringify(data) : undefined,
    };

    if (this.authenticator) {
      request = await this.authenticator.authenticateRequestAsync(request);
      const h: any = request.headers;
      if (!h.Authorization || h.Authorization.includes("null")) {
        throw Error("No token found");
      }
    }

    if (!hasAuthenticator) {
      request.headers = { ...request.headers, Authorization: `Bearer ${AuthProvider.token.auth.token}` };
    }

    return fetch(url, request).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response;
    });
  }
}
