import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";

import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";
import AddIcon from "../../images/plus.svg";
import { deleteUnitPrice, getAllUnitPrices, upsertUnitPrice } from "../../store/actions/unitPriceActions";

import { AuthByRole } from "enums/userRole";
import IUnitPrice from "interfaces/IUnitPrice";
import moment from "moment";
import AuthProvider from "providers/authProvider";
import { RootState } from "../../store/reducers/rootReducer";
import IUnitPriceProps, { IUnitPriceDispatchProps, IUnitPriceStateProps } from "./interfaces/IUnitPriceProps";
import IUnitPriceState from "./interfaces/IUnitPriceState";

class UnitPrice extends Component<IUnitPriceProps, IUnitPriceState> {
  public constructor(props: IUnitPriceProps) {
    super(props);

    const state: IUnitPriceState = {
      showDeleteConfirmationPrompt: false,
      showAddUnitPriceConfirmationPrompt: false,
      showAddUnitPricePopup: false,
    };

    this.state = state;

    this.onDeleteUnitPrice = this.onDeleteUnitPrice.bind(this);
    this.showDeleteConfirmationPrompt = this.showDeleteConfirmationPrompt.bind(this);
    this.toggleDeleteConfirmationPrompt = this.toggleDeleteConfirmationPrompt.bind(this);
    this.toggleShowAddUnitPricePopup = this.toggleShowAddUnitPricePopup.bind(this);
    this.toggleShowUpdateUnitPricePopup = this.toggleShowUpdateUnitPricePopup.bind(this);
    this.onSubmitUnitPrice = this.onSubmitUnitPrice.bind(this);
    this.newUnitPriceCostChanged = this.newUnitPriceCostChanged.bind(this);
    this.newUnitPriceCodeChanged = this.newUnitPriceCodeChanged.bind(this);
    this.newUnitPriceStartDateChanged = this.newUnitPriceStartDateChanged.bind(this);
    this.clearNewUnitPriceEntry = this.clearNewUnitPriceEntry.bind(this);
    this.toggleAddUnitPriceConfirmationPrompt = this.toggleAddUnitPriceConfirmationPrompt.bind(this);
    this.validateNewUnitPriceEntry = this.validateNewUnitPriceEntry.bind(this);
    this.onSubmitUnitPrice = this.onSubmitUnitPrice.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    if (this.props.unitPrices.length === 0) {
      this.props.onFetchUnitPrices();
    }
  }

  private toggleShowAddUnitPricePopup(): void {
    this.setState({
      showAddUnitPricePopup: !this.state.showAddUnitPricePopup,
    });
  }

  private clearNewUnitPriceEntry(): void {
    this.setState({
      newUnitPriceCode: undefined,
      newUnitPriceCost: undefined,
      newUnitPriceStartDate: undefined,
      showAddUnitPricePopup: false,
      showAddUnitPriceConfirmationPrompt: false,
      selectedUnitPrice: undefined,
    });
  }

  private onDeleteUnitPrice(): void {
    this.toggleDeleteConfirmationPrompt();
    if (this.state.selectedUnitPrice?.unitPricingId) {
      if (this.isLastUnitPriceForPriceCode) {
        const popupTimeOut = 99999;
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.unit_prices.error_deleting_unit_price),
          "",
          popupTimeOut
        );
      } else {
        this.props.onDeleteUnitPrice(this.state.selectedUnitPrice?.unitPricingId);
      }
    }
  }

  private get isLastUnitPriceForPriceCode(): boolean {
    // Can not delete unit price if it is the last unit price for a price code.
    return (
      this.props.unitPrices.find(
        (unitPrice) =>
          unitPrice.priceCode === this.state.selectedUnitPrice?.priceCode &&
          unitPrice.unitPricingId !== this.state.selectedUnitPrice.unitPricingId
      ) === undefined
    );
  }

  private showDeleteConfirmationPrompt(unitPrice: IUnitPrice): void {
    this.setState({
      selectedUnitPrice: unitPrice,
      showDeleteConfirmationPrompt: !this.state.showDeleteConfirmationPrompt,
    });
  }

  private toggleDeleteConfirmationPrompt(): void {
    this.setState({
      selectedUnitPrice: undefined,
      showDeleteConfirmationPrompt: !this.state.showDeleteConfirmationPrompt,
    });
  }

  private toggleShowUpdateUnitPricePopup(unitPrice: IUnitPrice): void {
    this.setState({
      selectedUnitPrice: unitPrice,
      newUnitPriceCost: unitPrice.unitPrice,
      newUnitPriceCode: unitPrice.priceCode,
      newUnitPriceStartDate: unitPrice.startDate,
      showAddUnitPricePopup: !this.state.showAddUnitPricePopup,
    });
  }

  private get confirmationDeleteUnitPriceMessage(): string {
    return LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_prices_confirm_delete);
  }

  private get addUnitPriceMessage(): string {
    if (this.state.newUnitPriceCode) {
      return LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_prices_confirm_add);
    }

    return LanguageProvider.t(TranslationMapper.global.messages.confirmation_message);
  }

  private newUnitPriceCostChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.valueAsNumber;
    this.setState({
      newUnitPriceCost: !isNaN(value) ? value : undefined,
    });
  }

  private newUnitPriceCodeChanged(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newUnitPriceCode: event.target.value });
  }

  private newUnitPriceStartDateChanged(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ newUnitPriceStartDate: event.target.value });
  }

  private onSubmitUnitPrice(): void {
    if (this.isNewUnitPriceInputValid) {
      const unitPrice: IUnitPrice = {
        startDate: this.state.newUnitPriceStartDate?.trim() ?? "",
        unitPrice: this.state.newUnitPriceCost ?? 0,
        priceCode: this.state.newUnitPriceCode?.trim() ?? "",
        unitPricingId: this.state.selectedUnitPrice?.unitPricingId,
      };

      this.props.onUpdateUnitPrice(unitPrice, this.clearNewUnitPriceEntry);
    } else {
      const popupTimeOut = 99999;
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.pages.unit_prices.error_invalid_new_unit_price),
        "",
        popupTimeOut
      );
    }
  }

  private validateNewUnitPriceEntry(): void {
    if (this.isNewUnitPriceInputValid) {
      this.toggleShowAddUnitPricePopup();
      this.toggleAddUnitPriceConfirmationPrompt();
    } else {
      const popupTimeOut = 99999;
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.pages.unit_prices.error_invalid_new_unit_price),
        "",
        popupTimeOut
      );
    }
  }

  private toggleAddUnitPriceConfirmationPrompt(): void {
    this.setState({ showAddUnitPriceConfirmationPrompt: !this.state.showAddUnitPriceConfirmationPrompt });
  }

  private get isNewUnitPriceInputValid(): boolean {
    const isNewUnitPriceCostValid = this.state.newUnitPriceCost != null && this.state.newUnitPriceCost > 0;
    // Can not add a unit price if a different unit price with the same code and start date already exists.
    const duplicateUnitPrice = this.props.unitPrices.find(
      (unitPrice) =>
        unitPrice.startDate === moment(this.state.newUnitPriceStartDate?.trim()).format("DD-MM-YYYY") &&
        unitPrice.priceCode === this.state.newUnitPriceCode &&
        unitPrice.unitPricingId !== this.state.selectedUnitPrice?.unitPricingId
    );

    return (
      this.state.newUnitPriceStartDate !== null &&
      isNewUnitPriceCostValid &&
      this.state.newUnitPriceCode !== null &&
      duplicateUnitPrice === undefined
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="unit-price-overview">
        <h1>
          {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_header)}
          {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceReadWrite) && (
            <img
              src={AddIcon}
              className="clickable unit-price-add-button"
              alt="Add"
              onClick={this.toggleShowAddUnitPricePopup}
            ></img>
          )}
        </h1>
        {this.props.isLoading && (
          <div className="unit-price-overview-loading">
            {LanguageProvider.t(TranslationMapper.global.messages.loading_unit_price)}
          </div>
        )}
        <Container className="unit-price-overview-container" fluid={true}>
          {this.props.unitPrices?.length > 0 && (
            <table className="unit-price-overview-table">
              <thead>
                <tr>
                  <th className="unit-price-align-right">
                    {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_code)}
                  </th>
                  <th className="unit-price-align-right">
                    {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_cost)}
                  </th>
                  <th className="unit-price-align-right">
                    {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_start_date)}
                  </th>
                  {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceReadWrite) && <th>&nbsp;</th>}
                  {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceReadWrite) && <th>&nbsp;</th>}
                </tr>
              </thead>
              <tbody>
                {this.props.unitPrices.map((unitPrice, index) => {
                  const showDeleteConfirmationPrompt = (): void => this.showDeleteConfirmationPrompt(unitPrice);
                  const toggleShowUpdateUnitPricePopup = (): void => this.toggleShowUpdateUnitPricePopup(unitPrice);

                  return (
                    <tr key={index}>
                      <td className="unit-price-align-right">{unitPrice.priceCode}</td>
                      <td className="unit-price-align-right">{unitPrice.unitPrice}</td>
                      <td className="unit-price-align-right">{unitPrice.startDate}</td>

                      {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceReadWrite) && (
                        <td className="unit-price-align-center">
                          <img
                            src={EditIcon}
                            className="clickable update-button"
                            alt="update"
                            onClick={toggleShowUpdateUnitPricePopup}
                          ></img>
                        </td>
                      )}
                      {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceReadWrite) && (
                        <td className="unit-price-align-center">
                          <img
                            src={DeleteIcon}
                            className="clickable delete-button"
                            alt="delete"
                            onClick={showDeleteConfirmationPrompt}
                          ></img>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Container>

        <ConfirmationPrompt
          onAgree={this.onDeleteUnitPrice}
          onCancel={this.toggleDeleteConfirmationPrompt}
          showPrompt={this.state.showDeleteConfirmationPrompt}
          headerText={this.confirmationDeleteUnitPriceMessage}
          id="delete-unit-price-confirmation"
        />

        <ConfirmationPrompt
          onAgree={this.onSubmitUnitPrice}
          onCancel={this.toggleAddUnitPriceConfirmationPrompt}
          showPrompt={this.state.showAddUnitPriceConfirmationPrompt}
          headerText={this.addUnitPriceMessage}
          id="add-unit-price-confirmation"
        />

        <BasicPopup
          showPopup={this.state.showAddUnitPricePopup}
          onClose={this.toggleShowAddUnitPricePopup}
          headerText={LanguageProvider.t(TranslationMapper.pages.unit_prices.add_or_update_unit_price_header)}
        >
          <Row className="unit-price-new-unit-price-row">
            <Col lg={8}>
              <p className="unit-price-new-unit-price-label">
                {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_code)}
              </p>
            </Col>
            <Col lg={4}>
              <input
                className="unit-price-new-unit-price-input"
                value={this.state.newUnitPriceCode ?? ""}
                onChange={this.newUnitPriceCodeChanged}
              />
            </Col>
          </Row>

          <Row className="unit-price-new-unit-price-row">
            <Col lg={8}>
              <p className="unit-price-new-unit-price-label">
                {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_cost)}
              </p>
            </Col>
            <Col lg={4}>
              <input
                type="number"
                className="unit-price-new-unit-price-input"
                value={this.state.newUnitPriceCost ?? ""}
                onChange={this.newUnitPriceCostChanged}
              />
            </Col>
          </Row>

          <Row className="unit-price-new-unit-price-row">
            <Col lg={8}>
              <p className="unit-price-new-unit-price-label">
                {LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_start_date)}
              </p>
            </Col>
            <Col lg={4}>
              <Form.Control
                className="unit-price-new-unit-price-input"
                type="text"
                value={this.state.newUnitPriceStartDate ?? ""}
                onChange={this.newUnitPriceStartDateChanged}
                onFocus={(e): string => (e.target.type = "date")}
                onBlur={(e): string => (e.target.type = "text")}
                placeholder={LanguageProvider.t(TranslationMapper.pages.unit_prices.unit_price_start_date)}
              ></Form.Control>
            </Col>
          </Row>

          <div className="btn unit-price-confirmation-prompt-buttons">
            <Button
              className="unit-price-confirmation-cancel-button"
              onClick={this.toggleShowAddUnitPricePopup}
              label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
            />

            <Button
              className="unit-price-confirmation-agree-button"
              onClick={this.validateNewUnitPriceEntry}
              style={ButtonStyle.blue}
              label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
            />
          </div>
        </BasicPopup>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IUnitPriceStateProps => ({
  unitPrices: state.unitPriceState.unitPrices ?? [],
  isLoading: state.unitPriceState.isLoading,
});

const mapDispatchToProps: IUnitPriceDispatchProps = {
  onFetchUnitPrices: getAllUnitPrices,
  onDeleteUnitPrice: deleteUnitPrice,
  onUpdateUnitPrice: upsertUnitPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPrice);
