import { IUserStoreState, initialUserStoreState } from "../../store/state/userStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function userReducer(state: IUserStoreState = initialUserStoreState, action: ActionTypesCollection): IUserStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

export default userReducer;
