import { Component } from "react";
import IPageProps from "./interfaces/IPageProps";

// Used to enforce same basic margins and layout for all pages
export default class Page extends Component<IPageProps> {
    public render(): JSX.Element {
        const className = this.props.className ? `g-zz-page ${this.props.className}` : "g-zz-page";

        return (
            <div className={className} data-test={this.props["data-test"]}>
                {this.props.children}
            </div>
        );
    }
}