import IUser from "../../interfaces/IUser";

export interface IUserStoreState {
  user?: IUser;
  isLoading: boolean;
}

export const initialUserStoreState: IUserStoreState = {
  isLoading: false,
};
