import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import IEmailAddress from "interfaces/IEmailAddress";
import IPostalCodeActivatedEmail from "interfaces/IPostalCodeActivatedEmail";
import IPostalCodeRequest from "interfaces/IPostalCodeRequest";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Column, Row as ReactTableRow } from "react-table";
import {
  deletePostalCodeRequest,
  fetchPostalCodeRequests,
  patchPostalCodeRequest,
  patchPostalCodeRequests,
  sendEmail,
} from "store/actions/postalCodeRequestActions";
import { RootState } from "store/reducers/rootReducer";
import DateUtils from "utils/dateUtils";
import { IPatchPostalCodeRequest } from "./interfaces/IPatchPostalCodeRequest";
import IPostalCodeRequestProps, {
  IPostalCodeRequestDispatchProps,
  IPostalCodeRequestStateProps,
} from "./interfaces/IPostalCodeRequestProps";
import { IPostalCodeRequestState } from "./interfaces/IPostalCodeRequestState";

class PostalCodeRequest extends Component<IPostalCodeRequestProps, IPostalCodeRequestState> {
  private readonly tableDataExampleObject: IPostalCodeRequest = {
    postalCodeRequestId: "",
    postalCode: "",
    city: "",
    name: "",
    status: "",
    emailAddress: "",
    registrationState: "",
    costEmployee: 0,
    costEmployer: 0,
    houseNumber: 0,
    requestDateTime: new Date(),
    totalCosts: 0,
    requestedTime: 0,
    requestedIroningTime: 0,
    totalTime: 0,
  };
  private readonly filterResetValue: string = "undefined";
  private readonly statusses: string[] = ["Aangevraagd", "Uitgenodigd", "Klant", "Afgehaakt"];
  private selectedRows: IPostalCodeRequest[] = [];

  public constructor(props: IPostalCodeRequestProps) {
    super(props);

    const state: IPostalCodeRequestState = {
      isLoading: true,
      showDeleteModal: false,
      showSendEmailModal: false,

      patchPostalCodeRequestsLoading: false,
    };

    this.state = state;

    this.updateStatus = this.updateStatus.bind(this);
    this.onDataChangeSuccess = this.onDataChangeSuccess.bind(this);
    this.onZipStartChange = this.onZipStartChange.bind(this);
    this.onZipEndChange = this.onZipEndChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onChangeDateStart = this.onChangeDateStart.bind(this);
    this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
    this.setSelectedRows = this.setSelectedRows.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.bulkSetStatusInvited = this.bulkSetStatusInvited.bind(this);
  }

  public componentDidMount(): void {
    this.props.onFetchPostalCodeRequests("");
  }

  private onDelete(requestId: string | undefined): void {
    if (requestId !== undefined) {
      this.props.onDeletePostalCodeRequest(requestId, this.onDataChangeSuccess);
    }

    this.showDeleteModal(false, undefined);
  }

  private sendEmail(): void {
    if (this.selectedRows.length > 0) {
      const postalCodeActivatedEmail: IPostalCodeActivatedEmail = { toAddresses: [] };
      const patchPostalCodeRequests: IPatchPostalCodeRequest[] = [];

      for (let index = 0; index < this.selectedRows.length; index++) {
        const emailAddress: IEmailAddress = {
          name: this.selectedRows[index].name,
          email: this.selectedRows[index].emailAddress,
        };

        const postalCodeRequest: IPatchPostalCodeRequest = {
          postalCodeRequestId: this.selectedRows[index].postalCodeRequestId,
        };

        postalCodeActivatedEmail.toAddresses.push(emailAddress);
        patchPostalCodeRequests.push(postalCodeRequest);
      }

      this.props.onSendEmail(postalCodeActivatedEmail, () => this.bulkSetStatusInvited(patchPostalCodeRequests));
    }
  }

  private bulkSetStatusInvited(requests: IPatchPostalCodeRequest[]): void {
    this.props.onPatchPostalCodeRequests(requests, "Uitgenodigd", this.onDataChangeSuccess, () =>
      this.showSendEmailModal(false)
    );
  }

  private updateStatus(id: string, status: string): void {
    this.props.onPatchPostalCodeRequest(id, status, this.onDataChangeSuccess);
  }

  private onDataChangeSuccess(): void {
    this.onFetchPostalCodeRequestsWithFilter();
  }

  private setSelectedRows(data: object[]): void {
    const selectedItems: IPostalCodeRequest[] = data as IPostalCodeRequest[];
    this.selectedRows = selectedItems;
  }

  private renderRowSubComponent(rowData: ReactTableRow): JSX.Element {
    const postalCodeRequestDetails = rowData.original as IPostalCodeRequest;
    return (
      <div className="postalcode-request-table-row-sub">
        <Container fluid>
          <Row>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_email)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.emailAddress}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.pages.offer_request_management.label_phone)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.phoneNumber}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.column_zizocode)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.zizoCode}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_invitation_send_date)}</b>
              </Col>
              {postalCodeRequestDetails.invitedAt !== undefined && postalCodeRequestDetails.invitedAt !== null && (
                <Col md>
                  {DateUtils.getFriendlyDateTimeStringWithYear(
                    DateUtils.utcToLocal(postalCodeRequestDetails.invitedAt)
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  private get getColumns(): Column[] {
    const onUpdateStatus = (id: string, e: ChangeEvent<HTMLInputElement>): void =>
      this.updateStatus(id, e.target.value.trim());
    const options = this.statusses.map((status) => (
      <option value={status} key={status}>
        {status}
      </option>
    ));

    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_zipcode),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.postalCode),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_city),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.city),
      },

      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_name),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.name),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_request_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.requestDateTime),
        Cell: ({ value }): string => DateUtils.getFriendlyDateTimeStringWithYear(DateUtils.utcToLocal(value)),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_status),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.status),
        Cell: ({ value, row }): JSX.Element => {
          return (
            <>
              <Form.Control
                as="select"
                defaultValue={value}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  onUpdateStatus(row.values.postalCodeRequestId.toString(), e)
                }
              >
                {options}
              </Form.Control>
            </>
          );
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_hours_per_period),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.totalTime),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_total_costs),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.totalCosts),
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.postalCodeRequestId
        ),
        Cell: ({ row }): JSX.Element => {
          return (
            <div className="g-react-table__actions">
              <div>
                <img
                  src={DeleteIcon}
                  alt="delete postalcode request"
                  onClick={(): void => this.showDeleteModal(true, row.values.postalCodeRequestId)}
                  className="g-react-table__action"
                />
              </div>
              <div>
                <span {...row.getToggleRowExpandedProps()}>
                  <div
                    className={
                      row.isExpanded ? "g-react-table__row-expander-open" : "g-react-table__row-expander-closed"
                    }
                  />
                </span>
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
    ];
    return columns;
  }

  private showDeleteModal(doShow: boolean, requestId: string | undefined): void {
    this.setState({
      postalCodeRequestToDelete: requestId,
      showDeleteModal: doShow,
    });
  }

  private showSendEmailModal(showModal: boolean): void {
    this.setState({
      showSendEmailModal: showModal,
    });
  }

  private getAndTrimSelection(e: ChangeEvent<HTMLInputElement>): string | undefined {
    const value = e.target.value.trim();
    const selection = value !== this.filterResetValue && value !== "" ? value : undefined;
    return selection;
  }

  private onZipStartChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedZipCodeRangeStart: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onZipEndChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedZipCodeRangeEnd: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onStatusChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedStatus: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onChangeDateStart(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedDateStart: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onChangeDateEnd(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedDateEnd: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onFetchPostalCodeRequestsWithFilter(): void {
    let filter = "";
    if (this.state.selectedStatus !== undefined) {
      filter = filter.concat(`?&filter=Status eq '${this.state.selectedStatus}'`);
    }

    if (this.state.selectedZipCodeRangeStart !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and PostalCode ge '${this.state.selectedZipCodeRangeStart}'`);
      } else {
        filter = filter.concat(`?&filter=PostalCode ge '${this.state.selectedZipCodeRangeStart}'`);
      }
    }

    if (this.state.selectedZipCodeRangeEnd !== undefined) {
      if (filter !== "") {
        filter = filter.concat(
          ` and PostalCode le '${this.state.selectedZipCodeRangeEnd}${
            this.state.selectedZipCodeRangeEnd.length === 4 ? "ZZ" : ""
          }'`
        );
      } else {
        filter = filter.concat(
          `?&filter=PostalCode le '${this.state.selectedZipCodeRangeEnd}${
            this.state.selectedZipCodeRangeEnd.length === 4 ? "ZZ" : ""
          }'`
        );
      }
    }

    if (this.state.selectedDateStart !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and RequestDateTime ge ${this.getOdataDateFormat(this.state.selectedDateStart)}`);
      } else {
        filter = filter.concat(`?&filter=RequestDateTime ge ${this.getOdataDateFormat(this.state.selectedDateStart)}`);
      }
    }

    if (this.state.selectedDateEnd !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and RequestDateTime le ${this.getOdataDateFormat(this.state.selectedDateEnd)}`);
      } else {
        filter = filter.concat(`?&filter=RequestDateTime le ${this.getOdataDateFormat(this.state.selectedDateEnd)}`);
      }
    }
    this.props.onFetchPostalCodeRequests(filter);
  }

  private getOdataDateFormat(date: string): string {
    return date + "T00:00.00Z";
  }

  public render(): JSX.Element {
    const options = this.statusses.map((status) => (
      <option value={status} key={status}>
        {status}
      </option>
    ));
    return (
      <Page className="postalcode-request-page">
        <>
          <div className="react-table__top">
            <div className="filters">
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_status)}
                </div>
                <Form.Control as="select" value={this.state.selectedStatus ?? ""} onChange={this.onStatusChange}>
                  <option value={this.filterResetValue}>
                    {LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_filter_placeholder)}
                  </option>
                  {options}
                </Form.Control>
              </div>
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_postalcode)}
                </div>
                <Form.Control
                  type="text"
                  value={this.state.selectedZipCodeRangeStart ?? ""}
                  onChange={this.onZipStartChange}
                  maxLength={4}
                  placeholder={LanguageProvider.t(
                    TranslationMapper.pages.offer_request_management.postalcode_from_filter_placeholder
                  )}
                ></Form.Control>
                <Form.Control
                  type="text"
                  value={this.state.selectedZipCodeRangeEnd ?? ""}
                  onChange={this.onZipEndChange}
                  maxLength={4}
                  placeholder={LanguageProvider.t(
                    TranslationMapper.pages.offer_request_management.postalcode_till_filter_placeholder
                  )}
                ></Form.Control>
              </div>
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_request_date)}
                </div>
                <Form.Control
                  type="text"
                  value={this.state.selectedDateStart ?? ""}
                  onChange={this.onChangeDateStart}
                  onFocus={(e): string => (e.target.type = "date")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(
                    TranslationMapper.pages.offer_request_management.request_date_filter_from_placeholder
                  )}
                ></Form.Control>
                <Form.Control
                  type="text"
                  value={this.state.selectedDateEnd ?? ""}
                  onChange={this.onChangeDateEnd}
                  onFocus={(e): string => (e.target.type = "date")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(
                    TranslationMapper.pages.offer_request_management.request_date_filter_till_placeholder
                  )}
                ></Form.Control>
              </div>
              {this.props.isLoading && (
                <div className="spinner-border mb-2" role="status">
                  <span className="sr-only"></span>
                </div>
              )}
            </div>
            <div>
              <Button
                style={ButtonStyle.blue}
                label={LanguageProvider.t(TranslationMapper.global.buttons.send)}
                onClick={(): void => this.showSendEmailModal(true)}
              />
            </div>
          </div>
          <ReactTable
            isSelectable={true}
            setSelection={this.setSelectedRows}
            columns={this.getColumns}
            data={this.props.postalCodeRequests ?? []}
            renderRowSubComponent={this.renderRowSubComponent}
            className="postalcode-request-table"
            usePaginationForTable={true}
            defaultColumnToSortBy={{
              id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.requestDateTime),
              desc: true,
            }}
          />

          <ConfirmationPrompt
            showPrompt={this.state.showDeleteModal}
            onCancel={(): void => this.showDeleteModal(false, undefined)}
            onAgree={(): void => this.onDelete(this.state.postalCodeRequestToDelete)}
          />

          <BasicPopup
            showPopup={this.state.showSendEmailModal}
            onClose={(): void => this.showSendEmailModal(false)}
            headerText={
              this.selectedRows.length > 0
                ? LanguageProvider.t(TranslationMapper.global.messages.send_postalcode_email_confirmation).replace(
                    "{0}",
                    this.selectedRows.length.toString()
                  )
                : LanguageProvider.t(TranslationMapper.global.errors.no_waiter_selected)
            }
          >
            <Container className="bt-1">
              <Row className="justify-content-around mt-4">
                {this.selectedRows.length > 0 && !this.props.sendEmailIsLoading && (
                  <Button
                    style={ButtonStyle.pinkGradient}
                    label={LanguageProvider.t(TranslationMapper.global.buttons.send)}
                    onClick={(): void => this.sendEmail()}
                  />
                )}
                {this.selectedRows.length === 0 && !this.props.patchPostalCodeRequestsLoading && (
                  <Button
                    style={ButtonStyle.red}
                    label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
                    onClick={(): void => this.showSendEmailModal(false)}
                  />
                )}
                {(this.props.sendEmailIsLoading || this.props.patchPostalCodeRequestsLoading) && (
                  <>
                    <Container>
                      <Row className="justify-content-center">
                        <div className="spinner-border mb-2" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </Row>
                      <Row className="justify-content-center">
                        <span>{this.props.sendEmailIsLoading ? "Versturen e-mails..." : "Statussen aanpassen..."}</span>
                      </Row>
                    </Container>
                  </>
                )}
              </Row>
            </Container>
          </BasicPopup>
        </>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IPostalCodeRequestStateProps => ({
  postalCodeRequests: state.postalCodeRequestState.fetchedPostalCodeRequests,
  isLoading: state.postalCodeRequestState.isLoading,
  sendEmailIsLoading: state.postalCodeRequestState.sendEmailIsLoading,
  patchPostalCodeRequestsLoading: state.postalCodeRequestState.patchPostalCodeRequestsLoading,
});

const mapDispatchToProps: IPostalCodeRequestDispatchProps = {
  onFetchPostalCodeRequests: fetchPostalCodeRequests,
  onPatchPostalCodeRequest: patchPostalCodeRequest,
  onDeletePostalCodeRequest: deletePostalCodeRequest,
  onSendEmail: sendEmail,
  onPatchPostalCodeRequests: patchPostalCodeRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostalCodeRequest);
