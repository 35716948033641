import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IUnitPriceStoreState, initialUnitPriceStoreState } from "store/state/unitPriceStoreState";

function unitPriceReducer(
  state: IUnitPriceStoreState = initialUnitPriceStoreState,
  action: ActionTypesCollection
): IUnitPriceStoreState {
  switch (action.type) {
    case ActionTypes.FETCH_UNIT_PRICE: {
      return { ...state, unitPrices: action.payload };
    }
    case ActionTypes.UNIT_PRICE_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default unitPriceReducer;
