import produce from "immer";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { IChatStoreState, initialChatStoreState } from "../state/chatStoreState";

function chatReducer(state: IChatStoreState = initialChatStoreState, action: ActionTypesCollection): IChatStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_CHAT:
      return { ...state, chatConversation: [action.payload] };
    case ActionTypes.UPDATE_CHAT_CONVERSATION:
      return { ...state, chatConversation: [...action.payload] };
    case ActionTypes.UPDATE_TOPIC_ID:
      return { ...state, currentTopicId: action.payload };
    case ActionTypes.UPDATE_CHAT_INFO:
      return { ...state, chatInfo: action.payload ?? [] };
    case ActionTypes.UPDATE_LOADING_CHAT:
      return { ...state, isLoading: action.payload };
    case ActionTypes.UPDATE_LOADING_CHATS:
      return { ...state, isChatsLoading: action.payload };
    case ActionTypes.FETCHED_CHAT_DELETION_DETAILS:
      return produce(state, (draftState) => {
        draftState.chatsToDeleteDetails = {
          ...action.payload,
          endUserName: action.payload?.endUserName ?? "",
          endUserObjectId: action.payload?.endUserObjectId ?? "",
          numberOfChatsToBeDeleted: action.payload?.numberOfChatsToBeDeleted ?? 0,
        };
      });
    case ActionTypes.UPDATE_LOADING_CHATS_DELETION:
      return { ...state, isChatsDeletionLoading: action.payload };
    case ActionTypes.UPDATE_CHAT_MESSAGE: {
        const updatedChatConversation = [...state.chatConversation];
        const chatMessageIndex = state.chatConversation.findIndex((c) => c.chatId === action.payload.chatId);

        //Find updated chat message and replace it
        if (chatMessageIndex >= 0) {
            updatedChatConversation[chatMessageIndex] = action.payload;
        }

        return produce(state, (drafState) => {
            drafState.chatConversation = updatedChatConversation;
        });
    }
    default:
      return state;
  }
}

export default chatReducer;
