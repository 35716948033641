import { IOfferRequestStoreState, initialOfferRequestStoreState } from "store/state/offerRequestStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function offerRequestReducer(
  state: IOfferRequestStoreState = initialOfferRequestStoreState,
  action: ActionTypesCollection
): IOfferRequestStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_OFFER_REQUESTS:
      return { ...state, isLoading: action.payload };
    case ActionTypes.FETCHED_OFFER_REQUESTS:
      return { ...state, offerRequests: [...action.payload] };
    case ActionTypes.FETCHED_SERVICES:
      return { ...state, availableServices: [...action.payload] };
    default:
      return state;
  }
}

export default offerRequestReducer;
