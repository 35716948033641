/* eslint-disable @typescript-eslint/explicit-function-return-type */
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import ManagementService from "services/managementService";
import TelemetryService from "services/telemetryService";
import { actionCreator, AppAction } from "../appAction";

import IUnitPrice from "interfaces/IUnitPrice";
import { ActionTypes } from "../actionTypes";

export const setUnitPriceLoading = actionCreator<ActionTypes.UNIT_PRICE_LOADING, boolean>(
  ActionTypes.UNIT_PRICE_LOADING
);
export const fetchedUnitPrices = actionCreator<ActionTypes.FETCH_UNIT_PRICE, IUnitPrice[]>(
  ActionTypes.FETCH_UNIT_PRICE
);

export function getAllUnitPrices(): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleUnitPriceLoadingStatus(true));

    managementService
      .getAllUnitPricesAsync()
      .then((unitPrices) => dispatch(fetchedUnitPrices(unitPrices)))
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "unitPriceActions.getAllUnitPrices",
            message: `Error retrieving unit prices ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_fetching_unit_prices));
      })
      .finally(() => dispatch(toggleUnitPriceLoadingStatus(false)));
  };
}

export function deleteUnitPrice(unitPriceId: string): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleUnitPriceLoadingStatus(true));

    managementService
      .deleteUnitPriceAsync(unitPriceId)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.global.success.success_deleting_unit_price)
        );
        dispatch(getAllUnitPrices());
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "unitPriceActions.deleteUnitPrice",
            message: `Error deleting unit price ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_deleting_unit_price));
      })
      .finally(() => dispatch(toggleUnitPriceLoadingStatus(false)));
  };
}

export function upsertUnitPrice(request: IUnitPrice, onSuccess: () => void): AppAction {
  return (dispatch) => {
    const managementService = new ManagementService();

    dispatch(toggleUnitPriceLoadingStatus(true));

    managementService
      .upsertUnitPriceAsync(request)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.global.success.success_upserting_unit_price)
        );
        dispatch(getAllUnitPrices());
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
           exception: {
            name: "unitPriceActions.upsertUnitPrice",
            message: `Error upserting unit prices ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_upserting_unit_price));
      })
      .finally(() => dispatch(toggleUnitPriceLoadingStatus(false)));
  };
}

export function toggleUnitPriceLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch) => {
    dispatch(setUnitPriceLoading(isLoading));
  };
}
