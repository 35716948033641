import IServiceCategory from "interfaces/IServiceCategory";
import ISupplementedServiceOfferRequest from "interfaces/ISupplementedServiceOfferRequest";

export interface IOfferRequestStoreState {
  isLoading: boolean;
  offerRequests?: ISupplementedServiceOfferRequest[];
  availableServices?: IServiceCategory[];
}

export const initialOfferRequestStoreState: IOfferRequestStoreState = {
  isLoading: false,
};
