import { IErrorStoreState, initialErrorState } from "store/state/errorStoreState";

import { ActionTypes, ActionTypesCollection } from "store/actionTypes";

function errorReducer(state: IErrorStoreState = initialErrorState, action: ActionTypesCollection): IErrorStoreState {
  switch (action.type) {
    case ActionTypes.INTERCEPTED_ERROR:
      return {
        ...state,
        error: action.payload ?? undefined,
        errorCode: action.payload ? "0xDA59" : "",
      };
    default:
      return state;
  }
}

export default errorReducer;
