/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UserAuthenticator } from "../../authenticators/userAuthenticator";
import IUser from "../../interfaces/IUser";
import { actionCreator, AppAction } from "../../store/appAction";

import { ActionTypes } from "../actionTypes";

export const updatedUser = actionCreator<ActionTypes.UPDATE_USER, IUser>(ActionTypes.UPDATE_USER);

export function getUser(): AppAction {
    return (dispatch) => {
        const user = UserAuthenticator.userInfo;

        if (user) {
            dispatch(updatedUser(user));
        }
    };
}
