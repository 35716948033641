export enum SystemNotificationPriority {
    low = "low",
    medium = "medium",
    high = "high"
}

export default interface ISystemNotificationBarProps {
    isHidden: boolean;
    messagePriority: SystemNotificationPriority
    className?: string;
    message?: string;
    onClick?: () => void;
}