import ILanguage from "interfaces/ILanguage";
import TranslateEN from "../i18n/locales/en/translation.json";
import TranslateNL from "../i18n/locales/nl/translation.json";

export const languages: ILanguage[]  =  [
    {
        key: "en",
        label: "EN",
        resources: {
            translation: TranslateEN,
        },
    },
    {
        key: "nl",
        label: "NL",
        defaultLang: true,
        resources: {
            translation: TranslateNL,
        },
    }
]