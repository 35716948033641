import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import activationReducer from "./activationReducer";
import authorizationReducer from "./authReducer";
import chatReducer from "./chatReducer";
import errorReducer from "./errorReducer";
import notificationReducer from "./notificationReducer";
import offerRequestReducer from "./offerRequestReducer";
import postalCodeRequestReducer from "./postalCodeRequestReducer";
import projectWorkProgramReducer from "./projectWorkProgramReducer";
import regionReducer from "./regionReducer";
import unitPriceReducer from "./unitPriceReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  errorState: errorReducer,
  chatState: chatReducer,
  activationState: activationReducer,
  userState: userReducer,
  notificationState: notificationReducer,
  regionState: regionReducer,
  offerRequestState: offerRequestReducer,
  accountState: accountReducer,
  postalCodeRequestState: postalCodeRequestReducer,
  projectWorkProgramState: projectWorkProgramReducer,
  unitPriceState: unitPriceReducer,
  authState: authorizationReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
