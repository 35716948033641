import * as msal from "@azure/msal-browser";
import moment from "moment";
import AuthProvider from "providers/authProvider";
import MsalFactory from "../factories/msalFactory";

export class MsalAuthenticator {
  protected async signInAsync(): Promise<msal.AccountInfo | null> {
    const account = await this.checkCacheForExistingAccountAsync();
    if (account == null) {
      MsalFactory.getMsalContext().loginRedirect();
      return null;
    } else {
      return account;
    }
  }

  protected async acquireTokenAsync(scopes: string[]): Promise<string | null> {
    const account = this.getLoggedInAccount();

    if (account == null) {
      return null;
    }

    try {
      const silentRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };
      const result = await MsalFactory.getMsalContext().acquireTokenSilent(silentRequest);
      AuthProvider.token.b2c.time.exp = result.account?.idTokenClaims?.exp ? result.account?.idTokenClaims?.exp : null;
      AuthProvider.token.b2c.time.iat = result.account?.idTokenClaims?.iat ? result.account?.idTokenClaims?.iat : null;
      AuthProvider.token.b2c.time.user = moment().unix();
      if (AuthProvider.token.b2c.time.exp && AuthProvider.token.b2c.time.iat) {
        AuthProvider.token.b2c.time.diff = AuthProvider.token.b2c.time.exp
          ? AuthProvider.token.b2c.time.exp - AuthProvider.token.b2c.time.iat
          : null;
      }
      AuthProvider.token.b2c.token = result.accessToken;

      // Reset Auth
      AuthProvider.token.auth = { token: "", time: { exp: null, iat: null, diff: null, user: null } };

      if (!result.accessToken) {
        throw Error();
      }

      return result.accessToken;
    } catch {
      const redirectRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };

      MsalFactory.getMsalContext().acquireTokenRedirect(redirectRequest);
      return null;
    }
  }

  private async checkCacheForExistingAccountAsync(): Promise<msal.AccountInfo | null> {
    try {
      const response = await MsalFactory.getMsalContext().handleRedirectPromise();
      if (response !== null) {
        return response.account;
      } else {
        return this.getLoggedInAccount();
      }
    } catch (err) {
      // More about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      console.log("Unexpected error occurred");
      console.log(err);

      return null;
    }
  }

  private getLoggedInAccount(): msal.AccountInfo | null {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const allAccounts = MsalFactory.getMsalContext().getAllAccounts();
    if (allAccounts.length === 1) {
      return allAccounts[0];
    } else if (allAccounts.length > 1) {
      MsalFactory.logoutAllAccounts(null);
    }

    return null;
  }

  protected logoutAllAccounts(): void {
    MsalFactory.logoutAllAccounts(null);
  }
}
