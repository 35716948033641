import IPostalCodeRequest from "interfaces/IPostalCodeRequest";

export interface IPostalCodeRequestStoreState {
  isLoading: boolean;
  sendEmailIsLoading: boolean;
  patchPostalCodeRequestsLoading: boolean;
  fetchedPostalCodeRequests?: IPostalCodeRequest[];
}

export const initialPostalCodeRequestStoreState: IPostalCodeRequestStoreState = {
  isLoading: false,
  sendEmailIsLoading: false,
  patchPostalCodeRequestsLoading: false,
};
