import { ChatType } from "enums/chatType";
import ChatMessage from "models/chatMessage";
import IChatDeletionCheckDetails from "pages/avgGdprManagement/interfaces/IChatDeletionCheckDetails";

import ChatClient from "../clients/chatClient";
import ChatInfo from "../models/chatInfo";

export default class ChatService {
  private readonly chatClient: ChatClient = new ChatClient();

  public async getChatMessagesForTopicAsync(topicId: string, chatType: ChatType): Promise<ChatMessage[]> {
    const messages = await this.chatClient.getChatMessagesForTopicAsync(topicId, chatType);
    return messages.map((message) => new ChatMessage(message));
  }

  public async getEndUsersWithChatInfoAsync(): Promise<ChatInfo[]> {
    const chatInfos = await this.chatClient.getEndUsersWithChatInfoAsync();
    return chatInfos.map((chatInfo) => new ChatInfo(chatInfo));
  }

  public async getExecutorsWithChatInfoAsync(): Promise<ChatInfo[]> {
    const chatInfos = await this.chatClient.getExecutorsWithChatInfoAsync();
    return chatInfos.map((chatInfo) => new ChatInfo(chatInfo));
  }

  public async getChatAttachmentAsync(chatId: string): Promise<string> {
    return this.chatClient.getChatAttachmentAsync(chatId);
  }

  // After full implementation of Authentication API for all roles this will be redundant and can be removed
  public async getChatAttachmentServiceDeskAsync(chatId: string): Promise<string> {
    return this.chatClient.getChatAttachmentServiceDeskAsync(chatId);
  }

  public async getChatDeletionCheckDetailsAsync(endUserObjectId: string): Promise<IChatDeletionCheckDetails> {
    return this.chatClient.getChatDeletionCheckDetailsAsync(endUserObjectId);
  }

  public async deleteAllChatConversationsForUserAsync(endUserObjectId: string): Promise<Response> {
    return this.chatClient.deleteAllChatConversationsForUserAsync(endUserObjectId);
  }
  public async sendChatMessageAsync(
    topicId: string,
    contents: string,
    chatType: ChatType,
    attachment?: string
  ): Promise<void> {
    await this.chatClient.sendChatMessageAsync(topicId, contents, chatType, attachment);
  }
}
