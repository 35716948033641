import * as React from "react";
import { withRouter } from "react-router-dom";

import { withTranslation } from "react-i18next";
import TranslationMapper from "../../../i18n/mapper";
import LanguageProvider from "../../../providers/languageProvider";
import RoutingProvider, { RoutingPath } from "../../../providers/routingProvider";
import ErrorHeader from "../errorHeader/errorHeader";
import IErrorDisplayProps from "./interfaces/IErrorDisplayProps";

class ErrorDisplay extends React.Component<IErrorDisplayProps, {}> {
  public constructor(props: IErrorDisplayProps) {
    super(props);

    this.renderBrowserSpecificError = this.renderBrowserSpecificError.bind(this);
    this.retryLoadingPage = this.retryLoadingPage.bind(this);
  }

  private renderBrowserSpecificMessage(): { __html: string } {
    return { __html: LanguageProvider.t(TranslationMapper.global.errors.browser_specific_error) };
  }

  private renderBrowserSpecificError(): JSX.Element {
    if (!this.props.validateBrowser) {
      return <></>;
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
      return <></>;
    }

    return (
      <div>
        <div dangerouslySetInnerHTML={this.renderBrowserSpecificMessage()}></div>
      </div>
    );
  }

  private retryLoadingPage(): void {
    RoutingProvider.invokePushNewRouteWithRefresh(this.props.history, RoutingPath.home);
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.showErrorHeader && <ErrorHeader />}
        <div className="screen-background">
          <div className="g-error-page-page d-flex">
            <div className="outer-box">
              <div className="inner-box">
                <h2 className="pt-2">{LanguageProvider.t(TranslationMapper.global.errors.uncaught_exception_title)}</h2>
                <p>{LanguageProvider.t(this.props.errorMessage)}</p>
                {this.renderBrowserSpecificError()}
                <small>
                  <p>
                    Error code <span id="errorcode">{this.props.errorCode}</span>&nbsp;
                  </p>
                  <p>
                    <button className="clickable" data-test="refresh-page" onClick={this.retryLoadingPage}>
                      {LanguageProvider.t(TranslationMapper.global.errors.reload_home)}
                    </button>
                  </p>
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(ErrorDisplay));
