import React, { ErrorInfo } from "react";

import TranslationMapper from "../../../i18n/mapper";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import IErrorBoundaryProps from "./interfaces/IErrorBoundaryProps";
import IErrorBoundaryState from "./interfaces/IErrorBoundaryState";

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(error: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorDisplay
          errorCode="0x4FD9"
          errorMessage={TranslationMapper.global.errors.uncaught_exception_title}
          validateBrowser={false}
          showErrorHeader={true}
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
