import { AuthApiAuthenticator } from "authenticators/authApiAuthenticator";
import { PlatformType } from "enums/platformType";
import IAuthUser, { IAuthRole, IAuthUpsertUser } from "interfaces/IAuth";

import { ApplicationConfig } from "../config";
import BaseClient from "./baseClient";

export default class AuthClient extends BaseClient {
  private readonly deleteUser: string = "User/DeleteUser";
  private readonly listAllUsers: string = "User/ListAllUsers";
  private readonly listAllRoles: string = "Role/ListAllRoles";
  private readonly updateUserRoles: string = "User/UpdateUserRoles";
  private readonly authorizeAuthorizeUserZizo: string = "Authorize/AuthorizeUserZizo";

  public constructor() {
    super(ApplicationConfig.endpoints.authApi, new AuthApiAuthenticator());
  }

  public async deleteUserAsync(id: string): Promise<Response> {
    return this.delete(`${this.deleteUser}?id=${id}&platformType=${PlatformType.Zizo}`);
  }

  public async getAuthTokenAsync(): Promise<string> {
    return this.getAuthToken(`${this.authorizeAuthorizeUserZizo}`);
  }

  public async getListAllRoles(): Promise<IAuthRole[]> {
    return this.get(`${this.listAllRoles}?platformType=${PlatformType.Zizo}`);
  }

  public async getListAllUsers(): Promise<IAuthUser[]> {
    return this.get(`${this.listAllUsers}?platformType=${PlatformType.Zizo}`);
  }

  public async putUpdateUserRoles(data: IAuthUpsertUser): Promise<Response> {
    return this.put(`${this.updateUserRoles}`, data);
  }
}
