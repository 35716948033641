import { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import CloseIcon from "../../images/close-blue-gradient.svg";
import IBasicPopupProps from "./interfaces/IBasicPopupProps";

export default class BasicPopup extends Component<IBasicPopupProps> {
  public render(): JSX.Element {
    // Using the React portal with hook ensures modal is always rendered within proper DOM root
    const modalHook = document.getElementById("modal-hook");

    return (
      <>
        {modalHook &&
          ReactDOM.createPortal(
            <Modal
              show={this.props.showPopup}
              onHide={this.props.onClose}
              className={this.props.className ? `g-basic-popup ${this.props.className}` : "g-basic-popup"}
              data-test={this.props["data-test"]}
              id={this.props.id}
              backdrop="static" // avoid closing modal on backdrop click
              centered
            >
              <Modal.Header>
                <img src={CloseIcon} alt="close popup" onClick={this.props.onClose} className="modal-header__close" />
                <div className="modal-header__headers">
                  <h1>{this.props.headerText}</h1>
                  <h2>{this.props.subheaderText}</h2>
                </div>
              </Modal.Header>

              {(this.props.bodyText || this.props.children) && (
                <Modal.Body className="mb-3">{this.props.bodyText ?? this.props.children}</Modal.Body>
              )}

              {this.props.footerContent && <Modal.Footer>{this.props.footerContent}</Modal.Footer>}
            </Modal>,
            modalHook
          )}
      </>
    );
  }
}
