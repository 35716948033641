import { AuthApiAuthenticator } from "authenticators/authApiAuthenticator";
import IAccountRegistration from "interfaces/IAccountRegistrationRequest";
import IDebtorInformation from "interfaces/IDebtorInformation";
import IPostalCodeActivatedEmail from "interfaces/IPostalCodeActivatedEmail";
import IServiceCategory from "interfaces/IServiceCategory";
import ISupplementedServiceOfferRequest from "interfaces/ISupplementedServiceOfferRequest";
import { ApplicationConfig } from "../config";
import CreateActivationLinkRequest from "../models/createActivationLinkRequest";
import BaseClient from "./baseClient";

export default class AccountClient extends BaseClient {
  private readonly accountEndpoint: string = "account";
  private readonly servicesEndpoint: string = "services";
  private readonly accountRegistrationEndpoint: string = "accountRegistration";

  public constructor() {
    super(ApplicationConfig.endpoints.accountApi, new AuthApiAuthenticator());
  }

  public async sendActivationEmailAsync(createActivationLinkRequest: CreateActivationLinkRequest): Promise<Response> {
    return this.post(`${this.accountEndpoint}/register`, createActivationLinkRequest);
  }

  public async getAllAvailableServicesAsync(languageCode: string): Promise<IServiceCategory[]> {
    return this.get<IServiceCategory[]>(`${this.servicesEndpoint}/availableServices?languageCode=${languageCode}`);
  }

  public async getServiceOfferRequestsAsync(): Promise<ISupplementedServiceOfferRequest[]> {
    return this.get<ISupplementedServiceOfferRequest[]>(`${this.servicesEndpoint}/serviceOfferRequests`);
  }

  public async updateServiceOfferRequestAsync(serviceOfferRequestCode: string, price: number): Promise<Response> {
    return this.put(`${this.servicesEndpoint}/update/offerRequest/${serviceOfferRequestCode}/price=${price}`, false);
  }

  public async deleteServiceOfferRequestAsync(serviceOfferRequestCode: string): Promise<Response> {
    return this.delete(`${this.servicesEndpoint}/delete/offerRequest/${serviceOfferRequestCode}`);
  }

  public async getDebtorInformationAsync(externalDebtorId: string): Promise<IDebtorInformation> {
    return this.get<IDebtorInformation>(
      `${this.accountRegistrationEndpoint}/debtorInformation?externalDebtorId=${externalDebtorId}`
    );
  }

  public async upsertAccountRegistrationRequestAsync(request: IAccountRegistration): Promise<Response> {
    return this.post(`${this.accountRegistrationEndpoint}/upsertAccountRegistrationRequest`, request);
  }

  public async getAccountRegistrationRequestsAsync(): Promise<IAccountRegistration[]> {
    return this.get(`${this.accountRegistrationEndpoint}`);
  }

  public async sendAccountRegistrationRequestAsync(accountRegistrationRequestId: string): Promise<Response> {
    return this.post(
      `${this.accountRegistrationEndpoint}/sendAccountRegistrationRequest`,
      accountRegistrationRequestId
    );
  }

  public async deleteAccountRegistrationRequestAsync(requestId: string): Promise<Response> {
    return this.delete(`${this.accountRegistrationEndpoint}/delete?requestId=${requestId}`);
  }

  public async sendPostalcodeActivatedEmailAsync(emailAddressess: IPostalCodeActivatedEmail): Promise<Response> {
    return this.post(`${this.accountEndpoint}/sendPostalcodeActivatedEmail`, emailAddressess);
  }
}
