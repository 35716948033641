import TranslationMapper from "i18n/mapper";
import IWorkProgramAction from "interfaces/IWorkProgramAction";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { addAction, removeAction, updateAction } from "store/actions/projectWorkProgramActions";
import { RootState } from "store/reducers/rootReducer";
import { isNullOrEmpty } from "utils/stringUtils";
import CloseIcon from "../../../images/close-blue-gradient.svg";
import DeleteIcon from "../../../images/delete.svg";
import SaveIcon from "../../../images/floppy-disk-regular.svg";
import AddIcon from "../../../images/plus.svg";
import IWorkProgramActionListProps, {
  IWorkProgramActionListDispatchProps,
  IWorkProgramActionListStateProps,
} from "./interfaces/IWorkProgramActionListProps";
import IWorkProgramActionListState from "./interfaces/IWorkProgramActionListState";

class WorkProgramActionList extends Component<IWorkProgramActionListProps, IWorkProgramActionListState> {
  public constructor(props: IWorkProgramActionListProps) {
    super(props);

    const state: IWorkProgramActionListState = {
      showNewActionItem: false,
      actionFrequencyId: this.props.frequencies[0].actionFrequencyId,
    };

    this.state = state;

    this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
    this.onFrequencyChanged = this.onFrequencyChanged.bind(this);
    this.addAction = this.addAction.bind(this);
    this.onToggleNewAction = this.onToggleNewAction.bind(this);
    this.onRemoveAction = this.onRemoveAction.bind(this);
    this.handleKeyPressAddAction = this.handleKeyPressAddAction.bind(this);
  }

  private get renderFrequencies(): JSX.Element[] {
    return this.props.frequencies?.map((freq) => (
      <option value={freq.actionFrequencyId} key={freq.actionFrequencyId}>
        {freq.description}
      </option>
    ));
  }

  private get workProgramActions(): IWorkProgramAction[] {
    return this.props.roomTypeWorkProgram.actions;
  }

  private addAction(): void {
    if (isNullOrEmpty(this.state.description) || this.state.description == null) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.no_action_description));
      return;
    }

    if (
      this.workProgramActions.find(
        (x) => x.description.trim().toLocaleLowerCase() === this.state.description?.trim().toLocaleLowerCase()
      ) != null
    ) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.action_already_exists));
      return;
    }

    const actionFrequency = this.props.frequencies.find((x) => x.actionFrequencyId === this.state.actionFrequencyId);
    const newAction: IWorkProgramAction = {
      description: this.state.description?.trim(),
      actionFrequencyId: this.state.actionFrequencyId,
      roomTypeWorkProgramId: this.props.roomTypeWorkProgram.roomTypeWorkProgramId ?? "",
      createdOn: new Date(),
      modifiedOn: new Date(),
      actionId: undefined,
      actionFrequency: actionFrequency,
    };
    this.props.addAction(newAction, this.props.externalProjectId);
    this.onToggleNewAction(false);
  }

  private onChangeAction(action: IWorkProgramAction, event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    const actionFrequency = this.props.frequencies.find((x) => x.actionFrequencyId === value);

    if (actionFrequency != null && actionFrequency?.actionFrequencyId != null) {
      action.actionFrequency = actionFrequency;
      action.actionFrequencyId = actionFrequency?.actionFrequencyId;
      this.props.updateAction(action, this.props.externalProjectId);
    }
  }

  private onRemoveAction(action: IWorkProgramAction): void {
    this.props.removeAction(action, this.props.externalProjectId);
  }

  private onDescriptionChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    this.setState({
      description: value,
    });
  }

  private onFrequencyChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    this.setState({
      actionFrequencyId: value,
    });
  }

  private onToggleNewAction(doShow: boolean): void {
    if (doShow) {
      this.setState({
        showNewActionItem: doShow,
      });
    } else {
      this.setState({
        showNewActionItem: doShow,
        description: undefined,
      });
    }
  }

  private handleKeyPressAddAction(event: { key: string }): void {
    if (event.key === "Enter") {
      this.addAction();
    } else if (event.key === "Escape") {
      this.onToggleNewAction(false);
    }
  }

  public render(): JSX.Element {
    const sortByDescription = this.workProgramActions.sort((a, b) => a.description.localeCompare(b.description)) ?? [];
    const sortByFrequency =
      this.workProgramActions.sort(
        (a, b) => (b.actionFrequency?.timesPerYear ?? 0) - (a.actionFrequency?.timesPerYear ?? 0)
      ) ?? [];

    const basicActions = sortByDescription.filter((a) => !a.actionFrequency?.periodic);
    const periodicActions = sortByFrequency.filter((a) => a.actionFrequency?.periodic);

    const basicActionsJSX = basicActions.map((action) => (
      <div className="action-list-items" key={action.description}>
        <p>{action.description}</p>
        <Form.Control
          as="select"
          value={action.actionFrequencyId}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangeAction(action, e)}
          className="action-list-new-item-dropdown"
        >
          {this.renderFrequencies}
        </Form.Control>
        <picture className="action-list-trash-img" onClick={(): void => this.onRemoveAction(action)}>
          <img src={DeleteIcon} alt="delete action" />
        </picture>
      </div>
    ));
    const periodicActionsJSX = periodicActions.map((action) => (
      <div className="action-list-items" key={action.description}>
        <p>{action.description}</p>
        <Form.Control
          as="select"
          value={action.actionFrequencyId}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangeAction(action, e)}
          className="action-list-new-item-dropdown"
        >
          {this.renderFrequencies}
        </Form.Control>
        <picture className="action-list-trash-img" onClick={(): void => this.onRemoveAction(action)}>
          <img src={DeleteIcon} alt="delete action" />
        </picture>
      </div>
    ));

    return (
      <>
        <p className="action-list-title">{LanguageProvider.t(TranslationMapper.pages.workprogram.basic_actions)}</p>
        {basicActionsJSX}
        <p className="action-list-title">{LanguageProvider.t(TranslationMapper.pages.workprogram.periodic_actions)}</p>
        {periodicActionsJSX}
        {this.state.showNewActionItem && (
          <div className="action-list-new-action" onKeyDown={this.handleKeyPressAddAction}>
            <input
              placeholder={LanguageProvider.t(TranslationMapper.components.labels.description)}
              type="text"
              onChange={this.onDescriptionChanged}
              autoFocus
            ></input>
            <Form.Control
              as="select"
              value={this.state.actionFrequencyId}
              onChange={this.onFrequencyChanged}
              className="action-list-new-item-dropdown"
            >
              {this.renderFrequencies}
            </Form.Control>
            <picture className="action-list-save-img" onClick={this.addAction}>
              <img src={SaveIcon} alt="save new action" />
            </picture>
            <picture className="action-list-trash-img">
              <img src={CloseIcon} alt="close new action" onClick={(): void => this.onToggleNewAction(false)} />
            </picture>
          </div>
        )}
        <div className="action-add-action-button-group" onClick={(): void => this.onToggleNewAction(true)}>
          <picture>
            <img src={AddIcon} alt="Add action" className="workprogram-add-action"></img>
          </picture>
          <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.add_action)}</p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IWorkProgramActionListStateProps => ({});

const mapDispatchToProps: IWorkProgramActionListDispatchProps = {
  removeAction: removeAction,
  addAction: addAction,
  updateAction: updateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkProgramActionList);
