// CSS that can't be imported as scss partial
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-notifications/dist/react-notifications.css";
import "react-notifications/lib/notifications.css";
import "./main.scss";

import NavBar from "components/navbar/navbar";
import AccountRegistrationManagement from "pages/accountRegistrationManagement/accountRegistrationManagement";
import AvgGdprManagement from "pages/avgGdprManagement/avgGdprManagement";
import EndUserChat from "pages/endUserChat/endUserChat";
import ExecutorChat from "pages/executorChat/executorChat";
import OfferRequestManagement from "pages/offerRequestManagement/offerRequestManagement";
import PostalCodeRequest from "pages/postalCodeRequest/postalCodeRequest";
import ProjectWorkProgram from "pages/projectWorkProgram/projectWorkProgram";
import ActiveRegions from "pages/regions/activeRegions";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import DateUtils from "utils/dateUtils";

import TranslationMapper from "i18n/mapper";
import PageAuth from "pages/authorization/authorization";
import PageHome from "pages/homePage/homePage";
import PageNotification from "pages/notification/notification";
import UnitPrice from "pages/unitPrice/unitPrice";
import AuthProvider from "providers/authProvider";
import LanguageProvider from "providers/languageProvider";
import { withTranslation } from "react-i18next";
import ServiceWorker from "./components/serviceWorker/serviceWorker";
import IAppProps, { IAppStateProps } from "./interfaces/IAppProps";
import { RoutingPath } from "./providers/routingProvider";

class App extends React.Component<IAppProps> {
  public constructor(props: IAppProps) {
    super(props);

    DateUtils.setDefaultLocaleGlobally();
  }

  private readonly navRoutes: any = () => (
    <Switch>
      <Redirect exact from={RoutingPath.null} to={RoutingPath.home} />
      <Route exact path={RoutingPath.home} component={PageHome} />
      <Route path={RoutingPath.chatEndUser} component={EndUserChat} />
      <Route path={RoutingPath.chatExecutor} component={ExecutorChat} />
      <Route exact path={RoutingPath.activeRegions} component={ActiveRegions} />
      <Route exact path={RoutingPath.offerRequests} component={OfferRequestManagement} />
      <Route exact path={RoutingPath.accountRegistrationRequests} component={AccountRegistrationManagement} />
      <Route exact path={RoutingPath.postalCodeRequests} component={PostalCodeRequest} />
      <Route exact path={RoutingPath.avgGdprManagement} component={AvgGdprManagement} />
      <Route exact path={RoutingPath.workProgram} component={ProjectWorkProgram} />
      <Route exact path={RoutingPath.unitPrice} component={UnitPrice} />
      <Route exact path={RoutingPath.authorization} component={PageAuth} />
      <Route exact path={RoutingPath.notification} component={PageNotification} />
    </Switch>
  );

  public render(): JSX.Element {
    return (
      <div className="app">
        <ServiceWorker />
        {AuthProvider.isUserAllowedByPath(this.props.location.pathname) ? (
          this.navRoutes()
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column text-center h-100">
            <h1 className="mb-4 mx-3">{LanguageProvider.t(TranslationMapper.pages.auth.unauthorized.title)}</h1>
            <p className="mx-3">{LanguageProvider.t(TranslationMapper.pages.auth.unauthorized.message)}</p>
          </div>
        )}
        <div id="modal-hook"></div>
        <NavBar />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IAppStateProps => ({
  error: state.errorState.error,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(App)));
