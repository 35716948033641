import SortIcon from "images/arrange.svg";
import React, { FunctionComponent } from "react";
import { useExpanded, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Form } from "react-bootstrap";
import Button from "../buttons/button";
import CheckBox from "../checkbox/checkbox";
import IReactTableProps from "../table/interfaces/IReactTableProps";

export const ReactTable: FunctionComponent<IReactTableProps> = ({
  columns,
  data,
  renderRowSubComponent,
  className,
  defaultColumnToSortBy,
  isSelectable,
  setSelection,
  usePaginationForTable,
  isLoading,
}) => {
  // The react-table package uses hooks, so needs a functional component instead of class component to work.
  // Documentation package usage: https://react-table.tanstack.com/docs/overview
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultColumnToSortBy ? [defaultColumnToSortBy] : undefined,
        pageSize: usePaginationForTable ? 10 : data.length,
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isSelectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ toggleRowSelected, rows, selectedFlatRows }): JSX.Element => {
              const selectableRows = rows.filter(
                (row) =>
                  row.values.status ===
                  LanguageProvider.t(TranslationMapper.pages.offer_request_management.request_status_0)
              ).length;
              const checked: boolean =
                rows.length >= 1 && selectableRows === selectedFlatRows.length && selectableRows > 0;
              const title: string = LanguageProvider.t(TranslationMapper.components.labels.label_select_all_rows);
              const indeterminate: boolean = !checked && selectedFlatRows.length > 0;
              const disabled: boolean = rows.length === 1 || selectableRows === 0;
              const overiddenOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
                rows.forEach((row) => {
                  if (
                    row.values.status ===
                    LanguageProvider.t(TranslationMapper.pages.offer_request_management.request_status_0)
                  ) {
                    toggleRowSelected(row.id, event.currentTarget.checked);
                  }
                });
              };
              const modifiedToggleAllRowsProps = {
                onChange: overiddenOnChange,
                checked: checked,
                title: title,
                indeterminate: indeterminate,
                disabled: disabled,
              };
              return (
                <div>
                  <CheckBox {...modifiedToggleAllRowsProps} />
                </div>
              );
            },
            Cell: ({ row }): JSX.Element => {
              const disabled =
                row.values.status !==
                LanguageProvider.t(TranslationMapper.pages.offer_request_management.request_status_0);
              return (
                <div>
                  <CheckBox disabled={disabled} {...row.getToggleRowSelectedProps()} />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );

  React.useEffect(() => {
    if (setSelection != null) {
      setSelection(selectedFlatRows.map((row) => row.original));
    }
  }, [setSelection, selectedFlatRows]);

  let pageNumbers: number[] = [];
  const pageSizes: number[] = usePaginationForTable ? [10, 25, 50, 100] : [500];
  const preferedNumberOfPagesInPagination = 5;

  if (pageCount === 1 || pageCount === 0 || !usePaginationForTable) {
    pageNumbers = [1];
  } else {
    if (pageCount < preferedNumberOfPagesInPagination) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = 0; i < preferedNumberOfPagesInPagination; i++) {
        const pageNumber = pageIndex + i;
        if (pageNumber > 0 && pageNumber <= pageCount) {
          pageNumbers.push(pageNumber);
        }
      }
    }
  }

  if (pageIndex + 1 > pageCount && pageCount !== 0) {
    gotoPage(0);
  }

  return (
    <>
      {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) =>
          column.Filter ? (
            <div key={column.id}>
              <label htmlFor={column.id}>{column.render("Header")}: </label>
              {column.render("Filter")}
            </div>
          ) : null
        )
      )}

      <table {...getTableProps()} className={className ? `g-react-table ${className}` : "g-react-table"}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      title: LanguageProvider.t(TranslationMapper.components.labels.label_sort),
                    })
                  )}
                >
                  {column.canSort && <img src={SortIcon} className="g-react-table-sort-icon" alt="sort column" />}
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading === true ? (
            <tr>
              <td colSpan={columns.length}>
                <div className="w-100 d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={i}>
                    <tr>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={columns.length}>{renderRowSubComponent && renderRowSubComponent(row)}</td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {usePaginationForTable && (
        <div className="pagination-container">
          <div className="pagination-buttons">
            <span>
              {pageCount === 0
                ? `${LanguageProvider.t(TranslationMapper.components.labels.pagination_label_no_result)}`
                : `${pageIndex + 1} / ${pageOptions.length}`}
            </span>
          </div>
          <div className="pagination-buttons">
            <Button
              className="pagination-button-prev"
              label={"<<"}
              onClick={(): void => gotoPage(0)}
              isDisabled={!canPreviousPage}
            ></Button>
            <Button
              className="pagination-button-prev"
              label={"<"}
              onClick={(): void => previousPage()}
              isDisabled={!canPreviousPage}
            ></Button>
            {pageNumbers.map((pageNumber) => (
              <Button
                key={pageNumber.toString()}
                className={pageNumber === pageIndex + 1 ? "pagination-button-page active" : "pagination-button-page"}
                label={pageNumber.toString()}
                onClick={(): void => gotoPage(pageNumber - 1)}
              ></Button>
            ))}
            <Button
              className="pagination-button-next"
              label={">"}
              onClick={(): void => nextPage()}
              isDisabled={!canNextPage}
            ></Button>
            <Button
              className="pagination-button-next"
              label={">>"}
              onClick={(): void => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
            ></Button>
          </div>
          <div className="pagination-buttons">
            <span>{LanguageProvider.t(TranslationMapper.components.labels.pagination_label_number_of_pages)}:</span>
            <Form.Control as="select" value={pageSize} onChange={(e): void => setPageSize(Number(e.target.value))}>
              {pageSizes.map((pageSize) => (
                <option value={pageSize} key={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="pagination-buttons">
            <span>
              {LanguageProvider.t(TranslationMapper.components.labels.pagination_total)}: {rows.length}
            </span>
          </div>
          {selectedFlatRows.length > 0 && (
            <div className="pagination-buttons">
              <span>
                {LanguageProvider.t(TranslationMapper.components.labels.label_selected_rows)}: {selectedFlatRows.length}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
