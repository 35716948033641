import moment from "moment";
import Resizer from "react-image-file-resizer";

export default class ImageUtil {
  // Sas token is valid for 2 hours in BE, to avoid timing issues we keep a margin of 5 minutes
  private static readonly sasTokenExpirationTimeInMinutes: number = 115;

  public static compressToBase64Async(imageFile: File): Promise<string> {
    switch (imageFile.type) {
      case "image/jpg":
      case "image/jpeg":
      case "image/png":
        return ImageUtil.compressImageToMaximizedBase64Async(imageFile);
      default:
        throw new Error("File type not allowed");
    }
  }

  public static compressImageToMaximizedBase64Async(image: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          image,
          1024,
          1024,
          "JPEG",
          100,
          0,
          (resizedImage) => {
            return resolve(resizedImage as string);
          },
          "base64"
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw new Error(error);
      }
    });
  }

  public static hasSasTokenExpired(expirationDate: Date): boolean {
    const now = moment().toDate();
    return expirationDate <= now;
  }

  public static getSasTokenExpirationDate(): Date {
    return moment().add(this.sasTokenExpirationTimeInMinutes, "minutes").toDate();
  }
}
