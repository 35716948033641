import IProjectWorkProgram from "interfaces/IProjectWorkProgram";
import IRoomTypeWorkProgram from "interfaces/IRoomTypeWorkProgram";
import IWorkProgramAction from "interfaces/IWorkProgramAction";
import IWorkProgramActionFrequency from "interfaces/IWorkProgramActionFrequency";
import ManagementService from "services/managementService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const fetchedWorkProgram = actionCreator<ActionTypes.FETCH_WORK_PROGRAM, IProjectWorkProgram | undefined>(
  ActionTypes.FETCH_WORK_PROGRAM
);
export const fetchedActionFrequencies = actionCreator<
  ActionTypes.FETCH_ACTION_FREQUENCIES,
  IWorkProgramActionFrequency[]
>(ActionTypes.FETCH_ACTION_FREQUENCIES);
export const workProgramIsLoading = actionCreator<ActionTypes.WORK_PROGRAM_LOADING, boolean>(
  ActionTypes.WORK_PROGRAM_LOADING
);

export function fetchWorkProgram(externalProjectId: string | undefined): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleWorkProgramIsLoading(true));
    managementService
      .getWorkProgramAsync(externalProjectId)
      .then((workProgram: IProjectWorkProgram) => {
        dispatch(fetchedWorkProgram(workProgram));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.fetchWorkProgram",
            message: `Error on fetching workprogram ${error}`,
          },
        });
      })
      .finally(() => {
        dispatch(toggleWorkProgramIsLoading(false));
      });
  };
}

export function createProjectWorkProgram(externalProjectId: string): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .createProjectWorkProgramAsync(externalProjectId)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.createProjectWorkProgram",
            message: `Error on create workprogram ${error}`,
          },
        });
      });
  };
}

export function fetchActionFrequencies(): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .getActionFrequenciesAsync()
      .then((frequencies) => {
        dispatch(fetchedActionFrequencies(frequencies));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.fetchActionFrequencies",
            message: `Error on fetch action frequencies ${error}`,
          },
        });
      })
      .finally(() => {
        dispatch(toggleWorkProgramIsLoading(false));
      });
  };
}

export function removeAction(action: IWorkProgramAction, externalProjectId: string): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .removeActionAsync(action)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.removeActionAsync",
            message: `Error on remove action ${error}`,
          },
        });
      });
  };
}

export function addAction(action: IWorkProgramAction, externalProjectId: string): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .addActionAsync(action)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.addActionAsync",
            message: `Error on add action ${error}`,
          },
        });
      });
  };
}

export function updateAction(action: IWorkProgramAction, externalProjectId: string): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .updateActionAsync(action)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.updateActionAsync",
            message: `Error on update action ${error}`,
          },
        });
      });
  };
}

export function addRoom(room: IRoomTypeWorkProgram, externalProjectId: string): AppAction {
  return (dispatch): void => {
    dispatch(toggleWorkProgramIsLoading(true));
    const managementService = new ManagementService();
    managementService
      .addRoomAsync(room)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.addRoom",
            message: `Error on add room ${error}`,
          },
        });
      });
  };
}

export function removeRoom(room: IRoomTypeWorkProgram, externalProjectId: string): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    managementService
      .removeRoomAsync(room)
      .then(() => {
        dispatch(fetchWorkProgram(externalProjectId));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "ProjectWorkProgramActions.removeRoom",
            message: `Error on remove room ${error}`,
          },
        });
      });
  };
}

export function resetProjectWorkProgram(): AppAction {
  return (dispatch): void => {
    dispatch(fetchedWorkProgram(undefined));
  };
}

export function toggleWorkProgramIsLoading(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(workProgramIsLoading(isLoading));
  };
}
