import { produce } from "immer";
import { IAccountStoreState, initialAccountStoreState } from "store/state/accountStoreState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

function accountReducer(
  state: IAccountStoreState = initialAccountStoreState,
  action: ActionTypesCollection
): IAccountStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_ACCOUNT_REGISTRATION:
      return { ...state, isLoading: action.payload };
    case ActionTypes.FETCHED_ACCOUNT_REGISTRATION_REQUESTS: {
      return { ...state, accountRegistrationRequests: [...action.payload] };
    }
    case ActionTypes.FETCHED_DEBTOR_INFORMATION:
      return produce(state, (draftState) => {
        draftState.fetchedDebtorInformation = action.payload;
      });
    case ActionTypes.UPDATE_FETCHING_ACCOUNT_REGISTRATION:
      return { ...state, fetchingDebtorInformation: action.payload };
    case ActionTypes.UPDATE_UPSERTING_ACCOUNT_REGISTRATION:
      return { ...state, upsertingAccountRegistration: action.payload };
    default:
      return state;
  }
}

export default accountReducer;
