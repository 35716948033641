import IAccountRegistrationRequest from "interfaces/IAccountRegistrationRequest";
import IDebtorInformation from "interfaces/IDebtorInformation";

export interface IAccountStoreState {
  isLoading: boolean;
  fetchingDebtorInformation: boolean;
  upsertingAccountRegistration: boolean;
  accountRegistrationRequests?: IAccountRegistrationRequest[];
  fetchedDebtorInformation?: IDebtorInformation;
}

export const initialAccountStoreState: IAccountStoreState = {
  isLoading: false,
  fetchingDebtorInformation: false,
  upsertingAccountRegistration: false,
  accountRegistrationRequests: [],
  fetchedDebtorInformation: undefined,
};
