import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { RoomType } from "enums/roomType";
import TranslationMapper from "i18n/mapper";
import IRoomTypeWorkProgram from "interfaces/IRoomTypeWorkProgram";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Container, Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { addRoom, removeRoom } from "store/actions/projectWorkProgramActions";
import { RootState } from "store/reducers/rootReducer";
import DeleteIcon from "../../images/delete.svg";
import WorkProgramActionList from "./components/workProgramActionList";
import IWorkProgramProps, { IWorkProgramDispatchProps, IWorkProgramStateProps } from "./interfaces/IWorkProgramProps";
import IWorkProgramState from "./interfaces/IWorkProgramState";

class WorkProgram extends Component<IWorkProgramProps, IWorkProgramState> {
  public constructor(props: IWorkProgramProps) {
    super(props);

    const state: IWorkProgramState = {
      showAddRoomModal: false,
      showDeleteRoom: false,
      selectedRoomType: 0,
      numberOfRoomType: 1,
      isLoading: true,
    };

    this.state = state;

    this.toggleAddRoomModal = this.toggleAddRoomModal.bind(this);
    this.toggleDeleteRoomModal = this.toggleDeleteRoomModal.bind(this);
    this.onRoomTypeChange = this.onRoomTypeChange.bind(this);
    this.onNumberOfRoomTypeChange = this.onNumberOfRoomTypeChange.bind(this);
    this.onAddRoom = this.onAddRoom.bind(this);
    this.setRoomIndex = this.setRoomIndex.bind(this);
  }

  public componentDidMount(): void {
    // On componentDidMount set a <string, number> Map to create a sorting index for each room.
    this.setRoomIndex();
  }

  private readonly roomTypeIndex: Map<string, number> = new Map<string, number>([]);

  private setRoomIndex(): void {
    // Default sorting: amount of actions descending.
    for (let i = 0; i < this.rooms.length; i++) {
      this.roomTypeIndex.set(this.rooms.sort((a, b) => b.actions.length - a.actions.length)[i].roomType.toString(), i);
    }
  }

  private readonly roomTypeTranslations: Map<string, string> = new Map<string, string>([
    [RoomType[RoomType.living_room], LanguageProvider.t(TranslationMapper.pages.workprogram.living_room)],
    [RoomType[RoomType.bath_room], LanguageProvider.t(TranslationMapper.pages.workprogram.bath_room)],
    [RoomType[RoomType.entrance], LanguageProvider.t(TranslationMapper.pages.workprogram.entrance)],
    [RoomType[RoomType.kitchen], LanguageProvider.t(TranslationMapper.pages.workprogram.kitchen)],
    [RoomType[RoomType.landing], LanguageProvider.t(TranslationMapper.pages.workprogram.landing)],
    [RoomType[RoomType.bed_room], LanguageProvider.t(TranslationMapper.pages.workprogram.bed_room)],
    [RoomType[RoomType.other_rooms], LanguageProvider.t(TranslationMapper.pages.workprogram.other_rooms)],
    [RoomType[RoomType.toilet], LanguageProvider.t(TranslationMapper.pages.workprogram.toilet)],
  ]);

  private readonly externalProjectId: string = this.props.workProgram?.externalProjectId ?? "";

  private get rooms(): IRoomTypeWorkProgram[] {
    return this.props.workProgram?.roomTypeWorkPrograms ?? [];
  }

  private get sortedRooms(): IRoomTypeWorkProgram[] {
    // When getting the rooms, use the Map of indexes (roomTypeIndex) created in componentDidMount to sort the rooms.
    // When an action is added or removed from the room, the original indexes are used to sort.
    // When the page is reloaded the componentDidMount creates a new Map of indexes with the new amount of actions per room.
    // When a new room is added, it gets pushed to the front of the array by setting the default value to -100 (just a lower number than any other room).
    return this.rooms.sort(
      (a, b) =>
        (this.roomTypeIndex.get(a.roomType.toString()) ?? -100) - (this.roomTypeIndex.get(b.roomType.toString()) ?? 0)
    );
  }

  private get getRoomTypes(): JSX.Element[] {
    const checkMark = "✓ ";
    const roomTypes = Object.keys(RoomType)
      .filter((x) => !(parseInt(x) >= 0))
      .map((type, key) => (
        <option value={key} key={type}>
          {this.rooms.map((x) => x.roomType).includes(key) ? checkMark : ""}
          {LanguageProvider.t(TranslationMapper.pages.workprogram[type])}
        </option>
      ));
    return roomTypes;
  }

  private onAddRoom(): void {
    if (this.rooms.map((x) => x.roomType).includes(this.state.selectedRoomType)) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.workprogram.roomtype_already_exists));
    } else {
      const newRoom: IRoomTypeWorkProgram = {
        projectWorkProgramId: this.props.workProgram?.projectWorkProgramId ?? "",
        roomType: this.state.selectedRoomType,
        numberOfRooms: this.state.numberOfRoomType,
        actions: [],
        createdOn: new Date(),
        modifiedOn: new Date(),
        isStandardWorkProgram: this.props.workProgram?.externalProjectId == null,
      };
      this.props.addRoom(newRoom, this.externalProjectId);
      this.toggleAddRoomModal(false);
    }
  }

  private onRemoveRoom(room: IRoomTypeWorkProgram | undefined, externalProjectId: string): void {
    if (room !== undefined) {
      this.props.removeRoom(room, externalProjectId);
      this.toggleDeleteRoomModal(false, undefined);
    }
  }

  private onRoomTypeChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState({
      selectedRoomType: Number(value),
    });
  }

  private onNumberOfRoomTypeChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    if (!isNaN(Number.parseInt(value))) {
      this.setState({
        numberOfRoomType: Number(value),
      });
    } else {
      this.setState({
        numberOfRoomType: 0,
      });
    }
  }

  private toggleAddRoomModal(show: boolean): void {
    this.setState({
      showAddRoomModal: show,
    });
  }

  private toggleDeleteRoomModal(show: boolean, room: IRoomTypeWorkProgram | undefined): void {
    this.setState({
      showDeleteRoom: show,
      roomToDelete: room,
    });
  }

  public render(): JSX.Element {
    const roomTypes = this.sortedRooms.map((roomtype) => (
      <div className="workprogram-roomtype-card" key={roomtype.roomType}>
        <div className="flex-group justify-content-between mb-3">
          <p className="workprogram-roomtype-card-title">
            {this.roomTypeTranslations.get(RoomType[roomtype.roomType])}
          </p>
          <div className="flex-group">
            {roomtype.numberOfRooms >= 1 && <p className="workprogram-roomtype-card-pill">{roomtype.numberOfRooms}x</p>}
            <picture
              className="workprogram-roomtype-card-trash-img"
              onClick={(): void => this.toggleDeleteRoomModal(true, roomtype)}
            >
              <img src={DeleteIcon} alt="delete action" />
            </picture>
          </div>
        </div>
        <div className="workprogram-roomtype-card-content">
          <WorkProgramActionList
            roomTypeWorkProgram={roomtype}
            frequencies={this.props.frequencies}
            externalProjectId={this.externalProjectId}
          />
        </div>
      </div>
    ));

    return (
      <>
        <div className="flex-group">
          <Button
            className="g-button-blue"
            label={LanguageProvider.t(TranslationMapper.pages.workprogram.add_room)}
            onClick={(): void => this.toggleAddRoomModal(true)}
            isDisabled={this.props.isLoading}
          />
          {this.props.workProgram?.externalProjectId != null && (
            <h1 className="m-0">
              {LanguageProvider.t(TranslationMapper.pages.workprogram.workprogram_project)}
              {this.props.workProgram?.externalProjectId}
            </h1>
          )}
        </div>
        <div className="workprogram-content-wrapper">{roomTypes}</div>

        <BasicPopup
          showPopup={this.state.showAddRoomModal}
          onClose={(): void => this.toggleAddRoomModal(false)}
          headerText={LanguageProvider.t(TranslationMapper.pages.workprogram.add_room)}
        >
          <Container>
            <div className="workprogram-modal-content">
              <Form.Label>{LanguageProvider.t(TranslationMapper.pages.workprogram.roomtype)}</Form.Label>
              <Form.Control
                as="select"
                className="clickable"
                value={this.state.selectedRoomType}
                onChange={this.onRoomTypeChange}
              >
                {this.getRoomTypes}
              </Form.Control>
              <Form.Label>{LanguageProvider.t(TranslationMapper.components.labels.number_of_roomtype)}</Form.Label>
              <Form.Control
                defaultValue={this.state.numberOfRoomType}
                type="number"
                onChange={this.onNumberOfRoomTypeChange}
              ></Form.Control>
              <Button
                className="g-button-blue mt-2"
                label={LanguageProvider.t(TranslationMapper.global.buttons.save)}
                onClick={this.onAddRoom}
                isDisabled={this.state.numberOfRoomType === 0}
              />
            </div>
          </Container>
        </BasicPopup>
        <ConfirmationPrompt
          showPrompt={this.state.showDeleteRoom}
          onCancel={(): void => this.toggleDeleteRoomModal(false, undefined)}
          onAgree={(): void => this.onRemoveRoom(this.state.roomToDelete, this.externalProjectId)}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IWorkProgramStateProps => ({
  isLoading: state.projectWorkProgramState.isLoading,
});

const mapDispatchToProps: IWorkProgramDispatchProps = {
  addRoom: addRoom,
  removeRoom: removeRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkProgram);
