import produce from "immer";
import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { INotificationStoreState, initialNotificationStoreState } from "store/state/notificationStoreState";

function notificationReducer(
  state: INotificationStoreState = initialNotificationStoreState,
  action: ActionTypesCollection
): INotificationStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_NOTIFICATION_DELETE_NOTIFICATION:
      return produce(state, (draftState) => {
        draftState.isLoadingDeleteNotification = action.payload ?? false;
      });
    case ActionTypes.UPDATE_LOADING_NOTIFICATION_GET_ALL_NOTIFICATION:
      return produce(state, (draftState) => {
        draftState.isLoadingGetAllNotifications = action.payload ?? false;
      });
    case ActionTypes.UPDATE_LOADING_NOTIFICATION_UPSERT_NOTIFICATION:
      return produce(state, (draftState) => {
        draftState.isLoadingUpsertNotification = action.payload ?? false;
      });
    case ActionTypes.FETCHED_NOTIFICATION_ALL_ITEMS:
      return produce(state, (draftState) => {
        draftState.notifications = action.payload ?? [];
      });
    default:
      return state;
  }
}

export default notificationReducer;
