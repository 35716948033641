/* eslint-disable @typescript-eslint/explicit-function-return-type */
import TranslationMapper from "i18n/mapper";
import IServiceCategory from "interfaces/IServiceCategory";
import ISupplementedServiceOfferRequest from "interfaces/ISupplementedServiceOfferRequest";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import AccountService from "services/accountService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const setOfferRequestsLoading = actionCreator<ActionTypes.UPDATE_LOADING_OFFER_REQUESTS, boolean>(
  ActionTypes.UPDATE_LOADING_OFFER_REQUESTS
);
export const fetchedOfferRequests = actionCreator<
  ActionTypes.FETCHED_OFFER_REQUESTS,
  ISupplementedServiceOfferRequest[]
>(ActionTypes.FETCHED_OFFER_REQUESTS);
export const fetchedServices = actionCreator<ActionTypes.FETCHED_SERVICES, IServiceCategory[]>(
  ActionTypes.FETCHED_SERVICES
);

export function fetchOfferRequests(): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();

    dispatch(toggleOfferRequestsLoadingStatus(true));

    accountService
      .getServiceOfferRequestsAsync()
      .then((offers) => dispatch(fetchedOfferRequests(offers)))
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "OfferRequestActions.fetchOfferRequests",
            message: `Error on fetch service offer requests ${error}`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_fetch_offer_requests)
        );
        console.warn(error);
      })
      .finally(() => dispatch(toggleOfferRequestsLoadingStatus(false)));

    dispatch(toggleOfferRequestsLoadingStatus(false));
  };
}

export function updateOfferRequest(offerId: string, updatedPrice: number, onSuccess: () => void): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();

    dispatch(toggleOfferRequestsLoadingStatus(true));

    accountService
      .updateServiceOfferRequestAsync(offerId, updatedPrice)
      .then(() => {
        dispatch(fetchOfferRequests());
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.update_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "OfferRequestActions.sendUpdatedOfferRequest",
            message: `Error on update service offer request '${offerId}', error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_send_update)
        );
        console.warn(error);
      })
      .finally(() => dispatch(toggleOfferRequestsLoadingStatus(false)));
  };
}

export function deleteOfferRequest(offerId: string): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();

    dispatch(toggleOfferRequestsLoadingStatus(true));

    accountService
      .deleteServiceOfferRequestAsync(offerId)
      .then(() => {
        dispatch(fetchOfferRequests());
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.delete_success)
        );
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "OfferRequestActions.deleteOfferRequest",
            message: `Error on deleting service offer request '${offerId}', error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_delete));
        console.warn(error);
      })
      .finally(() => dispatch(toggleOfferRequestsLoadingStatus(false)));
  };
}

export function fetchAvailableServices(): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();

    dispatch(toggleOfferRequestsLoadingStatus(true));

    accountService
      .getAllAvailableServicesAsync(LanguageProvider.language)
      .then((services) => dispatch(fetchedServices(services)))
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "OfferRequestActions.fetchAvailableServices",
            message: `Error on fetch service categories ${error}`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_fetch_service_categories));
        console.warn(error);
      })
      .finally(() => dispatch(toggleOfferRequestsLoadingStatus(false)));
  };
}

export function toggleOfferRequestsLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch) => {
    dispatch(setOfferRequestsLoading(isLoading));
  };
}
