import React, { Component } from "react";
import IButtonProps from "./interfaces/IButtonProps";

class Button extends Component<IButtonProps> {
  private readonly buttonRef: any;

  public constructor(props: IButtonProps) {
    super(props);

    this.buttonRef = React.createRef();

    this.onClick = this.onClick.bind(this);
  }

  private onClick(eventArgs: any): void {
    this.props.onClick && this.props.onClick(eventArgs);
  }

  public static getClassName(value?: string): string {
    return value || "";
  }

  private get className(): string {
    let className = "g-btn";

    if (this.props.className != null) {
      className += ` ${this.props.className}`;
    }

    if (this.props.isDisabled === true || this.props.isLoading === true) {
      className += " disabled";
    }

    if (this.props.style !== undefined) {
      className += ` ${this.props.style}`;
    }

    return className;
  }

  public render(): JSX.Element {
    return (
      <button
        className={this.className}
        disabled={this.props.isDisabled === true || this.props.isLoading === true}
        id={this.props.id}
        ref={this.buttonRef}
        onClick={this.onClick}
        data-test={this.props["data-test"]}
      >
        {this.props.isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        ) : (
          this.props.label
        )}

        {this.props.imageSrc && !this.props.addImageMask && <img src={this.props.imageSrc} alt={this.props.label} />}

        {this.props.imageSrc && this.props.addImageMask && (
          <div className="g-image-mask">
            <img src={this.props.imageSrc} alt={this.props.label} />
          </div>
        )}
      </button>
    );
  }
}

export default Button;
