import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import { Nav, Navbar } from "react-bootstrap";
import NavLink from "./components/navlink";

import { AuthByRole } from "enums/userRole";
import AccountIcon from "images/account.svg";
import AddressIcon from "images/address.svg";
import ChatIcon from "images/contact.svg";
import GdprAvgIcon from "images/gdpr.svg";
import WorkProgramIcon from "images/list-check-solid.svg";
import NotificationIcon from "images/notification.svg";
import PostalCodeRequestIcon from "images/pending-customers.svg";
import PriceIcon from "images/price.svg";
import RolesIcon from "images/roles.svg";
import AuthProvider from "providers/authProvider";

export default function NavBar() {
  function paths(): { [pageName: string]: string } {
    return {
      [RoutingPath.home]: TranslationMapper.global.navbar_titles.enduser_chat,
      [RoutingPath.chatEndUser]: TranslationMapper.global.navbar_titles.enduser_chat,
      [RoutingPath.chatExecutor]: TranslationMapper.global.navbar_titles.executor_chat,
      [RoutingPath.postalCodeRequests]: TranslationMapper.global.navbar_titles.waiters,
      [RoutingPath.activeRegions]: TranslationMapper.global.navbar_titles.active_regions,
      [RoutingPath.offerRequests]: TranslationMapper.global.navbar_titles.offer_requests,
      [RoutingPath.accountRegistrationRequests]: TranslationMapper.global.navbar_titles.account_registration_request,
      [RoutingPath.avgGdprManagement]: TranslationMapper.global.navbar_titles.avg_gdpr_management,
      [RoutingPath.workProgram]: TranslationMapper.global.navbar_titles.work_program,
      [RoutingPath.notification]: TranslationMapper.global.navbar_titles.notification,
      [RoutingPath.unitPrice]: TranslationMapper.global.navbar_titles.unit_price,
      [RoutingPath.authorization]: TranslationMapper.global.navbar_titles.auth,
    };
  }

  function pageName(pathName: string): string {
    return paths()[pathName] ? LanguageProvider.t(paths()[pathName]) : "";
  }

  function getNavigationIcon(routingPath: string): string {
    switch (routingPath) {
      case RoutingPath.postalCodeRequests:
        return PostalCodeRequestIcon;
      case RoutingPath.chatEndUser:
        return ChatIcon;
      case RoutingPath.chatExecutor:
        return ChatIcon;
      case RoutingPath.activeRegions:
        return AddressIcon;
      case RoutingPath.unitPrice:
      case RoutingPath.offerRequests:
        return PriceIcon;
      case RoutingPath.accountRegistrationRequests:
        return AccountIcon;
      case RoutingPath.avgGdprManagement:
        return GdprAvgIcon;
      case RoutingPath.workProgram:
        return WorkProgramIcon;
      case RoutingPath.authorization:
        return RolesIcon;
      case RoutingPath.notification:
        return NotificationIcon;
      default:
        return "";
    }
  }

  return (
    <Navbar fixed="bottom" className="g-nav-bar">
      <Nav justify className="g-nav">
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ChatEndUserReadWrite) && (
          <NavLink
            routingPath={RoutingPath.chatEndUser}
            pageName={pageName(RoutingPath.chatEndUser)}
            image={getNavigationIcon(RoutingPath.chatEndUser)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ChatExecutorReadWrite) && (
          <NavLink
            routingPath={RoutingPath.chatExecutor}
            pageName={pageName(RoutingPath.chatExecutor)}
            image={getNavigationIcon(RoutingPath.chatExecutor)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.PostalCodeRequestsReadWrite) && (
          <NavLink
            routingPath={RoutingPath.postalCodeRequests}
            pageName={pageName(RoutingPath.postalCodeRequests)}
            image={getNavigationIcon(RoutingPath.postalCodeRequests)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.ActiveRegionsRead) && (
          <NavLink
            routingPath={RoutingPath.activeRegions}
            pageName={pageName(RoutingPath.activeRegions)}
            image={getNavigationIcon(RoutingPath.activeRegions)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.OfferRequestsReadWrite) && (
          <NavLink
            routingPath={RoutingPath.offerRequests}
            pageName={pageName(RoutingPath.offerRequests)}
            image={getNavigationIcon(RoutingPath.offerRequests)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.AccountRegistrationRequestsReadWrite) && (
          <NavLink
            routingPath={RoutingPath.accountRegistrationRequests}
            pageName={pageName(RoutingPath.accountRegistrationRequests)}
            image={getNavigationIcon(RoutingPath.accountRegistrationRequests)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.AvgGdprManagementReadWrite) && (
          <NavLink
            routingPath={RoutingPath.avgGdprManagement}
            pageName={pageName(RoutingPath.avgGdprManagement)}
            image={getNavigationIcon(RoutingPath.avgGdprManagement)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.WorkProgramReadWrite) && (
          <NavLink
            routingPath={RoutingPath.workProgram}
            pageName={pageName(RoutingPath.workProgram)}
            image={getNavigationIcon(RoutingPath.workProgram)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.NotificationReadWrite) && (
          <NavLink
            routingPath={RoutingPath.notification}
            pageName={pageName(RoutingPath.notification)}
            image={getNavigationIcon(RoutingPath.notification)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.UnitPriceRead) && (
          <NavLink
            routingPath={RoutingPath.unitPrice}
            pageName={pageName(RoutingPath.unitPrice)}
            image={getNavigationIcon(RoutingPath.unitPrice)}
          />
        )}
        {AuthProvider.isAllowedByUserRoleAccess(AuthByRole.AuthorizationReadWrite) && (
          <NavLink
            routingPath={RoutingPath.authorization}
            pageName={pageName(RoutingPath.authorization)}
            image={getNavigationIcon(RoutingPath.authorization)}
          />
        )}
      </Nav>
    </Navbar>
  );
}
