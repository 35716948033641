import React, { Component } from "react";
import { ICheckboxProps } from "./interfaces/ICheckboxProps";

class Checkbox extends Component<ICheckboxProps> {
  private readonly defaultRef: React.RefObject<HTMLInputElement> = React.createRef();

  public componentDidMount() {
    this.setIndeterminate();
  }

  public componentDidUpdate() {
    this.setIndeterminate();
  }

  public setIndeterminate() {
    const { indeterminate } = this.props;

    if (this.defaultRef.current) {
      this.defaultRef.current.indeterminate = indeterminate ?? false;
    }
  }

  public render() {
    const { disabled, indeterminate, ...rest } = this.props;

    return (
      <>
        <input type="checkbox" className="checkbox" disabled={disabled} ref={this.defaultRef} {...rest} />
      </>
    );
  }
}

export default Checkbox;
