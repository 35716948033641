import * as msal from "@azure/msal-browser";

import { MsalAuthenticator } from "./msalAuthenticator";

import IUser from "interfaces/IUser";

export class UserAuthenticator extends MsalAuthenticator {
  public static userInfo: IUser | undefined = undefined;

  public async authenticateUserAsync(): Promise<msal.AccountInfo | null> {
    const user = await this.signInAsync();

    if (user) {
      UserAuthenticator.userInfo = {
        userId: user.localAccountId,
      };
    }

    return user;
  }
}
