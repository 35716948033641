import {
  fetchedAccountRegistrations,
  fetchedDebtorInformation,
  setAccountRegistrationLoading,
  setFetchingAccountRegistration,
  setUpsertingAccountRegistration,
} from "./actions/accountActions";
import { setActivationLoading } from "./actions/activationActions";
import {
  fetchedAuthAllRoles,
  fetchedAuthAllUsers,
  setAuthUserLoadingDelete,
  setAuthUserLoadingGetAllRoles,
  setAuthUserLoadingGetAllUsers,
  setAuthUserLoadingPostUser,
} from "./actions/authUserActions";
import {
  setChatLoading,
  setChatsDeletionLoading,
  setChatsLoading,
  updateChat,
  updateChatConversation,
  updateChatDeletionCheckDetails,
  updateChatInfo,
  updateChatMessage,
  updateTopicId,
} from "./actions/chatActions";
import { interceptedError } from "./actions/errorActions";
import {
  fetchedNotificationAllItems,
  setNotificationLoadingDeleteNotification,
  setNotificationLoadingGetAllNotifications,
  setNotificationLoadingUpsertNotification,
} from "./actions/notificationActions";
import { fetchedOfferRequests, fetchedServices, setOfferRequestsLoading } from "./actions/offerRequestActions";
import {
  fetchedPostalCodeRequests,
  setPatchingPostalCodeRequestStatusLoading,
  setPostalCodeRequestLoading,
  setSendEmailIsLoading,
} from "./actions/postalCodeRequestActions";
import {
  fetchedActionFrequencies,
  fetchedWorkProgram,
  workProgramIsLoading,
} from "./actions/projectWorkProgramActions";
import { fetchedActiveRegions, setRegionsLoading, updateActiveRegions } from "./actions/regionActions";
import { fetchedUnitPrices, setUnitPriceLoading } from "./actions/unitPriceActions";
import { updatedUser } from "./actions/userActions";

export enum ActionTypes {
  INTERCEPTED_ERROR = "INTERCEPTED_ERROR",
  UPDATE_LOADING_CHAT = "UPDATE_LOADING_CHAT",
  UPDATE_LOADING_CHATS = "UPDATE_LOADING_CHATS",
  UPDATE_LOADING_CHATS_DELETION = "UPDATE_LOADING_CHATS_DELETION",
  UPDATE_LOADING_ACCOUNT_REGISTRATION = "UPDATE_LOADING_ACCOUNT_REGISTRATION",
  UPDATE_FETCHING_ACCOUNT_REGISTRATION = "UPDATE_FETCHING_ACCOUNT_REGISTRATION",
  UPDATE_UPSERTING_ACCOUNT_REGISTRATION = "UPDATE_UPSERTING_ACCOUNT_REGISTRATION",
  UPDATE_PATCH_POSTAL_CODE_REQUEST_STATUS = "UPDATE_PATCH_POSTAL_CODE_REQUEST_STATUS",
  UPDATE_LOADING_OFFER_REQUESTS = "UPDATE_LOADING_OFFER_REQUESTS",
  UPDATE_LOADING_SEND_EMAIL = "UPDATE_LOADING_SEND_EMAIL",
  FETCHED_DEBTOR_INFORMATION = "FETCHED_DEBTOR_INFORMATION",
  UPDATE_CHAT_INFO = "UPDATE_CHAT_INFO",
  UPDATE_CHAT_CONVERSATION = "UPDATE_CHAT_CONVERSATION",
  UPDATE_CHAT = "UPDATE_CHAT",
  UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE",
  UPDATE_TOPIC_ID = "UPDATE_TOPIC_ID",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_LOADING_ACTIVATION = "UPDATE_LOADING_ACTIVATION",
  FETCHED_ACTIVE_REGIONS = "FETCHED_ACTIVE_REGIONS",
  FETCHED_CHAT_DELETION_DETAILS = "FETCHED_CHAT_DELETION_DETAILS",
  FETCHED_OFFER_REQUESTS = "FETCHED_OFFER_REQUESTS",
  FETCHED_ACCOUNT_REGISTRATION_REQUESTS = "FETCHED_ACCOUNT_REGISTRATION_REQUESTS",
  FETCHED_SERVICES = "FETCHED_SERVICES",
  UPDATE_LOADING_REGIONS = "UPDATE_LOADING_MANANGEMENT",
  UPDATE_ACTIVE_REGIONS = "UPDATE_ACTIVE_REGIONS",
  UPDATE_LOADING_POSTAL_CODE_REQUEST = "UPDATE_LOADING_POSTAL_CODE_REQUEST",
  FETCHED_POSTAL_CODE_REQUESTS = "FETCHED_POSTAL_CODE_REQUESTS",
  FETCH_WORK_PROGRAM = "FETCH_WORK_PROGRAM",
  FETCH_ACTION_FREQUENCIES = "FETCH_ACTION_FREQUENCIES",
  WORK_PROGRAM_LOADING = "WORK_PROGRAM_LOADING",
  UNIT_PRICE_LOADING = "UNIT_PRICE_LOADING",
  FETCH_UNIT_PRICE = "FETCH_UNIT_PRICE",
  UPDATE_LOADING_AUTH_USER_DELETE = "UPDATE_LOADING_AUTH_USER_DELETE",
  UPDATE_LOADING_AUTH_USER_GET_ALL_USERS = "UPDATE_LOADING_AUTH_USER_GET_ALL_USERS",
  UPDATE_LOADING_AUTH_USER_GET_ALL_ROLES = "UPDATE_LOADING_AUTH_USER_GET_ALL_ROLES",
  UPDATE_LOADING_AUTH_USER_POST_USER = "UPDATE_LOADING_AUTH_USER_POST_USER",
  FETCHED_AUTH_ALL_USERS = "FETCHED_AUTH_ALL_USERS",
  FETCHED_AUTH_ALL_ROLES = "FETCHED_AUTH_ALL_ROLES",
  FETCHED_NOTIFICATION_ALL_ITEMS = "FETCHED_NOTIFICATION_ALL_ITEMS",
  UPDATE_LOADING_NOTIFICATION_DELETE_NOTIFICATION = "UPDATE_LOADING_NOTIFICATION_DELETE_NOTIFICATION",
  UPDATE_LOADING_NOTIFICATION_GET_ALL_NOTIFICATION = "UPDATE_LOADING_NOTIFICATION_GET_ALL_NOTIFICATIONS",
  UPDATE_LOADING_NOTIFICATION_UPSERT_NOTIFICATION = "UPDATE_LOADING_NOTIFICATION_UPSERT_NOTIFICATION",
}

export type ActionTypesCollection =
  | ReturnType<typeof setChatLoading>
  | ReturnType<typeof setChatsLoading>
  | ReturnType<typeof setChatsDeletionLoading>
  | ReturnType<typeof updateChatInfo>
  | ReturnType<typeof interceptedError>
  | ReturnType<typeof fetchedDebtorInformation>
  | ReturnType<typeof updateTopicId>
  | ReturnType<typeof updatedUser>
  | ReturnType<typeof setActivationLoading>
  | ReturnType<typeof setRegionsLoading>
  | ReturnType<typeof setAccountRegistrationLoading>
  | ReturnType<typeof setFetchingAccountRegistration>
  | ReturnType<typeof setUpsertingAccountRegistration>
  | ReturnType<typeof updateChatConversation>
  | ReturnType<typeof updateChatMessage>
  | ReturnType<typeof updateChat>
  | ReturnType<typeof fetchedActiveRegions>
  | ReturnType<typeof setOfferRequestsLoading>
  | ReturnType<typeof fetchedOfferRequests>
  | ReturnType<typeof updateChatDeletionCheckDetails>
  | ReturnType<typeof fetchedAccountRegistrations>
  | ReturnType<typeof fetchedServices>
  | ReturnType<typeof updateActiveRegions>
  | ReturnType<typeof setPostalCodeRequestLoading>
  | ReturnType<typeof setSendEmailIsLoading>
  | ReturnType<typeof setPatchingPostalCodeRequestStatusLoading>
  | ReturnType<typeof fetchedPostalCodeRequests>
  | ReturnType<typeof fetchedWorkProgram>
  | ReturnType<typeof fetchedActionFrequencies>
  | ReturnType<typeof setUnitPriceLoading>
  | ReturnType<typeof fetchedUnitPrices>
  | ReturnType<typeof workProgramIsLoading>
  | ReturnType<typeof setAuthUserLoadingDelete>
  | ReturnType<typeof setAuthUserLoadingGetAllUsers>
  | ReturnType<typeof setAuthUserLoadingGetAllRoles>
  | ReturnType<typeof setAuthUserLoadingPostUser>
  | ReturnType<typeof fetchedAuthAllUsers>
  | ReturnType<typeof fetchedAuthAllRoles>
  | ReturnType<typeof fetchedNotificationAllItems>
  | ReturnType<typeof setNotificationLoadingDeleteNotification>
  | ReturnType<typeof setNotificationLoadingGetAllNotifications>
  | ReturnType<typeof setNotificationLoadingUpsertNotification>;
