import { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import CloseIcon from "../../../images/close-blue-gradient.svg";

import Button from "../../../components/material/buttons/button";
import IBasicModalProps from "./interfaces/IBasicModalProps";

import LanguageProvider from "providers/languageProvider";
import TranslationMapper from "../../../i18n/mapper";

export default class BasicModal extends Component<IBasicModalProps> {
  public constructor(props: IBasicModalProps) {
    super(props);

    this.refreshChat = this.refreshChat.bind(this);
  }

  private get modalClassName(): string {
    let className = "g-basic-modal";

    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    if (this.props.showFullscreen === true) {
      className += " fullscreen-modal";
    }

    return className;
  }

  // TODO 2131: Temporary until signalR is implemented for serviceDesk
  private refreshChat(): void {
    if (this.props.topicId !== undefined && this.props.onRefreshChat !== undefined) {
      this.props.onRefreshChat(this.props.topicId, this.props.chatType);
    }
  }

  public render(): JSX.Element {
    // Using the React portal with hook ensures modal is always rendered within proper DOM root
    const modalHook = document.getElementById("modal-hook");

    return (
      <>
        {modalHook &&
          ReactDOM.createPortal(
            <Modal
              onEntering={this.props.onEntering}
              show={this.props.showModal}
              onHide={this.props.onClose}
              className={this.modalClassName}
              dialogClassName="g-basic-modal-dialog"
              contentClassName="g-basic-modal-content"
              data-test={this.props["data-test"]}
              id={this.props.id}
              centered={this.props.showFullscreen !== true}
            >
              <Modal.Header bsPrefix={this.props.isHeaderFixed ? "g-basic-modal-header fixed" : "g-basic-modal-header"}>
                {this.props.headerImageSrc && (
                  <img
                    src={this.props.headerImageSrc}
                    className="g-basic-modal-header-image"
                    alt="popup header image"
                  />
                )}
                <div className="g-basic-modal-close-button" onClick={this.props.onClose}>
                  <img src={CloseIcon} alt="close popup" />
                </div>
                {this.props.header && <Modal.Title>{this.props.header}</Modal.Title>}
                {/* TODO 2131: Temporary until signalR is implemented for serviceDesk */}
                <Button
                  className="g-button-blue g-refresh-button"
                  label={LanguageProvider.t(TranslationMapper.global.buttons.refresh)}
                  onClick={this.refreshChat}
                />
              </Modal.Header>
              <Modal.Body
                className={
                  this.props.bodyClassName ? `g-basic-modal-body ${this.props.bodyClassName}` : "g-basic-modal-body"
                }
              >
                {this.props.children}
              </Modal.Body>
              {this.props.footer && <Modal.Footer>{this.props.footer}</Modal.Footer>}
            </Modal>,
            modalHook
          )}
      </>
    );
  }
}
