export enum UserRole {
  Unknown = "Unknown",
  Executor = "Executor",
  Admin = "Admin",
  EndUser = "EndUser",
  ServiceDesk = "ServiceDesk", // OBSOLETE: use BackOffice instead
  BackOffice = "BackOffice",
  ManagerOperations = "ManagerOperations",
  Planner = "Planner",
  HrSpecialist = "HrSpecialist",
  TeamLeader = "TeamLeader",
}

export enum AuthByRole {
  AccountRegistrationRequestsReadWrite = "AccountRegistrationRequestsReadWrite",
  ActiveRegionsReadWrite = "ActiveRegionsReadWrite",
  ActiveRegionsRead = "ActiveRegionsRead",
  AuthorizationReadWrite = "AuthorizationReadWrite",
  AvgGdprManagementReadWrite = "AvgGdprManagementReadWrite",
  ChatEndUserRead = "ChatEndUserRead",
  ChatEndUserReadWrite = "ChatEndUserReadWrite",
  ChatExecutorReadWrite = "ChatExecutorReadWrite",
  HomePageRead = "HomePageRead",
  NotificationReadWrite = "NotificationReadWrite",
  OfferRequestsReadWrite = "OfferRequestsReadWrite",
  PostalCodeRequestsReadWrite = "PostalCodeRequestsReadWrite",
  UnitPriceReadWrite = "UnitPriceReadWrite",
  UnitPriceRead = "UnitPriceRead",
  WorkProgramReadWrite = "WorkProgramReadWrite",
}
