import TranslationMapper from "i18n/mapper";
import React from "react";

import RefreshIcon from "images/refresh.svg";
import LanguageProvider from "providers/languageProvider";
import { SystemNotificationPriority } from "../../components/systemNotificationBar/interfaces/ISystemNotificationBarProps";
import SystemNotificationBar from "../../components/systemNotificationBar/systemNotificationBar";
import * as serviceWorkerRegistration from "../../registerServiceWorker";
import IServiceWorkerState from "./interfaces/IServiceWorkerState";

class ServiceWorker extends React.Component<{}, IServiceWorkerState> {
  public constructor(props: {}) {
    super(props);

    this.state = {};

    this.onUpdateAvailable = this.onUpdateAvailable.bind(this);
    this.onSWAvailable = this.onSWAvailable.bind(this);
    this.isRegistrationAvailable = this.isRegistrationAvailable.bind(this);
    this.isUpdateAvailable = this.isUpdateAvailable.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCheckUpdate = this.onCheckUpdate.bind(this);
    this.register = this.register.bind(this);

    this.register();
  }

  public componentDidMount(): void {
    const minuteToMs = (minute: number): number => minute * 60000;

    this.setState({
      interval: setInterval(this.onCheckUpdate, minuteToMs(1)),
    });
  }

  public componentWillUnmount(): void {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  private register(): void {
    serviceWorkerRegistration.register({
      onReady: this.onSWAvailable,
      onSuccess: this.onSWAvailable,
      onUpdate: this.onUpdateAvailable,
    });
  }

  private readonly timeoutForServiceWorkerToUpdate: number = 2500;

  private onUpdateAvailable(registration: ServiceWorkerRegistration): void {
    setTimeout(() => {
      // on very first load, registration of the service-worker can take some (waiting)time
      this.setState({
        registration: registration,
      });
    }, this.timeoutForServiceWorkerToUpdate);
  }

  private onSWAvailable(registration: ServiceWorkerRegistration): void {
    setTimeout(() => {
      // on very first load, registration of the service-worker can take some (waiting)time
      this.setState({
        registration: registration,
      });
    }, this.timeoutForServiceWorkerToUpdate);
  }

  private onUpdate(): void {
    if (this.state.registration?.waiting != null) {
      this.state.registration?.waiting.postMessage({ type: "SKIP_WAITING" });

      setTimeout(this.reload, this.timeoutForServiceWorkerToUpdate);
    }
  }

  private reload(): void {
    document.location.replace(document.location.origin + "/");
  }

  private onCheckUpdate(): void {
    if (this.state.registration != null) {
      this.state.registration.update();
    }
  }

  private isRegistrationAvailable(): boolean {
    return this.state.registration != null;
  }

  private isUpdateAvailable(): boolean {
    return this.state.registration?.waiting != null;
  }

  public render(): JSX.Element {
    return (
      <SystemNotificationBar
        isHidden={!this.isUpdateAvailable()}
        messagePriority={SystemNotificationPriority.medium}
        onClick={this.onUpdate}
      >
        <img className="mr-1" src={RefreshIcon} alt="refresh" />
        {LanguageProvider.t(TranslationMapper.global.messages.new_app_version)}
      </SystemNotificationBar>
    );
  }
}

export default ServiceWorker;
