import IUnitPrice from "interfaces/IUnitPrice";

export interface IUnitPriceStoreState {
  unitPrices?: IUnitPrice[];
  isLoading: boolean;
}

export const initialUnitPriceStoreState: IUnitPriceStoreState = {
  isLoading: false,
};
