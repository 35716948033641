import { AuthApiAuthenticator } from "authenticators/authApiAuthenticator";
import IChatMessage from "interfaces/IChatMessage";
import IChatDeletionCheckDetails from "pages/avgGdprManagement/interfaces/IChatDeletionCheckDetails";
import { ApplicationConfig } from "../config";
import { ChatType } from "../enums/chatType";
import IChatInfo from "../interfaces/IChatInfo";
import BaseClient from "./baseClient";

export default class ChatClient extends BaseClient {
  private readonly endpoint: string = "chat";

  public constructor() {
    super(ApplicationConfig.endpoints.chatApi, new AuthApiAuthenticator());
  }

  public async sendChatMessageAsync(
    topicId: string,
    contents: string,
    chatType: ChatType,
    attachment?: string
  ): Promise<void> {
    await this.post(`${this.endpoint}/send/servicedesk`, {
      topicId: topicId,
      contents: contents,
      chatType: chatType,
      attachment: attachment,
    });
  }

  public async getChatMessagesForTopicAsync(topicId: string, chatType: ChatType): Promise<IChatMessage[]> {
    let type = "enduser";
    if (chatType === ChatType.ExecutorServiceDeskChat) {
      type = "executor";
    }
    return this.get<IChatMessage[]>(`${this.endpoint}/servicedesk/${type}?topicId=${topicId}`);
  }

  public async getEndUsersWithChatInfoAsync(): Promise<IChatInfo[]> {
    return this.get<IChatInfo[]>(`${this.endpoint}/servicedesk/getEndusersWithChatInfo`);
  }

  public async getExecutorsWithChatInfoAsync(): Promise<IChatInfo[]> {
    return this.get<IChatInfo[]>(`${this.endpoint}/servicedesk/getExecutorsWithChatInfo`);
  }

  public async getChatAttachmentAsync(chatId: string): Promise<string> {
    return this.get<string>(`${this.endpoint}/getAttachment?chatId=${chatId}`);
  }

  public async getChatAttachmentServiceDeskAsync(chatId: string): Promise<string> {
    return this.get<string>(`${this.endpoint}/getAttachmentServiceDesk?chatId=${chatId}`);
  }

  public async getChatDeletionCheckDetailsAsync(endUserObjectId: string): Promise<IChatDeletionCheckDetails> {
    return this.postWithResponse<IChatDeletionCheckDetails>(
      `${this.endpoint}/chatDeletionCheck?endUserObjectId=${endUserObjectId}`,
      endUserObjectId
    );
  }

  public async deleteAllChatConversationsForUserAsync(endUserObjectId: string): Promise<Response> {
    return this.delete(`${this.endpoint}/deleteEndUserChatConversations?endUserObjectId=${endUserObjectId}`);
  }
}
