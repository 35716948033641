import IChatInfo from "../interfaces/IChatInfo";
import DateUtils from "../utils/dateUtils";

export default class ChatInfo implements IChatInfo {
  public fullName: string;
  public lastMessageSentDate?: Date;
  public lastMessageSender?: string;
  public id: string;

  public constructor(endUserChatInfo: IChatInfo) {
    // Make sure all properties are set the same as interface without risk of missing one on updated interface
    const properties = Object.getOwnPropertyNames(endUserChatInfo);
    properties.forEach((property) => {
      this[property] = endUserChatInfo[property];
    });

    // Only dates require extra logic
    if (endUserChatInfo.lastMessageSentDate) {
      this.lastMessageSentDate = DateUtils.utcToLocal(endUserChatInfo.lastMessageSentDate);
    }
  }
}
