import React, { Component } from "react";

import ISystemNotificationBarProps from "./interfaces/ISystemNotificationBarProps";

class SystemNotificationBar extends Component<ISystemNotificationBarProps> {
    public render(): JSX.Element {
        return (
            <div
                onClick={this.props.onClick}
                className={`g-system-notification ${this.props.messagePriority} ${this.props.isHidden ? "hidden" : "show"} ${this.props.className ?? ""}`}
                data-test={this.props["data-test"] ?? "g-system-notification-bar"}
            >
                <div className="message" onClick={this.props.onClick}>
                    {this.props.children}
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export default SystemNotificationBar;