import TranslationMapper from "i18n/mapper";
import moment, { Moment } from "moment";
import LanguageProvider from "providers/languageProvider";

export default class DateUtils {
  private static readonly defaultLocale: string = "nl";

  public static setDefaultLocaleGlobally(): void {
    moment.updateLocale(DateUtils.defaultLocale, {
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
    });
  }

  public static getTimeString(date: Date): string {
    return moment(date).format("H:mm");
  }

  public static getMonthName(date: Date): string {
    const monthLabels = [
      TranslationMapper.global.date_formats.months.january,
      TranslationMapper.global.date_formats.months.february,
      TranslationMapper.global.date_formats.months.march,
      TranslationMapper.global.date_formats.months.april,
      TranslationMapper.global.date_formats.months.may,
      TranslationMapper.global.date_formats.months.june,
      TranslationMapper.global.date_formats.months.july,
      TranslationMapper.global.date_formats.months.august,
      TranslationMapper.global.date_formats.months.september,
      TranslationMapper.global.date_formats.months.october,
      TranslationMapper.global.date_formats.months.november,
      TranslationMapper.global.date_formats.months.december,
    ];

    const monthLabel = monthLabels[date.getMonth()];

    return LanguageProvider.t(monthLabel);
  }

  public static getMonthShortName(date: Date): string {
    const monthLabels = [
      TranslationMapper.global.date_formats.months_shorthand.january,
      TranslationMapper.global.date_formats.months_shorthand.february,
      TranslationMapper.global.date_formats.months_shorthand.march,
      TranslationMapper.global.date_formats.months_shorthand.april,
      TranslationMapper.global.date_formats.months_shorthand.may,
      TranslationMapper.global.date_formats.months_shorthand.june,
      TranslationMapper.global.date_formats.months_shorthand.july,
      TranslationMapper.global.date_formats.months_shorthand.august,
      TranslationMapper.global.date_formats.months_shorthand.september,
      TranslationMapper.global.date_formats.months_shorthand.october,
      TranslationMapper.global.date_formats.months_shorthand.november,
      TranslationMapper.global.date_formats.months_shorthand.december,
    ];

    const monthLabel = monthLabels[date.getMonth()];

    return LanguageProvider.t(monthLabel);
  }

  public static getWeekDayName(date: Date): string {
    const dayLabels = [
      TranslationMapper.global.date_formats.weekdays.sunday,
      TranslationMapper.global.date_formats.weekdays.monday,
      TranslationMapper.global.date_formats.weekdays.tuesday,
      TranslationMapper.global.date_formats.weekdays.wednesday,
      TranslationMapper.global.date_formats.weekdays.thursday,
      TranslationMapper.global.date_formats.weekdays.friday,
      TranslationMapper.global.date_formats.weekdays.saturday,
    ];

    const dayLabel = dayLabels[date.getDay()];

    return LanguageProvider.t(dayLabel);
  }

  public static getFriendlyDateString(date: Date): string {
    const formatDate = (d: Moment | Date): string => moment(d).format("D");

    return `${formatDate(date)} ${this.getMonthShortName(date).toLowerCase()}`;
  }

  public static getFriendlyDateTimeStringWithYear(date: Date): string {
    const formatDate = (d: Moment | Date): string => moment(d).format("D");
    const formattedTime = this.getTimeString(date);

    return `${formatDate(date)} ${this.getMonthName(date).toLowerCase()} ${date.getFullYear()} ${formattedTime}`;
  }

  public static getFriendlyDateTimeString(date: Date | string): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    const formatDate = (d: Moment | Date): string => moment(d).format("D");
    const formattedTime = this.getTimeString(date);

    return `${formatDate(date)} ${this.getMonthName(date).toLowerCase()} ${formattedTime}`;
  }

  public static getTimeDifferenceInMinutes(start: Date, end: Date): number {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.asMinutes();
  }

  public static utcToLocal(date: string | Date): Date {
    return moment.utc(date).local().toDate();
  }
}
