import produce from "immer";
import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IAuthStoreState, initialAuthorizationStoreState } from "store/state/authorizationStoreState";

function authorizationReducer(
  state: IAuthStoreState = initialAuthorizationStoreState,
  action: ActionTypesCollection
): IAuthStoreState {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_AUTH_USER_DELETE:
      return produce(state, (draftState) => {
        draftState.user.isLoadingDelete = action.payload ?? false;
      });
    case ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_USERS:
      return produce(state, (draftState) => {
        draftState.user.isLoadingGetAllUsers = action.payload ?? false;
      });
    case ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_ROLES:
      return produce(state, (draftState) => {
        draftState.role.isLoadingGetAllRoles = action.payload ?? false;
      });
    case ActionTypes.UPDATE_LOADING_AUTH_USER_POST_USER:
      return produce(state, (draftState) => {
        draftState.user.isLoadingPostUser = action.payload ?? false;
      });
    case ActionTypes.FETCHED_AUTH_ALL_USERS:
      return produce(state, (draftState) => {
        draftState.user.users = action.payload ?? [];
      });
    case ActionTypes.FETCHED_AUTH_ALL_ROLES:
      return produce(state, (draftState) => {
        draftState.role.roles = action.payload ?? [];
      });
    default:
      return state;
  }
}

export default authorizationReducer;
