import { Component } from "react";
import { withRouter, NavLink as NavigationLink } from "react-router-dom";
import INavLinkProps from "./interfaces/INavLinkProps";

class NavLink extends Component<INavLinkProps> {

    private getIconClassName(isActive: boolean): string {
        return isActive ? "g-nav-icon-selected" : "g-nav-icon";
    }

    public render(): JSX.Element {
        const isActive = this.props.location.pathname.includes(this.props.routingPath);

        return (
            <NavigationLink
                exact to={this.props.routingPath}
                activeClassName="g-nav-link-selected"
                className="g-nav-link nav-link"
                data-test={this.props.routingPath.replace("/", "")}
            >
                <img src={this.props.image} className={this.getIconClassName(isActive)} alt={this.props.pageName} />
                {this.props.pageName}
            </NavigationLink>
        );
    }
}

export default withRouter(NavLink);
