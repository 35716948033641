import { cloneDeep } from "lodash";
import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import {
  IProjectWorkProgramStoreState,
  initialProjectWorkProgramStoreState,
} from "store/state/projectWorkProgramStoreState";

function workProgramReducer(
  state: IProjectWorkProgramStoreState = initialProjectWorkProgramStoreState,
  action: ActionTypesCollection
): IProjectWorkProgramStoreState {
  switch (action.type) {
    case ActionTypes.FETCH_WORK_PROGRAM: {
      const newState = cloneDeep(state);
      newState.projectWorkProgram = action.payload;
      return newState;
    }
    case ActionTypes.FETCH_ACTION_FREQUENCIES:
      return { ...state, actionFrequencies: [...action.payload] };
    case ActionTypes.WORK_PROGRAM_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default workProgramReducer;
