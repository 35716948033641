import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";

import IAttachmentViewerProps from "../interfaces/IAttachmentViewerProps";

export default class AttachmentViewer extends Component<IAttachmentViewerProps> {
    public render(): JSX.Element {
        const modalHook = document.getElementById("modal-hook");

        return (
            <>
                {modalHook &&
                    ReactDOM.createPortal(
                        <Modal
                            show={this.props.showImage}
                            onHide={this.props.onClose}
                            size="lg"
                            className="chat-attachment-viewer"
                            aria-labelledby="contained-modal-title-vcenter"
                            backdropClassName="chat-attachment-viewer-backdrop"
                            centered
                        >
                            <Modal.Body>
                                <div
                                    className="chat-attachment-viewer-close-button"
                                    id="chat-attachment-viewer-close-button"
                                    onClick={this.props.onClose}
                                />
                                <img src={this.props.imageUri} alt="received image" />
                            </Modal.Body>
                        </Modal>,
                        modalHook
                    )}
            </>
        );
    }
}
