import LanguageProvider from "providers/languageProvider";
import RoutingProvider from "providers/routingProvider";
import { Component } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import TranslationMapper from "../../i18n/mapper";
import Button from "../material/buttons/button";
import Page from "../material/page/page";
import ChatOverviewTableRow from "./chatOverviewTableRow";
import IChatOverviewProps from "./interfaces/IChatOverviewProps";
import IChatRowState from "./interfaces/IChatRowState";

class ChatOverview extends Component<IChatOverviewProps, IChatRowState> {
  public constructor(props: IChatOverviewProps) {
    super(props);

    const state: IChatRowState = {
      showChatInterface: false,
    };

    this.state = state;

    this.cleanUrl = this.cleanUrl.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    this.cleanUrl();
  }

  private cleanUrl(): void {
    // Ensure we always start with a clean url on mount, no residual chat query params after refresh for example
    if (this.props.location.pathname !== this.props.cleanPathName) {
      RoutingProvider.invokePushNewRoute(this.props.history, this.props.cleanPathName);
    }
  }

  public render(): JSX.Element {
    return (
      <Page className="g-chat-overview">
        <Button
          className="g-button-blue g-chat-overview-button"
          label={LanguageProvider.t(TranslationMapper.global.buttons.refresh)}
          onClick={this.props.onFetchChats}
          isDisabled={this.props.isLoading}
        />

        <Container className="g-chat-overview-container" fluid={true}>
          {this.props.chatInfo?.length > 0 && (
            <table className="g-chat-overview-table">
              <thead>
                <tr>
                  <th>{LanguageProvider.t(TranslationMapper.components.chats.chats_name)}</th>
                  <th>{LanguageProvider.t(TranslationMapper.components.chats.chats_date_last_message)}</th>
                  <th>{LanguageProvider.t(TranslationMapper.components.chats.chats_sender_last_message)}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.chatInfo.map((chat, index) => (
                  <ChatOverviewTableRow
                    key={index}
                    chatInfo={chat}
                    index={index}
                    cleanPathName={this.props.cleanPathName}
                    chatType={this.props.chatType}
                  />
                ))}
              </tbody>
            </table>
          )}

          {this.props.isLoading && (
            <div className="g-chat-overview-loading">
              {LanguageProvider.t(TranslationMapper.global.messages.loading_chat)}
            </div>
          )}

          {!this.props.isLoading && (!this.props.chatInfo || this.props.chatInfo?.length === 0) && (
            <div className="g-chat-overview-loading">
              {LanguageProvider.t(TranslationMapper.global.messages.no_chats)}
            </div>
          )}
        </Container>
      </Page>
    );
  }
}

export default withRouter(ChatOverview);
