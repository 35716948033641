import * as React from "react";

export default class ErrorHeader extends React.Component {
    public constructor(props: any) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div id="error-header">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="ml-auto">

                    </div>
                </nav>
            </div>
        );
    }
}
