import BasicPopup from "components/basicPopup/basicPopup";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

import IConfirmationPromptProps from "./interfaces/IConfirmationPromptProps";

export default class ConfirmationPrompt extends Component<IConfirmationPromptProps> {
  private get footerButtons(): JSX.Element {
    return (
      <div className="g-confirmation-prompt-buttons">
        <Button
          style={ButtonStyle.blue}
          onClick={this.props.onCancel}
          label={this.props.buttonLabelLeft ?? LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
        />
        <Button
          style={ButtonStyle.blue}
          onClick={this.props.onAgree}
          label={this.props.okText ?? LanguageProvider.t(TranslationMapper.global.buttons.ok)}
          isLoading={this.props.isAgreeLoading}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <BasicPopup
          onClose={this.props.onCancel}
          showPopup={this.props.showPrompt}
          headerText={
            this.props.headerText ?? LanguageProvider.t(TranslationMapper.global.messages.confirmation_message)
          }
          subheaderText={this.props.subheaderText}
          className={this.props.className ? `${this.props.className} g-confirmation-prompt` : "g-confirmation-prompt"}
          footerContent={this.footerButtons}
          bodyText={this.props.bodyText}
        >
          {this.props.children}
        </BasicPopup>
      </>
    );
  }
}
