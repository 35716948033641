import "./i18n/config";

import { UserAuthenticator } from "authenticators/userAuthenticator";
import MsalFactory from "factories/msalFactory";
import { TranslationMapper } from "i18n/mapper";
import ReactDOM from "react-dom";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "store/store";
import { ErrorCodes } from "utils/errorCodes";

import * as msal from "@azure/msal-browser";

import AccountService from "services/accountService";
import App from "./app";
import ErrorBoundary from "./error/components/errorBoundary/errorBoundary";
import ErrorDisplay from "./error/components/errorDisplay/errorDisplay";
import TelemetryProvider from "./providers/telemetryProvider";

MsalFactory.initialize();

const userAuthenticator = new UserAuthenticator();

userAuthenticator.authenticateUserAsync().then(
  async (user: msal.AccountInfo | null) => {
    if (user === null) {
      return;
    }

    if (user) {
      const service = new AccountService();
      await service.updateAuthProviderAllowedPaths();
      ReactDOM.render(
        <ErrorBoundary>
          <Router>
            <TelemetryProvider>
              <NotificationContainer />
              <Provider store={store}>
                <App />
              </Provider>
            </TelemetryProvider>
          </Router>
        </ErrorBoundary>,
        document.getElementById("root")
      );
    }
  },
  () => {
    ReactDOM.render(
      <Router>
        <ErrorDisplay
          errorMessage={TranslationMapper.global.errors.failed_login}
          errorCode={ErrorCodes.failedLogin}
          validateBrowser={false}
          showErrorHeader={false}
        />
      </Router>,
      document.getElementById("root")
    );
  }
);
