/* eslint-disable max-len */
export const TranslationMapper = {
  global: {
    navbar_titles: {
      enduser_chat: "global.navbar_titles.enduser_chat",
      executor_chat: "global.navbar_titles.executor_chat",
      waiters: "global.navbar_titles.waiters",
      active_regions: "global.navbar_titles.active_regions",
      offer_requests: "global.navbar_titles.offer_requests",
      account_registration_request: "global.navbar_titles.account_registration_request",
      avg_gdpr_management: "global.navbar_titles.avg_gdpr_management",
      work_program: "global.navbar_titles.work_program",
      notification: "global.navbar_titles.notification",
      unit_price: "global.navbar_titles.unit_price",
      auth: "global.navbar_titles.auth",
    },
    page_headers: {
      schedule: "global.page_headers.schedule",
      profile: "global.page_headers.profile",
    },
    messages: {
      new_app_version: "global.messages.new_app_version",
      loading_chat: "global.messages.loading_chat",
      no_chats: "global.messages.no_chats",
      confirmation_message: "global.messages.confirmation_message",
      loading_active_regions: "global.messages.loading_active_regions",
      send_postalcode_email_confirmation: "global.messages.send_postalcode_email_confirmation",
      loading_unit_price: "global.messages.loading_unit_price",
    },
    buttons: {
      logout: "global.buttons.logout",
      apply: "global.buttons.apply",
      cancel: "global.buttons.cancel",
      return: "global.buttons.return",
      delete: "global.buttons.delete",
      edit: "global.buttons.edit",
      create: "global.buttons.create",
      save: "global.buttons.save",
      next: "global.buttons.next",
      previous: "global.buttons.previous",
      close: "global.buttons.close",
      ok: "global.buttons.ok",
      loading: "global.buttons.loading",
      start_scan: "global.buttons.start_scan",
      start: "global.buttons.start",
      stop: "global.buttons.stop",
      refresh: "global.buttons.refresh",
      send: "global.buttons.send",
    },
    errors: {
      user_not_found: "global.errors.user_not_found",
      has_no_email: "global.errors.has_no_email",
      already_has_an_account: "global.errors.already_has_an_account",
      error_fetching_debtor_information: "global.errors.error_fetching_debtor_information",
      error_fetching_account_registration_requests: "global.errors.error_fetching_account_registration_requests",
      error_fetching_postal_code_request: "global.errors.error_fetching_postal_code_request",
      error_deleting_account_registration: "global.errors.error_deleting_account_registration",
      error_sending_email_id_empty: "global.errors.error_sending_email_id_empty",
      failed_login: "global.errors.failed_login",
      general: "global.errors.general",
      browser_specific_error: "global.errors.browser_specific_error",
      reload_home: "global.errors.reload_home",
      uncaught_exception_title: "global.errors.uncaught_exception_title",
      invalid_nfc: "global.errors.invalid_nfc",
      no_native_shell_detected: "global.errors.no_native_shell_detected",
      fetch_schedule_error: "global.errors.fetch_schedule_error",
      fetch_chat_error: "global.errors.fetch_chat_error",
      send_chat_error: "global.errors.send_chat_error",
      error_fetch_profile: "global.errors.error_fetch_profile",
      error_fetch_activity_executor_profile: "global.errors.error_fetch_activity_executor_profile",
      error_required_fields: "global.errors.error_required_fields",
      error_invalid_email: "global.errors.error_invalid_email",
      error_sending_activation_email: "global.errors.error_sending_activation_email",
      error_fetching_active_regions: "global.errors.error_fetching_active_regions",
      error_deleting_active_region: "global.errors.error_deleting_active_region",
      error_updating_active_regions: "global.errors.error_updating_active_regions",
      error_fetch_service_categories: "global.errors.error_fetch_service_categories",
      no_waiter_selected: "global.errors.no_waiter_selected",
      no_action_description: "global.errors.no_action_description",
      action_already_exists: "global.errors.action_already_exists",
      error_upserting_unit_price: "global.errors.error_upserting_unit_price",
      error_deleting_unit_price: "global.errors.error_deleting_unit_price",
      error_fetching_unit_prices: "global.errors.error_fetching_unit_prices",
    },
    success: {
      success_sending_activation_email: "global.success.success_sending_activation_email",
      succces_adding_new_active_region: "global.success.succces_adding_new_active_region",
      succces_deleting_active_region: "global.success.succces_deleting_active_region",
      success_deleting_unit_price: "global.success.success_deleting_unit_price",
      success_upserting_unit_price: "global.success.success_upserting_unit_price",
    },
    date_formats: {
      today: "global.date_formats.today",
      tomorrow: "global.date_formats.tomorrow",
      weekdays: {
        mon: "global.date_formats.weekdays.mon",
        tue: "global.date_formats.weekdays.tue",
        wed: "global.date_formats.weekdays.wed",
        thu: "global.date_formats.weekdays.thu",
        fri: "global.date_formats.weekdays.fri",
        sat: "global.date_formats.weekdays.sat",
        sun: "global.date_formats.weekdays.sun",
        monday: "global.date_formats.weekdays.monday",
        tuesday: "global.date_formats.weekdays.tuesday",
        wednesday: "global.date_formats.weekdays.wednesday",
        thursday: "global.date_formats.weekdays.thursday",
        friday: "global.date_formats.weekdays.friday",
        saturday: "global.date_formats.weekdays.saturday",
        sunday: "global.date_formats.weekdays.sunday",
      },
      time_unit: {
        minute: "global.date_formats.time_unit.minute",
        hour: "global.date_formats.time_unit.hour",
        day: "global.date_formats.time_unit.day",
        week: "global.date_formats.time_unit.week",
        month: "global.date_formats.time_unit.month",
        year: "global.date_formats.time_unit.year",
      },
      months: {
        january: "global.date_formats.months.january",
        february: "global.date_formats.months.february",
        march: "global.date_formats.months.march",
        april: "global.date_formats.months.april",
        may: "global.date_formats.months.may",
        june: "global.date_formats.months.june",
        july: "global.date_formats.months.july",
        august: "global.date_formats.months.august",
        september: "global.date_formats.months.september",
        october: "global.date_formats.months.october",
        november: "global.date_formats.months.november",
        december: "global.date_formats.months.december",
      },
      months_shorthand: {
        january: "global.date_formats.months_shorthand.january",
        february: "global.date_formats.months_shorthand.february",
        march: "global.date_formats.months_shorthand.march",
        april: "global.date_formats.months_shorthand.april",
        may: "global.date_formats.months_shorthand.may",
        june: "global.date_formats.months_shorthand.june",
        july: "global.date_formats.months_shorthand.july",
        august: "global.date_formats.months_shorthand.august",
        september: "global.date_formats.months_shorthand.september",
        october: "global.date_formats.months_shorthand.october",
        november: "global.date_formats.months_shorthand.november",
        december: "global.date_formats.months_shorthand.december",
      },
    },
  },
  components: {
    chats: {
      chats_name: "components.chats.chats_name",
      chats_date_last_message: "components.chats.chats_date_last_message",
      chats_sender_last_message: "components.chats.chats_sender_last_message",
      chat_header: "components.chats.chat_header",
      message_placeholder: "components.chats.message_placeholder",
      error_fetching_attachment: "components.chats.error_fetching_attachment",
      error_image_size: "components.chats.error_image_size",
      error_image_file: "components.chats.error_image_file",
    },
    activation_email: {
      activation_email_header: "components.activation_email.activation_email_header",
      activation_email_firstname: "components.activation_email.activation_email_firstname",
      activation_email_prefix: "components.activation_email.activation_email_prefix",
      activation_email_surname: "components.activation_email.activation_email_surname",
      activation_email_debtornumber: "components.activation_email.activation_email_debtornumber",
      activation_email_email: "components.activation_email.activation_email_email",
    },
    active_regions: {
      active_regions_header: "components.active_regions.active_regions_header",
      active_regions_region: "components.active_regions.active_regions_region",
      active_regions_cost_center: "components.active_regions.active_regions_cost_center",
      active_regions_confirm_delete: "components.active_regions.active_regions_confirm_delete",
      active_regions_confirm_add: "components.active_regions.active_regions_confirm_add",
      new_region_header: "components.active_regions.new_region_header",
      new_region_range_begin: "components.active_regions.new_region_range_begin",
      new_region_range_end: "components.active_regions.new_region_range_end",
      new_region_cost_center: "components.active_regions.new_region_cost_center",
      error_invalid_new_region: "components.active_regions.error_invalid_new_region",
    },
    labels: {
      column_name: "components.labels.column_name",
      column_service: "components.labels.column_service",
      column_request_date: "components.labels.column_request_date",
      column_offer_rate: "components.labels.column_offer_rate",
      column_status: "components.labels.column_status",
      column_zipcode: "components.labels.column_zipcode",
      column_housenumber: "components.labels.column_housenumber",
      column_housenumber_addition: "components.labels.column_housenumber_addition",
      column_external_debtor_id: "components.labels.column_external_debtor_id",
      column_total_costs: "components.labels.column_total_costs",
      column_postalcode: "components.labels.column_postalcode",
      column_zizocode: "components.labels.column_zizocode",
      column_city: "components.labels.column_city",
      column_hours_per_period: "components.labels.column_hours_per_period",
      column_upn: "components.labels.column_upn",
      column_role: "components.labels.column_role",
      label_email: "components.labels.label_email",
      label_offer_owner: "components.labels.label_offer_owner",
      label_invitation_owner: "components.labels.label_invitation_owner",
      label_offer_send_date: "components.labels.label_offer_send_date",
      label_invitation_send_date: "components.labels.label_invitation_send_date",
      label_offer_completed_on: "components.labels.label_offer_completed_on",
      label_invitation_completed_on: "components.labels.label_invitation_completed_on",
      label_offer_price: "components.labels.label_offer_price",
      label_selected_rows: "components.labels.label_selected_rows",
      label_select_all_rows: "components.labels.label_select_all_rows",
      label_sort: "components.labels.label_sort",
      send_update_button: "components.labels.send_update_button",
      send_invitation: "components.labels.send_invitation",
      add_registration_request: "components.labels.add_registration_request",
      debtor_number_from_afas: "components.labels.debtor_number_from_afas",
      check_debtor_number: "components.labels.check_debtor_number",
      check_debtor_number_checking: "components.labels.check_debtor_number_checking",
      check_debtor_number_inserting: "components.labels.check_debtor_number_inserting",
      check_debtor_number_error_message: "components.labels.check_debtor_number_error_message",
      upsert_account_offer_succes: "components.labels.upsert_account_offer_succes",
      upsert_account_offer_fail: "components.labels.upsert_account_offer_fail",
      upsert_account_registration_request_success: "components.labels.upsert_account_registration_request_success",
      upsert_account_registration_request_fail: "components.labels.upsert_account_registration_request_fail",
      pagination_label_no_result: "components.labels.pagination_label_no_result",
      pagination_label_number_of_pages: "components.labels.pagination_label_number_of_pages",
      pagination_total: "components.labels.pagination_total",
      number_of_roomtype: "components.labels.number_of_roomtype",
      description: "components.labels.description",
    },
  },
  pages: {
    offer_request_management: {
      request_status_0: "pages.offer_request_management.request_status_0",
      request_status_1: "pages.offer_request_management.request_status_1",
      request_status_2: "pages.offer_request_management.request_status_2",
      request_status_3: "pages.offer_request_management.request_status_3",
      request_status_4: "pages.offer_request_management.request_status_4",
      request_status_5: "pages.offer_request_management.request_status_5",
      request_status_invalid: "pages.offer_request_management.request_status_invalid",
      error_fetch_offer_requests: "pages.offer_request_management.error_fetch_offer_requests",
      error_send_update: "pages.offer_request_management.error_send_update",
      status_update_error: "pages.offer_request_management.status_update_error",
      error_delete: "pages.offer_request_management.error_delete",
      error_postalcode_delete: "pages.offer_request_management.error_postalcode_delete",
      update_success: "pages.offer_request_management.update_success",
      delete_success: "pages.offer_request_management.delete_success",
      postalcode_delete_success: "pages.offer_request_management.postalcode_delete_success",
      status_update_success: "pages.offer_request_management.status_update_success",
      send_email_success: "pages.offer_request_management.send_email_success",
      delete_account_registration_success: "pages.offer_request_management.delete_account_registration_success",
      error_duplicate_account_registration: "pages.offer_request_management.error_duplicate_account_registration",
      category_filter_placeholder: "pages.offer_request_management.category_filter_placeholder",
      status_filter_placeholder: "pages.offer_request_management.status_filter_placeholder",
      service_filter_placeholder: "pages.offer_request_management.service_filter_placeholder",
      postalcode_from_filter_placeholder: "pages.offer_request_management.postalcode_from_filter_placeholder",
      postalcode_till_filter_placeholder: "pages.offer_request_management.postalcode_till_filter_placeholder",
      request_date_filter_from_placeholder: "pages.offer_request_management.request_date_filter_from_placeholder",
      request_date_filter_till_placeholder: "pages.offer_request_management.request_date_filter_till_placeholder",
      label_additional_offer_information: "pages.offer_request_management.label_additional_offer_information",
      label_zip_code: "pages.offer_request_management.label_zip_code",
      label_phone: "pages.offer_request_management.label_phone",
      label_house_number: "pages.offer_request_management.label_house_number",
      label_house_number_addition: "pages.offer_request_management.label_house_number_addition",
    },
    avg_gdpr_management: {
      page_title: "pages.avg_gdpr_management.page_title",
      delete_chat_description: "pages.avg_gdpr_management.delete_chat_description",
      chat_title: "pages.avg_gdpr_management.chat_title",
      delete_chat_button: "pages.avg_gdpr_management.delete_chat_button",
      enduser_input_placeholder: "pages.avg_gdpr_management.enduser_input_placeholder",
      delete_chat_warning: "pages.avg_gdpr_management.delete_chat_warning",
      error_fetch_delete_details: "pages.avg_gdpr_management.error_fetch_delete_details",
      error_delete_chats: "pages.avg_gdpr_management.error_delete_chats",
      success_chats_deleted: "pages.avg_gdpr_management.success_chats_deleted",
      number_of_chats: "pages.avg_gdpr_management.number_of_chats",
      enduser_name: "pages.avg_gdpr_management.enduser_name",
      invalid_guid: "pages.avg_gdpr_management.invalid_guid",
      unknown_user: "pages.avg_gdpr_management.unknown_user",
    },
    workprogram: {
      page_title: "pages.workprogram.page_title",
      basic_actions: "pages.workprogram.basic_actions",
      periodic_actions: "pages.workprogram.periodic_actions",
      page_description: "pages.workprogram.page_description",
      standard_workprogram: "pages.workprogram.standard_workprogram",
      standard_workprogram_description: "pages.workprogram.standard_workprogram_description",
      specific_workprogram: "pages.workprogram.specific_workprogram",
      specific_workprogram_description: "pages.workprogram.specific_workprogram_description",
      project_id_placeholder: "pages.workprogram.project_id_placeholder",
      living_room: "pages.workprogram.living_room",
      workprogram_loading: "pages.workprogram.workprogram_loading",
      no_workprogram_found: "pages.workprogram.no_workprogram_found",
      no_project_found: "pages.workprogram.no_project_found",
      project_found: "pages.workprogram.project_found",
      workprogram_project: "pages.workprogram.workprogram_project",
      bath_room: "pages.workprogram.bath_room",
      entrance: "pages.workprogram.entrance",
      kitchen: "pages.workprogram.kitchen",
      landing: "pages.workprogram.landing",
      bed_room: "pages.workprogram.bed_room",
      other_rooms: "pages.workprogram.other_rooms",
      toilet: "pages.workprogram.toilet",
      add_room: "pages.workprogram.add_room",
      add_action: "pages.workprogram.add_action",
      roomtype_already_exists: "pages.workprogram.roomtype_already_exists",
      roomtype: "pages.workprogram.roomtype",
    },
    unit_prices: {
      unit_prices_confirm_delete: "pages.unit_prices.unit_prices_confirm_delete",
      unit_prices_confirm_add: "pages.unit_prices.unit_prices_confirm_add",
      error_invalid_new_unit_price: "pages.unit_prices.error_invalid_new_unit_price",
      error_deleting_unit_price: "pages.unit_prices.error_deleting_unit_price",
      unit_price_header: "pages.unit_prices.unit_price_header",
      unit_price_code: "pages.unit_prices.unit_price_code",
      unit_price_cost: "pages.unit_prices.unit_price_cost",
      unit_price_start_date: "pages.unit_prices.unit_price_start_date",
      add_or_update_unit_price_header: "pages.unit_prices.add_or_update_unit_price_header",
      unit_price_start_date_filter_placeholder: "pages.unit_prices.unit_price_start_date_filter_placeholder",
    },
    home: {
      header: "pages.home.header",
      subheader: "pages.home.subheader",
    },
    auth: {
      user: {
        messages: {
          error: {
            admin_last: "pages.auth.user.messages.error.admin_last",
            delete: "pages.auth.user.messages.error.delete",
            edit_user: "pages.auth.user.messages.error.edit_user",
            list_all_roles: "pages.auth.user.messages.error.list_all_roles",
            list_all_users: "pages.auth.user.messages.error.list_all_users",
          },
          success: {
            delete: "pages.auth.user.messages.success.delete",
          },
        },
        edit: {
          title: "pages.auth.user.edit.title",
          header: "pages.auth.user.edit.header",
          results_none: "pages.auth.user.edit.results_none",
          checkbox: {
            admin_delete_not_allowed: "pages.auth.user.edit.checkbox.admin_delete_not_allowed",
          },
        },
        delete: {
          header: "pages.auth.user.delete.header",
        },
        filter: {
          label: {
            role: "pages.auth.user.filter.label.role",
          },
          placeholder: {
            role: "pages.auth.user.filter.placeholder.role",
          },
        },
      },
      unauthorized: {
        title: "pages.auth.unauthorized.title",
        message: "pages.auth.unauthorized.message",
      },
    },
    notification: {
      upsert: {
        header: "pages.notification.upsert.header",
        create_new: "pages.notification.upsert.create_new",
        labels: {
          end_date: "pages.notification.upsert.labels.end_date",
          message: "pages.notification.upsert.labels.message",
          reset_viewed_by: "pages.notification.upsert.labels.reset_viewed_by",
          start_date: "pages.notification.upsert.labels.start_date",
          title: "pages.notification.upsert.labels.title",
          url: "pages.notification.upsert.labels.url",
        },
        form_text: {
          end_date: "pages.notification.upsert.form_text.end_date",
          message: "pages.notification.upsert.form_text.message",
          title: "pages.notification.upsert.form_text.title",
          url: "pages.notification.upsert.form_text.url",
        },
      },
      delete: {
        header: "pages.notification.delete.header",
      },
      messages: {
        error: {
          delete_notification: "pages.notification.messages.error.delete_notification",
          invalid_date_overlap: "pages.notification.messages.error.invalid_date_overlap",
          invalid_end_date: "pages.notification.messages.error.invalid_end_date",
          invalid_url: "pages.notification.messages.error.invalid_url",
          invalid_values: "pages.notification.messages.error.invalid_values",
          list_all_notifications: "pages.notification.messages.error.list_all_notifications",
          upsert_notification: "pages.notification.messages.error.upsert_notification",
        },
        success: {
          delete_notification: "pages.notification.messages.success.delete_notification",
          upsert_notification: "pages.notification.messages.success.upsert_notification",
        },
      },
      modal: {
        upsert: {
          title: {
            create: "pages.notification.modal.upsert.title.create",
            edit: "pages.notification.modal.upsert.title.edit",
          },
        },
        upsert_confirm: {
          title: {
            create: "pages.notification.modal.upsert_confirm.title.create",
            edit: "pages.notification.modal.upsert_confirm.title.edit",
          },
          body: {
            end_date: "pages.notification.modal.upsert_confirm.body.end_date",
            message: "pages.notification.modal.upsert_confirm.body.message",
            start_date: "pages.notification.modal.upsert_confirm.body.start_date",
          },
        },
      },
    },
  },
};

export default TranslationMapper;
