import IChatMessage from "../interfaces/IChatMessage";
import DateUtils from "../utils/dateUtils";

export default class ChatMessage implements IChatMessage {
  public chatId: string;
  public senderId: string;
  public topicId: string;
  public senderName: string;
  public chatType: number;
  public createdOn: Date;
  public contents?: string;
  public attachmentUri?: string;
  public attachmentSasUri?: string;
  public sasTokenExpirationDate?: Date;

  public constructor(chatMessage: IChatMessage) {
    // Make sure all properties are set the same as interface without risk of missing one on updated interface
    const properties = Object.getOwnPropertyNames(chatMessage);
    properties.forEach((property) => {
      this[property] = chatMessage[property];
    });

    // Only dates require extra logic
    this.createdOn = DateUtils.utcToLocal(chatMessage.createdOn);
  }
}
