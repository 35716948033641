import * as msal from "@azure/msal-browser";

export const zizoPolicies: IMsalPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_signUpSignInB2CScope || "",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_B2C_authority}.b2clogin.com/${process.env.REACT_APP_B2C_authority}.onmicrosoft.com/${process.env.REACT_APP_signUpSignInB2CScope}`,
      scopes: [],
    },
  },
  authorityDomain: `${process.env.REACT_APP_B2C_authority}.b2clogin.com`,
};

export const AuthenticationConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_clientId || "",
    authority: zizoPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [zizoPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_rootUrl,
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export const ApplicationConfig: IApplicationConfig = {
  endpoints: {
    chatApi: process.env.REACT_APP_endpoints_chatApi || "",
    accountApi: process.env.REACT_APP_endpoints_accountApi || "",
    managementApi: process.env.REACT_APP_endpoints_managementApi || "",
    authApi: process.env.REACT_APP_endpoints_authApi || "",
  },
  scopes: {
    authApi: [process.env.REACT_APP_scopes_authApi || ""],
  },
  environment: process.env.REACT_APP_environment || "",
};

interface IMsalPolicies {
  names: {
    signUpSignIn: string;
  };
  authorities: {
    signUpSignIn: msal.RedirectRequest;
  };
  authorityDomain: string;
}

interface IApplicationConfig {
  endpoints: {
    [key: string]: string;
  };
  scopes: {
    [key: string]: string[];
  };
  environment: string;
}
