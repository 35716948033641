import RoutingProvider from "providers/routingProvider";
import { Component } from "react";
import DateUtils from "../../utils/dateUtils";
import IChatRowState from "./interfaces/IChatRowState";
import IChatOverviewTableRowProps, { IChatOverviewTableRowDispatchProps, IChatOverviewTableRowStateProps } from "./interfaces/IChatOverviewTableRowProps";
import ChatInterface from "components/chatInterface/chatInterface";
import { RootState } from "store/reducers/rootReducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setTopicId } from "store/actions/chatActions";

class ChatOverviewTableRow extends Component<IChatOverviewTableRowProps, IChatRowState> {
    public constructor(props: IChatOverviewTableRowProps) {
        super(props);

        const state: IChatRowState = {
            showChatInterface: false,
        };

        this.state = state;

        this.toggleModal = this.toggleModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    private toggleModal(): void {
        this.setState({
            showChatInterface: !this.state.showChatInterface
        });
    }

    private openModal(): void {
        const navPath = `${this.props.cleanPathName}/chat?topic=${this.props.chatInfo.id}`;
        RoutingProvider.invokePushNewRoute(this.props.history, navPath);
        this.props.onOpenChat(this.props.chatInfo.id);
        this.toggleModal();
    }

    private closeModal(): void {
        RoutingProvider.invokePushNewRoute(this.props.history, this.props.cleanPathName);

        this.props.onOpenChat(undefined);

        this.toggleModal();
    }

    public render(): JSX.Element {
        return (
            <>
                <ChatInterface
                    topicId={this.props.chatInfo.id}
                    showChatInterface={this.state.showChatInterface}
                    cleanPathName={this.props.cleanPathName}
                    chatType={this.props.chatType}
                    onClose={this.closeModal}
                />

                <tr key={this.props.index} onClick={this.openModal}>
                    <td>{this.props.chatInfo.fullName}</td>
                    <td>{this.props.chatInfo.lastMessageSentDate != null ? DateUtils.getFriendlyDateTimeString(this.props.chatInfo.lastMessageSentDate) : ""}</td>
                    <td>{this.props.chatInfo.lastMessageSender}</td>
                </tr>
            </>
        );
    }
}

const mapStateToProps = (state: RootState): IChatOverviewTableRowStateProps => ({
    isLoading: state.chatState.isLoading,
    onOpenChat: setTopicId
});

const mapDispatchToProps: IChatOverviewTableRowDispatchProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChatOverviewTableRow));
