import INotification from "interfaces/INotification";

export interface INotificationStoreState {
  isLoadingDeleteNotification: boolean;
  isLoadingGetAllNotifications: boolean;
  isLoadingUpsertNotification: boolean;
  notifications: INotification[];
}

export const initialNotificationStoreState: INotificationStoreState = {
  isLoadingDeleteNotification: false,
  isLoadingGetAllNotifications: false,
  isLoadingUpsertNotification: false,
  notifications: [],
};
