/* eslint-disable @typescript-eslint/explicit-function-return-type */
import TranslationMapper from "i18n/mapper";
import IAuthUser, { IAuthRole, IAuthUpsertUser as IAuthUpdateUserRoles } from "interfaces/IAuth";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import AuthService from "services/authService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { AppAction, actionCreator } from "store/appAction";

export const setAuthUserLoadingDelete = actionCreator<ActionTypes.UPDATE_LOADING_AUTH_USER_DELETE, boolean>(
  ActionTypes.UPDATE_LOADING_AUTH_USER_DELETE
);

export const setAuthUserLoadingGetAllUsers = actionCreator<ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_USERS, boolean>(
  ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_USERS
);

export const setAuthUserLoadingGetAllRoles = actionCreator<ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_ROLES, boolean>(
  ActionTypes.UPDATE_LOADING_AUTH_USER_GET_ALL_ROLES
);

export const setAuthUserLoadingPostUser = actionCreator<ActionTypes.UPDATE_LOADING_AUTH_USER_POST_USER, boolean>(
  ActionTypes.UPDATE_LOADING_AUTH_USER_POST_USER
);

export const fetchedAuthAllUsers = actionCreator<ActionTypes.FETCHED_AUTH_ALL_USERS, IAuthUser[]>(
  ActionTypes.FETCHED_AUTH_ALL_USERS
);

export const fetchedAuthAllRoles = actionCreator<ActionTypes.FETCHED_AUTH_ALL_ROLES, IAuthRole[]>(
  ActionTypes.FETCHED_AUTH_ALL_ROLES
);

export function toggleLoadingDeleteUserStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setAuthUserLoadingDelete(isLoading));
  };
}

export function toggleLoadingGetAllUsersStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setAuthUserLoadingGetAllUsers(isLoading));
  };
}

export function toggleLoadingGetAllRolesStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setAuthUserLoadingGetAllRoles(isLoading));
  };
}

export function toggleLoadingPostUserStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setAuthUserLoadingPostUser(isLoading));
  };
}

export function deleteUser(id: string, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const authService = new AuthService();
    dispatch(toggleLoadingDeleteUserStatus(true));
    authService
      .deleteUserAsync(id)
      .then(() => {
        NotificationManager.success(LanguageProvider.t(TranslationMapper.pages.auth.user.messages.success.delete));
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AuthUserActions.deleteAuthUser",
            message: `Error on delete user request '${id}', error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.delete));
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingDeleteUserStatus(false)));
  };
}

export function getListAllUsers(onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const authService = new AuthService();
    dispatch(toggleLoadingGetAllUsersStatus(true));
    authService
      .getListAllUsers()
      .then((allUsers) => {
        dispatch(fetchedAuthAllUsers(allUsers));
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AuthUserActions.getListAllUsers",
            message: `Error on get list all users request, error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.list_all_users));
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingGetAllUsersStatus(false)));
  };
}

export function getListAllRoles(): AppAction {
  return (dispatch): void => {
    const authService = new AuthService();
    dispatch(toggleLoadingGetAllRolesStatus(true));
    authService
      .getListAllRoles()
      .then((allRoles) => {
        dispatch(fetchedAuthAllRoles(allRoles));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AuthUserActions.getListAllUsers",
            message: `Error on get list all roles request, error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.list_all_roles));
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingGetAllRolesStatus(false)));
  };
}

export function putUpdateUserRoles(data: IAuthUpdateUserRoles, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const authService = new AuthService();
    dispatch(toggleLoadingPostUserStatus(true));
    authService
      .putUpdateUserRoles(data)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AuthUserActions.getListAllUsers",
            message: `Error on upsert user request, error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.edit_user));
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingPostUserStatus(false)));
  };
}
