import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import WorkProgram from "components/workProgram/workProgram";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  createProjectWorkProgram,
  fetchActionFrequencies,
  fetchWorkProgram,
  resetProjectWorkProgram,
} from "store/actions/projectWorkProgramActions";
import { RootState } from "store/reducers/rootReducer";
import { isNullOrEmpty } from "utils/stringUtils";
import IProjectWorkProgramProps, {
  IProjectWorkProgramDispatchProps,
  IProjectWorkProgramStateProps,
} from "./interfaces/IProjectWorkProgramProps";
import IProjectWorkProgramState from "./interfaces/IProjectWorkProgramState";

class ProjectWorkProgram extends Component<IProjectWorkProgramProps, IProjectWorkProgramState> {
  public constructor(props: IProjectWorkProgramProps) {
    super(props);

    const state: IProjectWorkProgramState = {
      isStandardActive: false,
      isSpecificActive: false,
    };

    this.onSetStandardActive = this.onSetStandardActive.bind(this);
    this.onSetSpecificActive = this.onSetSpecificActive.bind(this);
    this.onExternalProjectIdChange = this.onExternalProjectIdChange.bind(this);
    this.onFetchWorkProgram = this.onFetchWorkProgram.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onCreateWorkProgram = this.onCreateWorkProgram.bind(this);

    this.state = state;
  }

  public async componentDidMount(): Promise<void> {
    this.props.onFetchActionFrequencies();
  }

  private onSetStandardActive(): void {
    if (!this.state.isStandardActive) {
      this.setState({
        isStandardActive: true,
        isSpecificActive: false,
      });
      this.props.onResetProjectWorkProgram();
      this.props.onFetchWorkProgram(undefined);
    }
  }

  private onSetSpecificActive(): void {
    if (!this.state.isSpecificActive) {
      this.setState({
        isSpecificActive: true,
        isStandardActive: false,
      });
      this.props.onResetProjectWorkProgram();
    }
  }

  private onExternalProjectIdChange(event: ChangeEvent<HTMLInputElement>): void {
    if (this.props.projectWorkProgram != null) {
      this.props.onResetProjectWorkProgram();
    }

    const value = event.target.value;
    this.setState({
      externalProjectId: value,
    });
  }

  private handleKeyPress(event: { key: string }): void {
    if (event.key === "Enter") {
      this.onFetchWorkProgram();
    }
  }

  private onFetchWorkProgram(): void {
    if (!isNullOrEmpty(this.state.externalProjectId)) {
      this.props.onFetchWorkProgram(this.state.externalProjectId);
    }
  }

  private onCreateWorkProgram(): void {
    if (this.state.externalProjectId != null) {
      this.props.onCreateProjectWorkProgram(this.state.externalProjectId);
    }
  }

  public render(): JSX.Element {
    return (
      <Page className="workprogram-page">
        <h1>{LanguageProvider.t(TranslationMapper.pages.workprogram.page_title)}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: LanguageProvider.t(TranslationMapper.pages.workprogram.page_description) }}
        />
        <div className="flex-group my-4">
          <div
            className={`workprogram-button ${this.state.isStandardActive ? "active" : ""}`}
            onClick={this.onSetStandardActive}
          >
            <p className="work-program-button-title">
              {LanguageProvider.t(TranslationMapper.pages.workprogram.standard_workprogram)}
            </p>
            <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.standard_workprogram_description)}</p>
          </div>
          <div
            className={`workprogram-button ${this.state.isSpecificActive ? "active" : ""}`}
            onClick={this.onSetSpecificActive}
          >
            <p className="work-program-button-title">
              {LanguageProvider.t(TranslationMapper.pages.workprogram.specific_workprogram)}
            </p>
            <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.specific_workprogram_description)}</p>
          </div>
          {this.props.isLoading && (
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          )}
        </div>
        {this.state.isSpecificActive && (
          <div className="flex-group my-4">
            <Form.Control
              type="text"
              value={this.state.externalProjectId ?? ""}
              onKeyDown={this.handleKeyPress}
              className="project-id-input"
              placeholder={LanguageProvider.t(TranslationMapper.pages.workprogram.project_id_placeholder)}
              onChange={this.onExternalProjectIdChange}
            />
            <Button
              label={LanguageProvider.t(TranslationMapper.global.buttons.apply)}
              onClick={this.onFetchWorkProgram}
              isDisabled={isNullOrEmpty(this.state.externalProjectId)}
            />
          </div>
        )}
        {this.props.projectWorkProgram != null && (this.state.isSpecificActive || this.state.isStandardActive) && (
          <>
            {this.props.projectWorkProgram.workProgramFound && (
              <WorkProgram workProgram={this.props.projectWorkProgram} frequencies={this.props.actionFrequencies} />
            )}
            {!this.props.projectWorkProgram.workProgramFound && (
              <>
                <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.no_workprogram_found)}</p>
                {!this.props.projectWorkProgram.projectFound && (
                  <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.no_project_found)}</p>
                )}
                {this.props.projectWorkProgram.projectFound && (
                  <div>
                    <p>{LanguageProvider.t(TranslationMapper.pages.workprogram.project_found)}</p>
                    <Button
                      label={LanguageProvider.t(TranslationMapper.global.buttons.create)}
                      style={ButtonStyle.blueOutline}
                      onClick={this.onCreateWorkProgram}
                      isDisabled={this.props.isLoading}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IProjectWorkProgramStateProps => ({
  isLoading: state.projectWorkProgramState.isLoading,
  projectWorkProgram: state.projectWorkProgramState.projectWorkProgram ?? undefined,
  actionFrequencies: state.projectWorkProgramState.actionFrequencies,
});

const mapDispatchToProps: IProjectWorkProgramDispatchProps = {
  onFetchWorkProgram: fetchWorkProgram,
  onFetchActionFrequencies: fetchActionFrequencies,
  onResetProjectWorkProgram: resetProjectWorkProgram,
  onCreateProjectWorkProgram: createProjectWorkProgram,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectWorkProgram);
