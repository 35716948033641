import { AuthByRole, UserRole } from "enums/userRole";
import { IAuthByRoleGroup, IAuthByRoleGroupValues } from "interfaces/IAuth";
import { RoutingPath } from "./routingProvider";

type tokenTime = {
  exp: number | null;
  iat: number | null;
  diff: number | null;
  user: number | null;
};

type tokenInfo = {
  token: string;
  time: tokenTime;
};

type token = {
  auth: tokenInfo;
  b2c: tokenInfo;
};

export default class AuthProvider {
  // used for path checking in app.tsx
  private static allowedUserPaths: string[] = [];

  public static readonly authByRoleGroup: IAuthByRoleGroup = {
    roles: [
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.TeamLeader,
          UserRole.HrSpecialist,
          UserRole.BackOffice,
        ],
        isAccessAllowed: false,
        name: AuthByRole.HomePageRead,
        uri: RoutingPath.home,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.TeamLeader,
          UserRole.BackOffice,
        ],
        isAccessAllowed: false,
        name: AuthByRole.ChatEndUserReadWrite,
        uri: RoutingPath.chatEndUser,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.TeamLeader,
          UserRole.BackOffice,
        ],
        isAccessAllowed: false,
        name: AuthByRole.ChatEndUserReadWrite,
        uri: RoutingPath.chatEndUserDetails,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.TeamLeader,
        ],
        isAccessAllowed: false,
        name: AuthByRole.ChatExecutorReadWrite,
        uri: RoutingPath.chatExecutor,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.TeamLeader,
        ],
        isAccessAllowed: false,
        name: AuthByRole.ChatExecutorReadWrite,
        uri: RoutingPath.chatExecutorDetails,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations, UserRole.Planner, UserRole.BackOffice],
        isAccessAllowed: false,
        name: AuthByRole.PostalCodeRequestsReadWrite,
        uri: RoutingPath.postalCodeRequests,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.TeamLeader,
          UserRole.HrSpecialist,
        ],
        isAccessAllowed: false,
        name: AuthByRole.ActiveRegionsRead,
        uri: RoutingPath.activeRegions,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations, UserRole.Planner, UserRole.BackOffice],
        isAccessAllowed: false,
        name: AuthByRole.ActiveRegionsReadWrite,
        uri: RoutingPath.activeRegions,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations, UserRole.BackOffice],
        isAccessAllowed: false,
        name: AuthByRole.OfferRequestsReadWrite,
        uri: RoutingPath.offerRequests,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.TeamLeader,
        ],
        isAccessAllowed: false,
        name: AuthByRole.AccountRegistrationRequestsReadWrite,
        uri: RoutingPath.accountRegistrationRequests,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations],
        isAccessAllowed: false,
        name: AuthByRole.AvgGdprManagementReadWrite,
        uri: RoutingPath.avgGdprManagement,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.TeamLeader,
        ],
        isAccessAllowed: false,
        name: AuthByRole.WorkProgramReadWrite,
        uri: RoutingPath.workProgram,
      },
      {
        allowedRole: [
          UserRole.Admin,
          UserRole.ManagerOperations,
          UserRole.Planner,
          UserRole.BackOffice,
          UserRole.HrSpecialist,
          UserRole.TeamLeader,
        ],
        isAccessAllowed: false,
        name: AuthByRole.UnitPriceRead,
        uri: RoutingPath.unitPrice,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations],
        isAccessAllowed: false,
        name: AuthByRole.UnitPriceReadWrite,
        uri: RoutingPath.unitPrice,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations],
        isAccessAllowed: false,
        name: AuthByRole.AuthorizationReadWrite,
        uri: RoutingPath.authorization,
      },
      {
        allowedRole: [UserRole.Admin, UserRole.ManagerOperations, UserRole.Planner, UserRole.BackOffice],
        isAccessAllowed: false,
        name: AuthByRole.NotificationReadWrite,
        uri: RoutingPath.notification,
      },
    ],
  };

  public static token: token = {
    auth: { token: "", time: { exp: null, iat: null, diff: null, user: null } },
    b2c: { token: "", time: { exp: null, iat: null, diff: null, user: null } },
  };

  public static roles: UserRole[];

  // set isAccessAllowed to true if user is allowed
  public static updateIsAllowed(userRoles: UserRole[]): void {
    this.authByRoleGroup.roles.forEach((authByRoleGroupValues: IAuthByRoleGroupValues) => {
      if (this.isAllowed(authByRoleGroupValues.allowedRole, userRoles) && authByRoleGroupValues.uri) {
        AuthProvider.allowedUserPaths.push(authByRoleGroupValues.uri);
        authByRoleGroupValues.isAccessAllowed = true;
      }
    });
  }

  public static isAllowed(mustHaveRole: UserRole[], userRoles: UserRole[]): any {
    const isAllowed = (mustHaveRole: UserRole[], userRoles: UserRole[]): boolean =>
      mustHaveRole.some((role: UserRole) => userRoles.includes(role));
    return isAllowed(mustHaveRole, userRoles);
  }

  public static isAllowedByUserRoleAccess(UserRoleAccess: string): boolean {
    const keyByRoleAccess = AuthProvider.authByRoleGroup.roles.find(
      (allowedRoleByUri) => allowedRoleByUri.name === UserRoleAccess
    );

    return keyByRoleAccess?.isAccessAllowed || false;
  }

  public static isUserAllowedByPath(path: string): boolean {
    return AuthProvider.allowedUserPaths.includes(path);
  }
}
