import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React from "react";

class HomePage extends React.Component {
  public render(): JSX.Element {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column text-center h-100">
        <h1 className="mb-4 mx-3">{LanguageProvider.t(TranslationMapper.pages.home.header)}</h1>
        <p className="mx-3">{LanguageProvider.t(TranslationMapper.pages.home.subheader)}</p>
      </div>
    );
  }
}

export default HomePage;
