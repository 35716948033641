import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { IRegionStoreState, initialRegionStoreState } from "../state/regionStoreState";

function regionReducer(
  state: IRegionStoreState = initialRegionStoreState,
  action: ActionTypesCollection
): IRegionStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_ACTIVE_REGIONS:
      return { ...state, activeRegions: [...action.payload] };
    case ActionTypes.UPDATE_LOADING_REGIONS:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default regionReducer;
