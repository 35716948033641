import { configureStore } from "@reduxjs/toolkit";

import { fetchInitialConfiguration } from "./fetchInitialConfiguration";
import dateMiddleware from "./middleware/dateMiddleware";
import rootReducer, { RootState } from "./reducers/rootReducer";
import { initialAccountStoreState } from "./state/accountStoreState";
import { initialAuthorizationStoreState as initialAuthStoreState } from "./state/authorizationStoreState";
import { initialChatStoreState } from "./state/chatStoreState";
import { initialErrorState } from "./state/errorStoreState";
import { initialNotificationStoreState } from "./state/notificationStoreState";
import { initialOfferRequestStoreState } from "./state/offerRequestStoreState";
import { initialPostalCodeRequestStoreState } from "./state/PostalCodeRequestStoreState";
import { initialProjectWorkProgramStoreState } from "./state/projectWorkProgramStoreState";
import { initialRegionStoreState } from "./state/regionStoreState";
import { initialUnitPriceStoreState } from "./state/unitPriceStoreState";
import { initialUserStoreState } from "./state/userStoreState";

export const initialStates: RootState = {
  authState: initialAuthStoreState,
  errorState: initialErrorState,
  chatState: initialChatStoreState,
  activationState: initialChatStoreState,
  userState: initialUserStoreState,
  notificationState: initialNotificationStoreState,
  regionState: initialRegionStoreState,
  offerRequestState: initialOfferRequestStoreState,
  accountState: initialAccountStoreState,
  postalCodeRequestState: initialPostalCodeRequestStoreState,
  projectWorkProgramState: initialProjectWorkProgramStoreState,
  unitPriceState: initialUnitPriceStoreState,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialStates,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // lastMessageSentdate is not serializable due to UTC in backend local time in frontend
      serializableCheck: {
        ignoredActions: ["UPDATE_CHAT_INFO", "UPDATE_CHAT_CONVERSATION"],
        ignoredPaths: ["chatState.chatConversation", "chatState.chatInfo"],
      },
    }).concat(dateMiddleware),
});

store.dispatch(fetchInitialConfiguration());

export default store;
