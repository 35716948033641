import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import { ServiceOfferStatus } from "enums/serviceOfferStatus";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import IServiceDescriptor from "interfaces/IServiceDescriptor";
import ISupplementedServiceOfferRequest from "interfaces/ISupplementedServiceOfferRequest";
import moment from "moment";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Column, Row as ReactTableRow } from "react-table";
import {
  deleteOfferRequest,
  fetchAvailableServices,
  fetchOfferRequests,
  updateOfferRequest,
} from "store/actions/offerRequestActions";
import { RootState } from "store/reducers/rootReducer";

import IOfferRequestManagementProps, {
  IOfferRequestManagementDispatchProps,
  IOfferRequestManagementStateProps,
} from "./interfaces/IOfferRequestManagementProps";
import IOfferRequestManagementState from "./interfaces/IOfferRequestManagementState";

class OfferRequestManagement extends Component<IOfferRequestManagementProps, IOfferRequestManagementState> {
  private readonly maxLengthPriceInput: number = 6;
  private readonly tableDataExampleObject: ISupplementedServiceOfferRequest = {
    createdOn: new Date(),
    email: "",
    firstName: "",
    lastName: "",
    serviceId: "",
    serviceOfferOwner: "",
    serviceOfferRequestCode: "",
    serviceOfferStatus: 0,
    houseNumber: 0,
    zipCode: "",
    fullName: "",
    price: 0,
    serviceCategoryId: "",
    serviceCategoryName: "",
    serviceName: "",
    phoneNumber: "",
  };

  private readonly filterResetValue: string = "undefined";

  public constructor(props: IOfferRequestManagementProps) {
    super(props);

    const state: IOfferRequestManagementState = {
      updatedOfferPrices: new Map<string, number>(),
      showDeleteConfirmationPrompt: false,
    };

    this.state = state;

    this.getStatusName = this.getStatusName.bind(this);
    this.onDeleteRequest = this.onDeleteRequest.bind(this);
    this.onServiceCategoryFilterChange = this.onServiceCategoryFilterChange.bind(this);
    this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
    this.onOfferPriceUpdate = this.onOfferPriceUpdate.bind(this);
    this.renderRowSubComponent = this.renderRowSubComponent.bind(this);
    this.onPriceUpdateSuccess = this.onPriceUpdateSuccess.bind(this);
    this.setShowDeleteConformationPrompt = this.setShowDeleteConformationPrompt.bind(this);
    this.onServicesFilterChange = this.onServicesFilterChange.bind(this);
  }

  public componentDidMount(): void {
    this.props.onFetchServiceOffers();
    this.props.onFetchAvailableServices();
  }

  private getStatusName(status: number): string {
    const label =
      TranslationMapper.pages.offer_request_management[`request_status_${status}`] ??
      TranslationMapper.pages.offer_request_management.request_status_invalid;

    return LanguageProvider.t(label);
  }

  private onDeleteRequest(offerId?: string): void {
    if (!offerId) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.general));
      return;
    }

    this.props.onDeleteOfferRequest(offerId);
    this.setShowDeleteConformationPrompt(false, undefined);
  }

  private setShowDeleteConformationPrompt(show: boolean, offerId: string | undefined): void {
    this.setState({
      showDeleteConfirmationPrompt: show,
      offerIdToDelete: offerId,
    });
  }

  private get getColumns(): Column[] {
    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_name),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.fullName),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_service),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.serviceName),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_request_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.createdOn),
        Cell: ({ value }): string => moment(value).format("DD-MM-yyyy"),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_status),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.serviceOfferStatus
        ),
        Cell: ({ value }): string => this.getStatusName(value),
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.serviceOfferRequestCode
        ),
        Cell: ({ value }): JSX.Element => {
          return (
            <div className="offer-request-actions">
              <img
                src={DeleteIcon}
                alt="delete offer"
                onClick={(): void => this.setShowDeleteConformationPrompt(true, value)}
              />
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: (): null => null,
        id: "expander",
        Cell: ({ row }): JSX.Element => (
          <span {...row.getToggleRowExpandedProps()}>
            <div
              className={
                row.isExpanded
                  ? "offer-request-management-table-row-expander-open"
                  : "offer-request-management-table-row-expander-closed"
              }
            />
          </span>
        ),
        disableSortBy: true,
      },
    ];

    return columns;
  }

  private get getFilteredResults(): ISupplementedServiceOfferRequest[] {
    if (
      !this.props.offerRequests ||
      (!this.state.selectedServicesCategoryFilter &&
        this.state.statusFilter === undefined &&
        !this.state.selectedServicesFilter)
    ) {
      return this.props.offerRequests ?? [];
    }

    let results: ISupplementedServiceOfferRequest[] = this.props.offerRequests;

    if (this.state.statusFilter !== undefined) {
      results = results.filter((r) => r.serviceOfferStatus === this.state.statusFilter) ?? [];
    }

    if (this.state.selectedServicesCategoryFilter) {
      results = results.filter((r) => r.serviceCategoryId === this.state.selectedServicesCategoryFilter) ?? [];
    }

    if (this.state.selectedServicesFilter) {
      results = results.filter((r) => r.serviceId === this.state.selectedServicesFilter) ?? [];
    }

    return results;
  }

  private onOfferPriceUpdate(event: ChangeEvent<HTMLInputElement>, offerId: string): void {
    const input = event.target.value.replace(",", ".");
    const parsed = parseFloat(parseFloat(input).toFixed(2));
    const prices = this.state.updatedOfferPrices;
    const isValidParsed = parsed != null && !isNaN(parsed) && parsed.toString().length <= this.maxLengthPriceInput;

    if (!isValidParsed && !prices.has(offerId)) {
      return;
    }

    if (prices.has(offerId) && !isValidParsed) {
      prices.delete(offerId);
    } else {
      prices.set(offerId, parsed);
    }

    this.setState({
      updatedOfferPrices: prices,
    });
  }

  private onSendUpdatedOffer(offerId: string): void {
    const price = this.state.updatedOfferPrices.get(offerId);

    if (!price) {
      return;
    }

    this.props.onUpdateOfferRequest(offerId, price, this.onPriceUpdateSuccess);
  }

  private onPriceUpdateSuccess(): void {
    this.setState({
      updatedOfferPrices: new Map<string, number>(),
    });
  }

  private renderRowSubComponent(rowData: ReactTableRow): JSX.Element {
    const offerDetails = rowData.original as ISupplementedServiceOfferRequest;
    const onPriceChange = (e: ChangeEvent<HTMLInputElement>): void =>
      this.onOfferPriceUpdate(e, offerDetails.serviceOfferRequestCode);
    const onSendOffer = (): void => this.onSendUpdatedOffer(offerDetails.serviceOfferRequestCode);
    const priceFromProps = offerDetails.price != null && offerDetails.price !== 0 ? offerDetails.price : undefined;

    return (
      <div className="offer-request-management-table-row-sub">
        <Row className="ml-0 mb-4">
          <Col>
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.components.labels.label_email)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.email}</Col>
          </Col>
          <Col>
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.pages.offer_request_management.label_zip_code)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.zipCode}</Col>
          </Col>
          <Col>
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.pages.offer_request_management.label_house_number)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.houseNumber}</Col>
          </Col>
          <Col>
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.pages.offer_request_management.label_house_number_addition)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.houseNumberAddition}</Col>
          </Col>
          <Col>
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.pages.offer_request_management.label_phone)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.phoneNumber}</Col>
          </Col>
        </Row>

        {offerDetails.additionalInformationQuestion && (
          <Row className="ml-0 mb-4">
            <Col xl={6} className="pl-0 pr-0">
              <b>
                {LanguageProvider.t(
                  TranslationMapper.pages.offer_request_management.label_additional_offer_information
                )}
              </b>
              <div>{offerDetails.additionalInformationQuestion}</div>
            </Col>
          </Row>
        )}

        <Row className="ml-0">
          <Col className="ml-0">
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.components.labels.label_offer_owner)}</b>
            </Col>
            <Col className="pl-0 pr-0">{offerDetails.serviceOfferOwner}</Col>
          </Col>
          <Col className="ml-0">
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.components.labels.label_offer_send_date)}</b>
            </Col>
            <Col className="pl-0 pr-0">
              {offerDetails.serviceOfferSendOn ? moment(offerDetails.serviceOfferSendOn).format("DD-MM-yyyy") : "-"}
            </Col>
          </Col>
          <Col className="ml-0">
            <Col className="pl-0 pr-0">
              <b>{LanguageProvider.t(TranslationMapper.components.labels.label_offer_price)}</b>
            </Col>
            <Col className="pl-0 pr-0 mt-auto">
              <Form.Control
                type="number"
                value={this.state.updatedOfferPrices.get(offerDetails.serviceOfferRequestCode) ?? priceFromProps ?? ""}
                onChange={onPriceChange}
                readOnly={offerDetails.serviceOfferStatus === ServiceOfferStatus.Approved}
                maxLength={this.maxLengthPriceInput}
              />
            </Col>
          </Col>
          <Col className="ml-0 mt-auto">
            {offerDetails.serviceOfferStatus !== ServiceOfferStatus.Approved && (
              <Button
                style={ButtonStyle.blueOutline}
                label={LanguageProvider.t(TranslationMapper.components.labels.send_update_button)}
                isDisabled={this.state.updatedOfferPrices.get(offerDetails.serviceOfferRequestCode) == null}
                onClick={onSendOffer}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }

  private onServiceCategoryFilterChange(e: ChangeEvent<HTMLSelectElement>): void {
    const selection = e.target.value !== this.filterResetValue ? e.target.value : undefined;

    this.setState({
      selectedServicesCategoryFilter: selection,
    });
  }

  private onServicesFilterChange(e: ChangeEvent<HTMLSelectElement>): void {
    const selection = e.target.value !== this.filterResetValue ? e.target.value : undefined;

    this.setState({
      selectedServicesFilter: selection,
    });
  }

  private onStatusFilterChange(e: ChangeEvent<HTMLSelectElement>): void {
    const selection = parseInt(e.target.value);
    this.setState({
      statusFilter: selection != null && !isNaN(selection) ? selection : undefined,
    });
  }

  private get renderServiceCategoryFilter(): JSX.Element {
    if (!this.props.availableServices || this.props.availableServices.length === 0) {
      return <></>;
    }

    const options = this.props.availableServices.map((s) => (
      <option value={s.serviceCategoryId} key={s.serviceCategoryId}>
        {s.name}
      </option>
    ));

    return (
      <Form.Control
        as="select"
        defaultValue={this.filterResetValue}
        value={this.state.selectedServicesCategoryFilter}
        onChange={this.onServiceCategoryFilterChange}
      >
        <option value={this.filterResetValue}>
          {LanguageProvider.t(TranslationMapper.pages.offer_request_management.category_filter_placeholder)}
        </option>
        {options}
      </Form.Control>
    );
  }

  private get renderServicesFilter(): JSX.Element {
    if (!this.props.availableServices || this.props.availableServices.length === 0) {
      return <></>;
    }

    const services: IServiceDescriptor[] = [];
    this.props.availableServices.forEach((sc) => services.push(...sc.services));

    const options = services.map((s) => (
      <option value={s.serviceId} key={s.serviceId}>
        {s.name}
      </option>
    ));

    return (
      <Form.Control
        as="select"
        defaultValue={this.filterResetValue}
        value={this.state.selectedServicesCategoryFilter}
        onChange={this.onServicesFilterChange}
      >
        <option value={this.filterResetValue}>
          {LanguageProvider.t(TranslationMapper.pages.offer_request_management.service_filter_placeholder)}
        </option>
        {options}
      </Form.Control>
    );
  }

  private get renderStatusFilter(): JSX.Element {
    const statuses = Object.keys(ServiceOfferStatus)
      .reverse()
      .splice(Object.keys(ServiceOfferStatus).length / 2);

    const options = statuses.map((s) => (
      <option value={s} key={s}>
        {this.getStatusName(parseInt(s))}
      </option>
    ));

    return (
      <Form.Control
        as="select"
        defaultValue={this.filterResetValue}
        value={this.state.statusFilter}
        onChange={this.onStatusFilterChange}
      >
        <option value={this.filterResetValue}>
          {LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_filter_placeholder)}
        </option>
        {options}
      </Form.Control>
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="offer-request-management-page">
        {this.props.isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}

        {!this.props.isLoading && this.props.offerRequests && this.props.offerRequests.length > 0 && (
          <>
            <div className="offer-request-management-filters">
              {this.renderServiceCategoryFilter}
              {this.renderServicesFilter}
              {this.renderStatusFilter}
              <Button
                className="ml-auto"
                style={ButtonStyle.blue}
                label={LanguageProvider.t(TranslationMapper.global.buttons.refresh)}
                onClick={this.props.onFetchServiceOffers}
              />
            </div>

            <ReactTable
              columns={this.getColumns}
              data={this.getFilteredResults}
              renderRowSubComponent={this.renderRowSubComponent}
              className="offer-request-management-table"
              usePaginationForTable={false}
              defaultColumnToSortBy={{
                id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.fullName),
                desc: false,
              }}
            />

            <ConfirmationPrompt
              showPrompt={this.state.showDeleteConfirmationPrompt}
              onCancel={(): void => this.setShowDeleteConformationPrompt(false, undefined)}
              onAgree={(): void => this.onDeleteRequest(this.state.offerIdToDelete)}
            />
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IOfferRequestManagementStateProps => ({
  offerRequests: state.offerRequestState.offerRequests,
  availableServices: state.offerRequestState.availableServices,
  isLoading: state.offerRequestState.isLoading,
});

const mapDispatchToProps: IOfferRequestManagementDispatchProps = {
  onFetchServiceOffers: fetchOfferRequests,
  onFetchAvailableServices: fetchAvailableServices,
  onDeleteOfferRequest: deleteOfferRequest,
  onUpdateOfferRequest: updateOfferRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferRequestManagement);
