import { ApplicationConfig } from "config";
import moment from "moment";
import AuthProvider from "providers/authProvider";
import AuthService from "services/authService";
import { MsalAuthenticator } from "./msalAuthenticator";

export class AuthApiAuthenticator extends MsalAuthenticator {
  // eslint-disable-next-line no-undef
  public async authenticateRequestAsync(request: RequestInit): Promise<RequestInit> {
    const authService = new AuthService();

    const isB2cTokenValid =
      !AuthProvider.token.b2c.time.exp ||
      (AuthProvider.token.b2c.time.user &&
        AuthProvider.token.b2c.time.diff &&
        AuthProvider.token.b2c.time.user + AuthProvider.token.b2c.time.diff < moment().unix());
    const isAuthTokenValid =
      AuthProvider.token.auth.time.user &&
      AuthProvider.token.auth.time.diff &&
      AuthProvider.token.auth.time.user + AuthProvider.token.auth.time.diff < moment().unix();

    // B2C token
    if (isB2cTokenValid) {
      const accessToken = await this.acquireTokenAsync(ApplicationConfig.scopes.authApi);
      request.headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
    }
    if (isAuthTokenValid) {
      const accessToken = await this.acquireTokenAsync(ApplicationConfig.scopes.authApi);
      request.headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
      await authService.getBearerToken();
    }

    if (!isB2cTokenValid && !isAuthTokenValid) {
      request.headers = { ...request.headers, Authorization: `Bearer ${AuthProvider.token.b2c.token}` };
    }

    return request;
  }
}
