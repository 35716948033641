import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import { PlatformType } from "enums/platformType";
import { UserRole } from "enums/userRole";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import EditIcon from "images/edit.svg";
import IAuthUser, { IAuthUpsertUser as IAuthPutUpdateUserRoles, IAuthRole } from "interfaces/IAuth";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Column } from "react-table";
import { deleteUser, getListAllRoles, getListAllUsers, putUpdateUserRoles } from "store/actions/authUserActions";
import { RootState } from "store/reducers/rootReducer";
import IAuthUsersProps, { IAuthDispatchProps, IAuthUsersStateProps } from "./interfaces/IAuthUsersProps";
import { IAuthUsersState } from "./interfaces/IAuthUsersState";

class AuthorizationUsers extends Component<IAuthUsersProps, IAuthUsersState> {
  private readonly adminName: string = "Admin";
  private readonly filterResetValue: string = "";
  private readonly tableDataAuthUsersObject: IAuthUser = {
    b2CObjectId: "",
    id: "",
    upn: "",
    userType: UserRole.Unknown,
    roles: [],
  };
  private isUserAdminWhenOpeningEditModal: boolean = false;

  public constructor(props: IAuthUsersProps) {
    super(props);

    const state: IAuthUsersState = {
      filter: {
        role: "",
      },
      isDeleteModalShown: false,
      isEditModalShown: false,
      isMayNotDeleteLastAdminShown: false,
      users: [],
      userSelected: undefined,
      rolesSelected: undefined,
    };

    this.state = state;

    this.onFilterRoleChange = this.onFilterRoleChange.bind(this);
  }

  public componentDidMount(): void {
    this.getListAllUsers(true);
  }

  public componentDidUpdate(prevProps: Readonly<IAuthUsersProps>): void {
    if (prevProps.users !== this.props.users) {
      this.setState({ users: this.props.users ?? [] });
    }
  }

  // get count how many user are admin
  private get countAdmin(): number {
    return this.props.users?.filter((x) => x.roles.some((y) => y.name === this.adminName)).length ?? 0;
  }

  // get boolean if there are more then one admin
  private get isDeleteAdminAllowed(): boolean {
    return this.countAdmin > 1;
  }

  // get boolean if delete admin is displayed
  private isDeleteAdminDisplayed(role: IAuthRole): boolean {
    return (
      this.isUserAdminWhenOpeningEditModal && // only when admin by default is not enabled
      role.name === this.adminName && // only when role is admin
      !this.isDeleteAdminAllowed // only when there are more then one admin
    );
  }

  private getListAllUsers(init?: boolean): void {
    this.props.onGetListAllUsers(() => {
      if (init) {
        this.getListAllRoles();
      }
    });
  }

  private getListAllRoles(): void {
    this.props.onGetListAllRoles();
  }

  private getAndTrimSelection(e: ChangeEvent<HTMLInputElement>): string | undefined {
    const value = e.target.value.trim();
    const selection = value !== this.filterResetValue && value !== "" ? value : undefined;
    return selection;
  }

  private showDeleteModal(doShow: boolean, requestId: string | undefined): void {
    this.setState({
      isDeleteModalShown: doShow,
      isMayNotDeleteLastAdminShown: false,
      userSelected: this.props.users?.find((x) => x.id === requestId),
    });
  }

  private showEditModal(doShow: boolean, requestId: string | undefined): void {
    const user = this.props.users?.find((x) => x.id === requestId);

    this.setState({
      isEditModalShown: doShow,
      userSelected: user,
      rolesSelectedKeys: user?.roles.map((x) => x.id) ?? [],
    });

    // Has admin role enabled when opening edit modal
    this.isUserAdminWhenOpeningEditModal = user?.roles.some((x) => x.name === this.adminName) ?? false;
  }

  private onDelete(): void {
    if (this.state.userSelected?.id !== undefined) {
      this.props.onDeleteAuthorizationUser(this.state.userSelected.id, () => {
        this.getListAllUsers();
        this.showDeleteModal(false, undefined);
      });
    }
  }

  // build array with selected checkboxes on change checked
  private onRoleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { checked, value } = e.target;
    if (checked) {
      // add to array
      this.setState((prevState) => ({
        rolesSelectedKeys: [...(prevState.rolesSelectedKeys ?? []), value],
      }));
    } else {
      // remove from array
      this.setState((prevState) => ({
        rolesSelectedKeys: prevState.rolesSelectedKeys?.filter((role) => role !== value),
      }));
    }
  }

  private onFilterRoleChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        filter: { role: this.getAndTrimSelection(e) },
      },
      () => {
        // filter users based on selected (filter) role
        const filteredUsers = this.props.users?.filter((x) => {
          if (this.state.filter.role) {
            return x.roles.some((y) => y.id === this.state.filter.role);
          }
          return true;
        });
        this.setState({ users: filteredUsers ?? [] });
      }
    );
  }

  private updateUserRoles(): void {
    if (!this.state.userSelected) return;
    const dataPutUpdateUserRoles: IAuthPutUpdateUserRoles = {
      id: this.state.userSelected.id,
      platformType: PlatformType.Zizo,
      roles: this.state.rolesSelectedKeys?.map((x) => ({ id: x })) ?? [],
    };

    this.props.onPutUpdateUserRoles(dataPutUpdateUserRoles, () => {
      this.getListAllUsers();
      this.showEditModal(false, undefined);
    });
  }

  private get getColumns(): Column[] {
    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_upn),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataAuthUsersObject, (x) => x.upn),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_role),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataAuthUsersObject, (x) => x.roles),
        Cell: (props) => {
          const joinedRoles: string = props.cell.value.map((x) => x.displayName).join(", ");
          return <div>{joinedRoles}</div>;
        },
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataAuthUsersObject, (x) => x.id),
        Cell: ({ row }): JSX.Element => {
          return (
            <div className="g-react-table__actions">
              <div>
                <img
                  src={EditIcon}
                  alt="Edit user"
                  onClick={(): void => this.showEditModal(true, row.values.id)}
                  className="g-react-table__action"
                />
                <img
                  src={DeleteIcon}
                  alt="Delete user"
                  onClick={(): void => this.showDeleteModal(true, row.values.id)}
                  className={`g-react-table__action${
                    row.values.roles.some((x) => x.name === this.adminName) && !this.isDeleteAdminAllowed
                      ? " disabled" // only when there are is one admin
                      : ""
                  }`}
                />
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
    ];
    return columns;
  }

  public renderEditModal(): JSX.Element {
    return (
      <BasicPopup
        className="g-confirmation-prompt"
        headerText={LanguageProvider.t(TranslationMapper.pages.auth.user.edit.title)}
        onClose={(): void => this.showEditModal(false, undefined)}
        showPopup={this.state.isEditModalShown}
        subheaderText={LanguageProvider.t(TranslationMapper.pages.auth.user.edit.header).replace(
          "{0}",
          this.state.userSelected?.upn ?? ""
        )}
        footerContent={
          <div className="g-confirmation-prompt-buttons">
            <Button
              style={ButtonStyle.blue}
              onClick={(): void => this.showEditModal(false, undefined)}
              label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
            />
            <Button
              style={ButtonStyle.blue}
              onClick={(): void => this.updateUserRoles()}
              label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
              isLoading={this.props.isLoadingPostUser}
            />
          </div>
        }
      >
        {this.props.isLoadingGetAllRoles ? (
          <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <div className="mx-4 mb-5">
            {this.props.roles?.length ? (
              <div className="mb-3">
                {this.props.roles.map((role) => (
                  <div className="form-check" key={role.id}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={role.id}
                      id={role.id}
                      checked={this.state.rolesSelectedKeys?.includes(role.id)}
                      onChange={(e): void => this.onRoleChange(e)}
                      disabled={this.isDeleteAdminDisplayed(role)}
                    />
                    <label className="form-check-label" htmlFor={role.id}>
                      {role.displayName}
                      {this.isDeleteAdminDisplayed(role) && (
                        <span className="form-text ps-2">
                          (
                          {LanguageProvider.t(TranslationMapper.pages.auth.user.edit.checkbox.admin_delete_not_allowed)}
                          )
                        </span>
                      )}
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">{LanguageProvider.t(TranslationMapper.pages.auth.user.edit.results_none)}</p>
            )}

            {this.state.isMayNotDeleteLastAdminShown && (
              <div className="alert alert-danger text-center" role="alert">
                {LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.admin_last)}
              </div>
            )}
          </div>
        )}
      </BasicPopup>
    );
  }

  public render(): JSX.Element {
    return (
      <Page className="authorization-page">
        <div className="react-table__top">
          <div className="filters">
            <div className="grouped-filter">
              <div className="grouped-filter-label">
                {LanguageProvider.t(TranslationMapper.pages.auth.user.filter.label.role)}
              </div>
              <Form.Control as="select" value={this.state.filter.role ?? ""} onChange={this.onFilterRoleChange}>
                <option value={this.filterResetValue}>
                  {LanguageProvider.t(TranslationMapper.pages.auth.user.filter.placeholder.role)}
                </option>
                {this.props.roles?.map((role) => (
                  <option value={role.id} key={role.id}>
                    {role.displayName}
                  </option>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>
        <ReactTable
          columns={this.getColumns}
          data={this.state.users}
          usePaginationForTable={true}
          defaultColumnToSortBy={{
            id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataAuthUsersObject, (x) => x.upn),
            desc: true,
          }}
          isLoading={this.props.isLoadingGetAllUsers}
        />

        {/* Modal Edit */}
        {this.renderEditModal()}

        {/* Modal Delete */}
        <ConfirmationPrompt
          showPrompt={this.state.isDeleteModalShown}
          onCancel={(): void => this.showDeleteModal(false, undefined)}
          onAgree={(): void => this.onDelete()}
          isAgreeLoading={this.props.isLoadingDelete}
          subheaderText={LanguageProvider.t(TranslationMapper.pages.auth.user.delete.header).replace(
            "{0}",
            this.state.userSelected?.upn ?? ""
          )}
        >
          {this.state.isMayNotDeleteLastAdminShown && (
            <div className="mx-4 mb-5">
              <div className="alert alert-danger text-center" role="alert">
                {LanguageProvider.t(TranslationMapper.pages.auth.user.messages.error.admin_last)}
              </div>
            </div>
          )}
        </ConfirmationPrompt>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IAuthUsersStateProps => ({
  isLoadingDelete: state.authState.user.isLoadingDelete,
  isLoadingGetAllUsers: state.authState.user.isLoadingGetAllUsers,
  isLoadingPostUser: state.authState.user.isLoadingPostUser,
  isLoadingGetAllRoles: state.authState.role.isLoadingGetAllRoles,
  users: state.authState.user.users,
  roles: state.authState.role.roles,
});

const mapDispatchToProps: IAuthDispatchProps = {
  onDeleteAuthorizationUser: deleteUser,
  onGetListAllUsers: getListAllUsers,
  onGetListAllRoles: getListAllRoles,
  onPutUpdateUserRoles: putUpdateUserRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationUsers);
