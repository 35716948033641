/* eslint-disable @typescript-eslint/explicit-function-return-type */
import TranslationMapper from "i18n/mapper";
import IAccountRegistrationRequest from "interfaces/IAccountRegistrationRequest";
import IDebtorInformation from "interfaces/IDebtorInformation";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import AccountService from "services/accountService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const setAccountRegistrationLoading = actionCreator<ActionTypes.UPDATE_LOADING_ACCOUNT_REGISTRATION, boolean>(
  ActionTypes.UPDATE_LOADING_ACCOUNT_REGISTRATION
);
export const setFetchingAccountRegistration = actionCreator<ActionTypes.UPDATE_FETCHING_ACCOUNT_REGISTRATION, boolean>(
  ActionTypes.UPDATE_FETCHING_ACCOUNT_REGISTRATION
);
export const fetchedAccountRegistrations = actionCreator<
  ActionTypes.FETCHED_ACCOUNT_REGISTRATION_REQUESTS,
  IAccountRegistrationRequest[]
>(ActionTypes.FETCHED_ACCOUNT_REGISTRATION_REQUESTS);
export const fetchedDebtorInformation = actionCreator<
  ActionTypes.FETCHED_DEBTOR_INFORMATION,
  IDebtorInformation | undefined
>(ActionTypes.FETCHED_DEBTOR_INFORMATION);
export const setUpsertingAccountRegistration = actionCreator<
  ActionTypes.UPDATE_UPSERTING_ACCOUNT_REGISTRATION,
  boolean
>(ActionTypes.UPDATE_UPSERTING_ACCOUNT_REGISTRATION);

export function fetchAccountRegistrations(): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();

    dispatch(toggleLoadingStatus(true));

    accountService
      .getAccountRegistrationRequestsAsync()
      .then((requests) => dispatch(fetchedAccountRegistrations(requests)))
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "OfferRequestActions.fetchAccountRegistrations",
            message: `Error on fetch account registration requests ${error}`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.error_fetching_account_registration_requests)
        );
      })
      .finally(() => dispatch(toggleLoadingStatus(false)));
  };
}

export function sendAccountRegistrationRequest(accountRegistrationRequestId: string, onSuccess: () => void): AppAction {
  return () => {
    const accountService = new AccountService();
    if (accountRegistrationRequestId === "" || accountRegistrationRequestId === undefined) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.error_sending_email_id_empty));
      return;
    }
    accountService
      .sendAccountRegistrationRequestAsync(accountRegistrationRequestId)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.components.labels.upsert_account_registration_request_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "accountRegistrationRequestActions.sendAccountRegistrationRequest",
            message: `Error on sending registration email: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.components.labels.upsert_account_registration_request_fail)
        );
      });
  };
}

export function upsertAccountRegistrationRequest(
  request: IAccountRegistrationRequest,
  onSuccess: () => void
): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();
    dispatch(toggleUpsertingAccountRegistrationStatus(true));
    accountService
      .upsertAccountRegistrationRequestAsync(request)
      .then(() => {
        dispatch(fetchAccountRegistrations());
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.components.labels.upsert_account_offer_succes)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "accountRegistrationRequestActions.upsertAccountRegistrationRequest",
            message: `Error on insert account offer request, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_send_update)
        );
      })
      .finally(() => dispatch(toggleUpsertingAccountRegistrationStatus(false)));
  };
}

export function getDebtorInformation(externalDebtorId: string): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();
    dispatch(toggleFetchingDebtorInformationStatus(true));
    accountService
      .getDebtorInformationAsync(externalDebtorId)
      .then((debtorInformation) => {
        if (!debtorInformation.debtorFound) {
          NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.user_not_found));
          dispatch(resetDebtorInformation());
        } else {
          if (!debtorInformation.emailFound) {
            NotificationManager.error(
              `${debtorInformation.name} ${LanguageProvider.t(TranslationMapper.global.errors.has_no_email)}`
            );
            dispatch(resetDebtorInformation());
          }
          if (debtorInformation.alreadyHasAccount) {
            NotificationManager.error(
              `${debtorInformation.name} ${LanguageProvider.t(TranslationMapper.global.errors.already_has_an_account)}`
            );
            dispatch(resetDebtorInformation());
          }
          if (debtorInformation.debtorFound && debtorInformation.emailFound && !debtorInformation.alreadyHasAccount) {
            dispatch(fetchedDebtorInformation(debtorInformation));
          }
        }
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "accountRegistrationRequestActions.getDebtorInformation",
            message: `Error getting debtor information. ${error.message}`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.error_fetching_debtor_information)
        );
      })
      .finally(() => {
        dispatch(toggleFetchingDebtorInformationStatus(false));
      });
  };
}

export function deleteAccountRegistrationRequest(requestId: string): AppAction {
  return (dispatch) => {
    const accountService = new AccountService();
    accountService
      .deleteAccountRegistrationRequestAsync(requestId)
      .then(() => {
        dispatch(fetchAccountRegistrations());
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.delete_account_registration_success)
        );
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "accountRegistrationRequestActions.deleteAccountRegistrationRequest",
            message: `Error deleting account registration request. ${error}`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.error_deleting_account_registration)
        );
      });
  };
}

export function toggleLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch) => {
    dispatch(setAccountRegistrationLoading(isLoading));
  };
}

export function toggleFetchingDebtorInformationStatus(fetching: boolean): AppAction {
  return (dispatch) => {
    dispatch(setFetchingAccountRegistration(fetching));
  };
}

export function toggleUpsertingAccountRegistrationStatus(upserting: boolean): AppAction {
  return (dispatch) => {
    dispatch(setUpsertingAccountRegistration(upserting));
  };
}

export function resetDebtorInformation(): AppAction {
  return (dispatch) => {
    dispatch(fetchedDebtorInformation(undefined));
  };
}
