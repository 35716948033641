import IProjectWorkProgram from "interfaces/IProjectWorkProgram";
import IWorkProgramActionFrequency from "interfaces/IWorkProgramActionFrequency";

export interface IProjectWorkProgramStoreState {
  isLoading: boolean;
  actionFrequencies: IWorkProgramActionFrequency[];
  projectWorkProgram?: IProjectWorkProgram;
}

export const initialProjectWorkProgramStoreState: IProjectWorkProgramStoreState = {
  isLoading: false,
  actionFrequencies: [
    {
      actionFrequencyId: "",
      description: "",
      periodic: false,
      timesPerYear: 0,
      createdOn: "",
      modifiedOn: "",
    },
  ],
};
