import { ThunkAction } from "redux-thunk";

import { ActionTypes, ActionTypesCollection } from "./actionTypes";
import { RootState } from "./reducers/rootReducer";

export type AppAction = ThunkAction<void, RootState, undefined, ActionTypesCollection>;

export interface IActionType<TAction extends ActionTypes, TPayload> {
  type: TAction;
  payload: TPayload;
}

export interface IActionCreator<TAction extends ActionTypes, TPayload> {
  type: TAction;
  (payload: TPayload): IActionType<TAction, TPayload>;
}

export function actionCreator<TAction extends ActionTypes, TPayload>(type: TAction): IActionCreator<TAction, TPayload> {
  return Object.assign((payload: TPayload) => ({ type, payload }), { type });
}
